import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const Physiotherapyforrespiratoryconditions = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Breathe Easier with Expert Care</h1>
                    <h2>Unlock the power of physiotherapy to manage Asthma and COPD at Ramp Physio and Fitness, where our specialized respiratory programs are tailored to improve lung function and enhance quality of life.</h2>
                    <p>Respiratory conditions like Asthma and Chronic Obstructive Pulmonary Disease (COPD) can significantly impact your daily life. At Ramp Physio and Fitness, we understand the challenges you face and offer expert physiotherapy services designed to improve your respiratory health. With evidence-based techniques and personalized care plans, we're committed to helping you breathe easier and achieve better lung function. In this comprehensive guide, we'll explore the benefits of physiotherapy for managing Asthma and COPD and how our programs can be a breath of fresh air in your health journey.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Respiratory Physiotherapy</h1>
                        <p>Respiratory physiotherapy is a specialized field focused on improving breathing and lung function for individuals with respiratory conditions. Our physiotherapists use a range of techniques, including breathing exercises, manual chest therapy, and endurance training to help alleviate symptoms and enhance overall respiratory health. By incorporating these methods, patients with Asthma and COPD can experience reduced breathlessness, increased exercise tolerance, and improved ability to clear mucus from their airways.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Pulmonary Rehabilitation Programs</h1>
                    <p>Pulmonary rehabilitation is a cornerstone of managing COPD and severe Asthma. Our comprehensive programs combine education, exercise training, and behavioral changes to empower you to manage your condition effectively. With a focus on improving physical and emotional well-being, our pulmonary rehabilitation programs are personalized to fit your unique needs and goals, offering a supportive environment to help you regain control of your respiratory health.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Techniques to Enhance Lung Capacity</h1>
                    <p>Our physiotherapy approach includes a variety of techniques to help enhance your lung capacity and improve airway clearance. These may include:</p>
                    <ul>
                        <li>Diaphragmatic breathing exercises</li>
                        <li>Controlled coughing techniques</li>
                        <li>Percussion and vibration to loosen mucus</li>
                        <li>Postural drainage positioning</li>
                        <li>Inspiratory muscle training</li>
                        <li>Aerobic and strength training exercises</li>
                        <li>Use of spirometry to monitor lung function</li>
                        <li>Stress management and relaxation techniques</li>
                        <li>Education on inhaler technique and medication adherence</li>
                        <li>Guidance on smoking cessation for smokers</li>
                    </ul>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Personalized Care for Lasting Results</h1>
                        <p>Every individual's experience with Asthma or COPD is unique, which is why we offer personalized care plans tailored to your specific condition, fitness level, and lifestyle. Our physiotherapists work closely with you to develop a program that not only addresses your current symptoms but also focuses on long-term management and prevention strategies. With our support, you can look forward to improved breathing, increased activity levels, and a higher quality of life.</p>
                        <br></br>
                        <p>
                            In conclusion, physiotherapy for respiratory conditions is a powerful approach to managing Asthma and COPD. At Ramp Physio and Fitness, we're dedicated to providing you with the expertise and care you need to breathe easier and live better. If you're ready to take the next step in your respiratory health journey, book a session with us today and experience the transformative power of personalized physiotherapy.
                        </p>
                    </div>
                </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default Physiotherapyforrespiratoryconditions;