import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const UnderstandingBodyweightTrainingBenefits = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Mastering Bodyweight Training</h1>
                    <h2>Unlock the potential of bodyweight training with Ramp Physio and Fitness and elevate your fitness journey through natural, accessible, and highly efficient exercises.</h2>
                    <p>At Ramp Physio and Fitness, we value the simplicity and effectiveness of bodyweight training. This form of exercise harnesses the power of your own body to build strength, endurance, and flexibility. In this comprehensive guide, we will explore the myriad benefits of bodyweight training and how you can incorporate it into your fitness regimen to transform your health and well-being.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Benefits of Bodyweight Exercises</h1>
                        <p>Bodyweight training, also known as calisthenics, is a versatile workout strategy that goes beyond mere convenience. It offers a range of benefits that can lead to a healthier, more active lifestyle. From improving relative strength to enhancing proprioception, bodyweight exercises are a foundational aspect of physical fitness.</p>
                        <ul>
                            <li>Cost-effective and requires no equipment</li>
                            <li>Promotes functional strength and mobility</li>
                            <li>Can be performed anywhere, anytime</li>
                            <li>Adaptable to all fitness levels</li>
                            <li>Improves body awareness and control</li>
                            <li>Facilitates natural movements and compound exercises</li>
                            <li>Reduces the risk of injury related to heavy lifting</li>
                            <li>Encourages creativity and variety in workouts</li>
                            <li>Offers full-body conditioning</li>
                            <li>Supports weight management and cardiovascular health</li>
                        </ul>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Customizing Your Workout</h1>
                    <p>Bodyweight training is not one-size-fits-all. It allows for personalization to suit individual fitness levels and goals. Whether you are a beginner or an advanced athlete, bodyweight exercises can be modified to increase or decrease intensity. Progressions and regressions ensure that you are continually challenging your body and improving.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Integrating Skill and Strength</h1>
                    <p>Bodyweight training is not just about building muscle; it's about developing skill, balance, and coordination. These elements are crucial for athletic performance and daily activities. As you progress with bodyweight exercises, you will notice improvements in areas such as grip strength, core stability, and overall body control.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Progressing with Precision</h1>
                        <p>Advancing in bodyweight training is about mastering form and technique before increasing the challenge. This progression ensures that you are building a solid foundation for advanced movements. With our expert guidance, you can safely push the boundaries of your abilities and achieve remarkable fitness milestones.</p>
                    </div>
                </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default UnderstandingBodyweightTrainingBenefits;