import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const PhysiotherapyForFacialNervePalsy = () => {
    return (
        <div>
            <Helmet>
                <title>Physiotherapy for Facial Nerve Palsy - Ramp Physio and Fitness</title>
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Empowering Your Smile with Facial Nerve Palsy Physiotherapy</h1>
                    <h2>Unlock natural facial expressions and improve nerve function with tailored physiotherapy for facial nerve palsy at Ramp Physio and Fitness in Homebush, NSW.</h2>
                    <p>Facial nerve palsy can be a challenging condition, affecting your ability to make facial expressions and communicate emotions. At Ramp Physio and Fitness, we understand the physical and emotional impact of facial nerve palsy. Our expert team is dedicated to providing comprehensive physiotherapy to help restore your facial symmetry and function. In this article, we'll explore the benefits of physiotherapy for facial nerve palsy and how our personalized approach can support your recovery journey.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Facial Nerve Palsy</h1>
                        <p>Facial nerve palsy, often referred to as Bell's palsy, is a condition that causes sudden, temporary weakness or paralysis of the facial muscles. It can result from various factors, including viral infections, trauma, or other neurological conditions. Physiotherapy plays a crucial role in the rehabilitation process, employing exercises and techniques to stimulate facial nerve recovery and muscle re-education.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Customized Treatment Plans</h1>
                    <p>Every case of facial nerve palsy is unique, and so are our treatment plans. At Ramp Physio and Fitness, we design customized physiotherapy programs based on your specific condition, symptoms, and recovery goals. Our treatments may include facial exercises, neuromuscular retraining, manual therapy, and the use of biofeedback to enhance your rehabilitation.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Exercises for Facial Nerve Palsy</h1>
                    <p>Engaging in specific exercises can significantly improve muscle strength and coordination for those with facial nerve palsy. Our therapists guide patients through a series of targeted exercises, including:</p>
                    <ul>
                        <li>Blinking and eye closure exercises</li>
                        <li>Smile symmetry training</li>
                        <li>Lip puckering and retraction drills</li>
                        <li>Nasal flaring activities</li>
                        <li>Forehead wrinkling and relaxation</li>
                        <li>Manual facial muscle resistance techniques</li>
                        <li>Mirror feedback exercises</li>
                        <li>Neuromuscular electrical stimulation (as appropriate)</li>
                        <li>Relaxation and stress reduction techniques</li>
                        <li>Home exercise programs for continued progress</li>
                    </ul>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Support Beyond the Clinic</h1>
                    <p>Recovery from facial nerve palsy extends beyond our clinic walls. We equip you with the knowledge and tools necessary for your continued recovery at home. Our therapists offer support and education on managing symptoms, preventing complications, and promoting faster healing. We are your partners in health, committed to your full recovery.</p>
                    <br></br>
                    <p>
                        At Ramp Physio and Fitness, we are dedicated to helping you regain your confidence and quality of life through expert physiotherapy for facial nerve palsy. Our mission is to provide compassionate, individualized care that addresses your unique needs and empowers you to achieve your health and wellness goals. Contact us today to learn more about our facial nerve palsy physiotherapy services and take the first step towards a brighter, more expressive future.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default PhysiotherapyForFacialNervePalsy;