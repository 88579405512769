import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const PhysiotherapyForArthritisPainManagement = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Alleviate Arthritis Pain with Expert Physiotherapy</h1>
                    <h2>Unlock the potential of physiotherapy to manage arthritis pain and enhance your quality of life with Ramp Physio and Fitness.</h2>
                    <p>Living with arthritis can be challenging, but it doesn't have to limit your life. At Ramp Physio and Fitness in Homebush, NSW, we specialize in physiotherapy treatments tailored to alleviate arthritis pain and improve mobility. Our holistic approach to arthritis pain management combines the latest techniques with personalized care to help you achieve lasting relief and maintain an active lifestyle. In this comprehensive guide, we'll explore how physiotherapy can transform your arthritis management strategy.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Arthritis and Physiotherapy</h1>
                        <p>Arthritis is a common condition that causes joint pain, swelling, and stiffness. Physiotherapy is an effective therapy for managing arthritis symptoms, focusing on exercises, manual therapy, and pain relief techniques. Our experienced physiotherapists at Ramp Physio and Fitness will guide you through a personalized program designed to reduce pain, increase joint mobility, and strengthen the muscles surrounding your joints.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Effective Pain Relief Strategies</h1>
                    <p>Our physiotherapy program includes a variety of pain relief strategies such as heat and ice therapy, TENS (transcutaneous electrical nerve stimulation), and soft tissue mobilization. These methods help to reduce inflammation, ease muscle tension, and provide immediate pain relief for arthritis sufferers.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={serviceImage} alt="Service Detail" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Customized Exercise Programs</h1>
                    <p>Exercise is a cornerstone of arthritis management. Our physiotherapists develop customized exercise programs that include range-of-motion exercises, strengthening exercises, and low-impact aerobic activities. These exercises are designed to improve joint function, enhance cardiovascular health, and prevent further joint damage.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="service-intro-content2">
                        <h1>Joint Protection and Education</h1>
                        <p>We believe in empowering our clients with knowledge. Educational sessions on joint protection techniques, ergonomic advice, and lifestyle modifications play a significant role in our physiotherapy program. These sessions help you understand your condition and how to manage it effectively in your daily life.</p>
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container5">
                <div className="service-intro-content5">
                    <h1>Multi-disciplinary Approach</h1>
                    <p>Our approach to managing arthritis pain is multi-disciplinary. We collaborate with other healthcare professionals such as dietitians, occupational therapists, and personal trainers to provide comprehensive care. This team effort ensures you receive the support needed to manage your arthritis from all angles.</p>
                </div>
            </div>
            <div className="service-intro-container6">
                <div className="service-intro-content6">
                    <h1>Long-term Health and Mobility</h1>
                    <p>Our goal is to help you achieve long-term health and mobility. With regular physiotherapy sessions and adherence to a personalized treatment plan, many of our clients experience significant improvements in their arthritis symptoms and overall quality of life.</p>
                    <ul>
                        <li>Joint-specific exercises</li>
                        <li>Manual therapy techniques</li>
                        <li>Modalities for pain relief</li>
                        <li>Customized home exercise plans</li>
                        <li>Educational resources and workshops</li>
                        <li>Supportive braces and aids</li>
                        <li>Lifestyle and dietary advice</li>
                        <li>Regular progress assessments</li>
                        <li>Access to state-of-the-art gym facilities</li>
                        <li>Guidance on activity modification</li>
                    </ul>
                </div>
            </div>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default PhysiotherapyForArthritisPainManagement;