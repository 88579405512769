import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Link } from 'react-router-dom'; // Import Link for routing
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const NdisPhysiotherapyForDisorderedEating = () => {
    return (
        <div>
            <Helmet>
                <title>NDIS Physiotherapy for Disordered Eating | Ramp Physio and Fitness</title>
                <meta name="description" content="Ramp Physio and Fitness provides NDIS-approved physiotherapy for individuals with disordered eating to achieve health and fitness goals." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Empowering Recovery: NDIS Physiotherapy for Disordered Eating</h1>
                    <h2>Discover the pivotal role of physiotherapy in supporting individuals with disordered eating through NDIS at Ramp Physio and Fitness.</h2>
                    <p>Disordered eating patterns can profoundly impact physical health and overall well-being. At Ramp Physio and Fitness, we provide NDIS-approved physiotherapy services tailored to support individuals facing these challenges. Our approach is grounded in compassionate care, evidence-based practice, and a commitment to empowering our clients on their journey to recovery. In this comprehensive guide, we will delve into the benefits of physiotherapy for disordered eating, outline our specialized programs, and share how we can assist in navigating the NDIS for optimal support.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Disordered Eating</h1>
                        <p>Disordered eating encompasses a range of irregular eating behaviors that may not meet the criteria for an eating disorder diagnosis but still pose significant health risks. Recognizing the signs and symptoms is the first step toward seeking help. Physiotherapy plays a crucial role in the multidisciplinary treatment of disordered eating by addressing physical complications, improving body function, and promoting positive body image and self-esteem.</p>

                        <p>Our experienced physiotherapists at Ramp Physio and Fitness work closely with dietitians, psychologists, and other healthcare professionals to deliver holistic care. With a focus on restoring physical health and function, we help our clients rebuild their strength and endurance, manage pain, and regain mobility that may have been compromised due to disordered eating behaviors.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/dumbbells.webp" alt="Exercise" />
                    </div>
                </div>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/massage.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Impact on Physical Function</h1>
                        <p>Disordered eating can have profound effects on physical functioning, including decreased muscle strength, bone density issues, fatigue, and cardiovascular complications. The body's ability to maintain energy levels and perform daily activities may be significantly compromised due to inadequate nutrition and irregular eating patterns. Physical symptoms can vary greatly depending on the specific eating behaviors and their duration, with some individuals experiencing general weakness and reduced exercise tolerance, while others may face more specific challenges such as osteoporosis or heart problems.</p>
                    </div>
                </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>NDIS Support for Disordered Eating</h1>
                    <p>The National Disability Insurance Scheme (NDIS) recognizes the need for support among individuals with disordered eating. Qualifying participants can access funding for services that address both the psychological and physical aspects of their condition. Ramp Physio and Fitness is an NDIS-registered provider, offering physiotherapy interventions that align with the goals set out in an individual's NDIS plan. Our services are designed to improve daily living, enhance quality of life, and support long-term recovery.</p>

                    <p>Key areas we focus on include:</p>
                    <p>- Pain management and musculoskeletal health</p>
                    <p>- Nutritional rehabilitation and exercise routine establishment</p>
                    <p>- Coordination, balance, and proprioception training</p>
                    <p>- Education on body mechanics and injury prevention</p>
                    <p>- Support for reintegration into physical activities</p>
                    <p>- Facilitation of independence and self-management skills</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/Pinloaded.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Personalized Physiotherapy Approach</h1>
                        <p>At Ramp Physio and Fitness, we recognize that every individual's experience with disordered eating is unique. Therefore, we adopt a personalized approach to physiotherapy, creating tailored treatment plans that cater to the specific needs and goals of each client. Our interventions are designed to be flexible, accommodating changes in health status and personal circumstances, ensuring that our clients receive the most appropriate and effective care at all times.</p>

                        <p>Our commitment to personalized care includes:</p>
                        <p>- Initial comprehensive assessments and regular progress evaluations</p>
                        <p>- Development of individualized exercise programs</p>
                        <p>- One-on-one sessions with experienced physiotherapists</p>
                        <p>- Collaboration with other healthcare providers for integrated care</p>
                        <p>- Ongoing support and adjustments to treatment as needed</p>
                        <p>- Use of evidence-based practices and the latest research in physiotherapy</p>

                        <br></br>
                        <p>
                            In conclusion, physiotherapy is an essential component of the treatment and support for individuals with disordered eating. Through our NDIS-approved services at Ramp Physio and Fitness, we are dedicated to providing the highest level of care to help our clients lead healthier, more fulfilling lives. If you or someone you know is struggling with disordered eating and could benefit from our services, please reach out to us to learn how we can support you on your path to recovery.
                        </p>
                    </div>
                </div>
            </SkewedContainer>
            <SubpagesLink />
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NdisPhysiotherapyForDisorderedEating;