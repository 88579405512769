import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const ManagementOfChronicPelvicPainSyndrome = () => {
    return (
        <div>
            <Helmet>
                <title>Chronic Pelvic Pain Syndrome Management | Ramp Physio and Fitness</title>
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Expert Management of Chronic Pelvic Pain Syndrome</h1>
                    <h2>Explore comprehensive strategies for managing Chronic Pelvic Pain Syndrome with the dedicated team at Ramp Physio and Fitness in Homebush, NSW.</h2>
                    <p>Chronic Pelvic Pain Syndrome (CPPS) is a complex condition that affects many individuals, causing significant discomfort and impacting quality of life. At Ramp Physio and Fitness, our expert team is committed to providing evidence-based, multidisciplinary approaches to managing CPPS. In this detailed guide, we'll share insights on the condition and outline how our tailored services can help you navigate and alleviate chronic pelvic pain.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Chronic Pelvic Pain</h1>
                        <p>Chronic Pelvic Pain Syndrome is characterized by persistent pain in the pelvic region that lasts for six months or longer. The condition can have various underlying causes, making diagnosis and treatment a challenge. Symptoms may include pain during intercourse, urination, or bowel movements, and the pain can range from mild to severe, often having a significant impact on daily activities and mental health.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Multi-Faceted Treatment Strategies</h1>
                    <p>Our approach to managing CPPS is comprehensive and patient-centered, involving a combination of physiotherapy, exercise, education, and lifestyle modification. We focus on reducing pain, improving function, and enhancing the overall well-being of our clients through individualized treatment plans.</p>
                    <ul>
                        <li>Physiotherapy and manual therapy techniques</li>
                        <li>Personalized exercise programs</li>
                        <li>Nutritional guidance and lifestyle advice</li>
                        <li>Stress management and relaxation techniques</li>
                        <li>Education on pain science and self-management strategies</li>
                        <li>Referral to other health professionals when necessary</li>
                    </ul>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Physiotherapy's Role in CPPS</h1>
                    <p>Physiotherapy plays a vital role in the management of CPPS, with techniques such as pelvic floor muscle training, biofeedback, and myofascial release proving to be effective. Our physiotherapists are trained in assessing and treating pelvic health issues, providing care that is both sensitive and specialized.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Lifestyle and Wellness Integration</h1>
                    <p>Managing CPPS goes beyond medical treatments; it involves integrating wellness practices into daily life. Our team encourages activities such as RAMP individually tailored and physiotherapy designed and monitored flexibility and core stability programs, and regular physical activity, which have been shown to improve pain management and enhance quality of life.</p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default ManagementOfChronicPelvicPainSyndrome;