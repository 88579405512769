import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const GymWorkoutsForPostureCorrection = () => {
    return (
        <div>
            <Helmet>
                <title>Gym Workouts for Posture Correction - Ramp Physio and Fitness</title>
                <meta name="description" content="Improve your posture with expert-designed gym workouts at Ramp Physio and Fitness in Homebush, NSW. Achieve better health and fitness with our guidance." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Perfect Your Posture with Gym Workouts</h1>
                    <h2>Unlock the secrets to a stronger, healthier back with our targeted gym workouts designed to correct posture and enhance overall wellbeing.</h2>
                    <p>At Ramp Physio and Fitness, we understand the importance of good posture not just for your appearance, but for your overall health. Poor posture can lead to a multitude of issues, including back pain, neck strain, and reduced lung capacity. Our gym workouts for posture correction are crafted by expert physiotherapists to help you strengthen key muscle groups and align your spine for a healthier, more confident you.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Gym Workouts for Posture" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Strengthen and Straighten</h1>
                        <p>Strengthening the muscles that support your spine is critical to posture correction. Our gym workouts focus on both the core and back muscles, ensuring a balanced approach to straightening your posture. Exercises like deadlifts, rows, and planks are just a few examples of what our program includes to help you build a stronger foundation for good posture.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Balanced Muscle Development</h1>
                    <p>Imbalanced muscles can pull your posture out of alignment. Our gym workouts are designed to ensure that opposing muscle groups are equally developed. By incorporating exercises like chest stretches and shoulder blade squeezes, we promote symmetry and balance across your body's musculature.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Core to Your Posture</h1>
                    <p>A strong core is the foundation of good posture. Our workouts include a variety of core-strengthening exercises like the bird dog, side planks, and the Pilates 100, all aimed at enhancing stability and support for your spine.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Posture Correction Techniques</h1>
                        <p>Our gym workouts also focus on techniques that correct posture beyond the gym. We teach you how to maintain proper posture while sitting, standing, and moving throughout your day. These habits, combined with our workouts, ensure that your posture improvements last long-term.</p>
                        <ul>
                            <li>Engage your core when sitting.</li>
                            <li>Keep your shoulders back and down.</li>
                            <li>Adjust your workstation to support a neutral spine.</li>
                            <li>Take frequent breaks to stretch and move.</li>
                            <li>Practice mindful walking with a tall spine.</li>
                        </ul>
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container5">
                <div className="service-intro-content5">
                    <h1>Customized Workout Plans</h1>
                    <p>Every individual's posture issues are unique, which is why we offer customized workout plans tailored to your specific needs. Our physiotherapists conduct thorough assessments to create a workout regime that addresses your posture concerns effectively.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container6">
                    <h1>Expert Guidance and Support</h1>
                    <p>Our team of physiotherapists and fitness experts provide continuous guidance and support throughout your posture correction journey. We ensure that you perform each exercise with proper form and technique to maximize benefits and prevent injury.</p>
                </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default GymWorkoutsForPostureCorrection;