import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const PhysiotherapyForScoliosisManagement = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Expert Scoliosis Management</h1>
                    <h2>Explore specialized physiotherapy for scoliosis management at Ramp Physio and Fitness and take control of your spinal health.</h2>
                    <p>Scoliosis, a condition characterized by an abnormal curvature of the spine, can lead to discomfort, pain, and reduced mobility. At Ramp Physio and Fitness, we are committed to providing expert physiotherapy for scoliosis management. Our tailored approach aids in reducing symptoms and improving quality of life through personalized treatment plans. In this detailed guide, we will explore the benefits of physiotherapy in managing scoliosis, supported by evidence-based practices and our team's dedication to your health and fitness.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Physiotherapy for Scoliosis" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Scoliosis</h1>
                        <p>Scoliosis is more than just a curved spine; it's a complex three-dimensional deformity that can affect individuals of all ages. While the exact cause of scoliosis is often unknown, physiotherapy has been shown to be an effective non-surgical intervention for managing symptoms and improving spinal alignment. Our physiotherapists at Ramp Physio and Fitness are trained in scoliosis-specific exercises that are designed to stabilize and correct the spinal curvature.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Personalized Treatment Plans</h1>
                    <p>Each case of scoliosis is unique, which is why we develop personalized treatment plans tailored to your specific needs. Our physiotherapists conduct thorough assessments to understand the severity and pattern of your spinal curvature, and then create a custom exercise program that targets the affected areas. Individualized treatment plans can include a combination of manual therapy, scoliosis-specific exercises, and education on posture and movement strategies.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Exercise-Based Interventions</h1>
                    <p>Our exercise-based interventions are the cornerstone of scoliosis management. Studies have shown that specific exercises can reduce the progression of the curve and improve symmetry in the torso. Our programs may include the Schroth method, which uses exercises tailored to each patient's curvature to decompress the spine, strengthen weak muscles, and teach the body more natural movements.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Benefits of Physiotherapy</h1>
                        <ul>
                            <li>Reduces pain and discomfort associated with scoliosis</li>
                            <li>Improves spinal alignment and posture</li>
                            <li>Increases flexibility and range of motion</li>
                            <li>Strengthens core and back muscles</li>
                            <li>Enhances breathing and lung capacity</li>
                            <li>Prevents further progression of the spinal curve</li>
                            <li>Boosts overall quality of life</li>
                            <li>Provides tools for self-management and independence</li>
                            <li>Offers a conservative treatment alternative to surgery</li>
                            <li>Supports long-term spinal health</li>
                        </ul>
                        <p>By participating in a physiotherapy program at Ramp Physio and Fitness, you can reap these benefits and take a proactive step towards managing your scoliosis effectively.</p>
                    </div>
                </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default PhysiotherapyForScoliosisManagement;