import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import physioLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const PhysiotherapyTreatmentAndExercisesForMigraines = () => {
    return (
        <div>
            <Helmet>
                <title>Physiotherapy for Migraines - Ramp Physio and Fitness</title>
                <meta name="description" content="Discover how physiotherapy and specific exercises can alleviate migraine symptoms and prevent future episodes. Learn more at Ramp Physio and Fitness." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Alleviating Migraines Through Physiotherapy</h1>
                    <h2>Explore how targeted physiotherapy treatment and exercises can significantly reduce the frequency and intensity of migraine headaches.</h2>
                    <p>Migraines are more than just headaches; they are a complex neurological condition that can cause debilitating symptoms. At Ramp Physio and Fitness, we specialize in providing physiotherapy treatments that can help manage and alleviate migraine pain. Our tailored exercises and manual therapy techniques are designed to address the root causes of migraines, offering our clients a pathway to improved quality of life. In this comprehensive guide, we will delve into how our expert team can assist you in your journey towards migraine relief.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Migraines</h1>
                        <p>Migraines can manifest with a variety of symptoms, including severe throbbing pain, sensitivity to light and sound, nausea, and visual disturbances. The precise mechanisms behind migraines are not fully understood, but they are believed to involve changes in brain activity and the nervous system. Physiotherapy can play a pivotal role in managing these symptoms through non-pharmacological interventions.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/dumbbells.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Manual Therapy Techniques</h1>
                    <p>Our physiotherapists apply manual therapy techniques such as soft tissue mobilization and cervical spine manipulation to relieve muscle tension and improve blood flow. These methods can reduce the stress on the nervous system that may contribute to migraine symptoms.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={physioLogo} alt="Physiotherapy Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>

            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Exercise Therapy for Migraines</h1>
                    <p>Exercise therapy is another essential component of our migraine treatment program. Specific exercises can improve posture, enhance neck stability, and reduce the frequency of migraine attacks. Here are 10 examples of exercises we may include in a treatment plan:</p>
                    <p>
                        • Neck stretches<br />
                        • Shoulder blade squeezes<br />
                        • Upper back extensions<br />
                        • Deep cervical flexor training<br />
                        • Aerobic exercises like walking or swimming<br />
                        • Breathing exercises<br />
                        • Core strengthening<br />
                        • Balance training<br />
                        • Relaxation techniques<br />
                        • Temporomandibular joint (TMJ) exercises<br />
                    </p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Pinloaded.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Preventive Strategies and Education</h1>
                    <p>Educating our clients about migraine triggers and prevention strategies is a crucial part of our approach. We provide guidance on lifestyle adjustments, ergonomic advice, and stress management techniques to help reduce the likelihood of migraine onset.</p>
                    <br></br>
                    <p>In conclusion, Ramp Physio and Fitness offers a comprehensive approach to migraine management through physiotherapy treatment and exercises. Our team is committed to helping you understand and manage your migraines, ultimately leading to a reduction in pain and an improvement in your overall well-being.</p>
                </div>
            </div>
            </SkewedContainer>
            <SubpagesLink />

            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default PhysiotherapyTreatmentAndExercisesForMigraines;