import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/Ramp_cover.webp';
import otherinfoimage from '../assets/RAMP Physio Icon.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';
import { Link } from 'react-router-dom';
const TermsandConditions = () => {

    return (
        <div>
{/* While we test GTM for now, we have removed the Google Analytics code from here */}

            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br /><br />
                    <h1>Terms and Conditions</h1>
                    <p>
                        Welcome to Ramp Physio and Fitness! These terms and conditions outline the rules and regulations for the use of Ramp Physio and Fitness's Website, located at www.rampphysioandfitness.com.au
                        <br /><br />
                        Please read these Terms and Conditions carefully before You use the Website.By using the Website, You indicate that they have, effective upon the date on which You have used the Website, read, accepted and agreed to be bound by these Terms and Conditions.<br /><br />
                        If You do not agree with these Terms and Conditions, You should cease using the Website immediately.
                        <br />
                        <br />
                        Sign up with your email address to receive news and updates.
                    </p>

                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">

                        <h1>Use of Cookies</h1>
                        <p>
                            We employ the use of cookies. By accessing Ramp Physio and Fitness, you agreed to use cookies in agreement with the Ramp Physio and Fitness's Privacy Policy.
                            <br />
                        </p>
                        <p>
                            Most interactive websites use cookies to let us retrieve the user’s details for each visit. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. Some of our affiliate/advertising partners may also use cookies.
                            <br /><br />
                        </p>

                        <h1>License</h1>
                        <p>
                            Unless otherwise stated, Ramp Physio and Fitness and/or its licensors own the intellectual property rights for all material on Ramp Physio and Fitness. All intellectual property rights are reserved. You may access this from Ramp Physio and Fitness for your own personal use subjected to restrictions set in these terms and conditions.
                            <br /><br />
                        </p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>

            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Term, Termination, and Suspension</h1>
                    <p>
                        Unless otherwise specified, the term of this Agreement will be month to month (“Subscription Term”). The Subscription Term commences on the Effective Date and will automatically renew on a monthly basis until either Party terminates in accordance with this Agreement. Either Party may terminate the Agreement and/or any subscription at any time, for any reason or no reason, by providing notice to the other Party at least thirty (30) days before the end of the relevant Subscription Term.
                    </p>
                    <br /><br />
                    <h1>Termination for Cause</h1>
                    <p>
                        Ramp Physio And Fitness may terminate this Agreement and/or any subscription, effective immediately upon notice to you, if you or an Affiliate are in material breach of this Agreement. In the event of a termination for material breach, in addition to other amounts you may owe Ramp Physio And Fitness, you must immediately pay any unpaid Subscription Fees associated with the remainder of the Subscription Term. In no event will any termination relieve you of your obligation to pay any fees payable to Ramp Physio And Fitness for the period prior to the effective date of termination.
                        <br /><br />
                    </p>

                </div>
                <div className="intro-image">
                    <img src="../assets/dumbbells.webp" alt="Exercise" />
                </div>
            </div>



            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Workouts.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Right to Terminate or Suspend Services</h1>
                    <p>
                        We may suspend or terminate your access to and use of the Services (or any portion thereof) at any time without notice if we believe (a) that any activity or use of Services in connection with your account violates this Agreement, the intellectual property rights of a third party or applicable laws or is otherwise disruptive or harmful to Ramp Physio And Fitness or any third party or (b) that we are required to do so by law.
                    </p>
                    <br /><br />
                    <Link to="/australianconsumerlaw" className="cosumerlaw-link">
                        <p>Find out more about Australian Consumer Law (ACL) for Fitness Services.</p>
                    </Link>

                    <br /><br />
                    <h1>You must not: </h1>
                    <ul>
                        <p>
                            <li>Republish material from Ramp Physio and Fitness</li>
                            <li>Sell, rent or sub-license material from Ramp Physio and Fitness</li>
                            <li>Reproduce, duplicate or copy material from Ramp Physio and Fitness</li>
                            <li>Redistribute content from Ramp Physio and Fitness</li></p>
                    </ul>
                    <p><br />
                        This Agreement shall begin on the date hereof.
                        <br /><br />
                    </p>
                </div>
            </div>

            <SkewedContainer top="left" bgColor="white" noMargin>
                <HealthSection />
            </SkewedContainer>
            <TestimonialSection />

            <div className="service-intro-container3">
                <img src={otherinfoimage} alt="RAMP" className="service-intro-logo" />
                <p className="service-intro-text">
                    Ramp Physio and Fitness
                </p>
            </div>

        </div>

    );
};
export default TermsandConditions;
