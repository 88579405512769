import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const OptimizingFatLossWithCircuitTraining = () => {
    return (
        <div>
            <Helmet>
                <title>Optimizing Fat Loss with Circuit Training | Ramp Physio and Fitness</title>
                <meta name="description" content="Discover how circuit training can maximize your fat loss efforts. Ramp Physio and Fitness guides you through effective workouts for optimal results." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Maximizing Fat Loss with Circuit Training</h1>
                    <h2>Unlock the potential of circuit training at Ramp Physio and Fitness to optimize fat loss and enhance your overall fitness in Homebush, NSW.</h2>
                    <p>Are you looking to burn fat efficiently and effectively? Circuit training may be the answer. At Ramp Physio and Fitness, we specialize in creating dynamic circuit training routines that are tailored to help you achieve your fat loss goals. This article will explore the benefits of circuit training, provide tips for optimizing your workouts, and discuss how our expert team can support your fitness journey.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>The Science of Fat Loss</h1>
                        <p>Circuit training is a form of body conditioning that involves endurance training, resistance training, high-intensity aerobics, and exercises performed in a circuit. It targets fat loss by combining strength and cardio exercises that increase your heart rate, boosting metabolism and promoting a higher calorie burn both during and after the workout.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Effective Fat Burning Circuits</h1>
                    <p>Effective circuit training combines exercises that target different muscle groups with minimal rest in between. This keeps your heart rate elevated, ensuring you burn more fat. Here are 10 examples of exercises we incorporate into our fat loss circuits:</p>
                    <ul>
                        <li>Jump squats</li>
                        <li>Push-ups</li>
                        <li>Kettlebell swings</li>
                        <li>Mountain climbers</li>
                        <li>Burpees</li>
                        <li>Dumbbell rows</li>
                        <li>Plank with shoulder taps</li>
                        <li>Box jumps</li>
                        <li>Medicine ball slams</li>
                        <li>Bicycle crunches</li>
                    </ul>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Customizing Your Circuit</h1>
                    <p>Personalization is key to successful fat loss. Our trainers at Ramp Physio and Fitness create customized circuit training programs that align with your fitness level and goals. This ensures that you are working at the right intensity to maximize fat loss while also improving your strength and endurance.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Nutritional Guidance for Fat Loss</h1>
                    <p>While exercise is crucial for fat loss, nutrition cannot be overlooked. Our team provides comprehensive support, including nutritional guidance, to complement your circuit training and help you achieve the best possible results.</p>
                    <br></br>
                    <p>
                        In conclusion, circuit training is an excellent way to optimize fat loss and improve physical fitness. At Ramp Physio and Fitness, we are dedicated to helping you achieve your health and fitness goals through personalized circuit training programs. Join us to experience the transformative effects of circuit training on your body and fitness levels.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default OptimizingFatLossWithCircuitTraining;