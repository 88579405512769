import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';
const NDIS = () => {
    return (
        <div>
{/* While we test GTM for now, we have removed the Google Analytics code from here */}

            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Easing the Burden of Back Pain with Expert Physiotherapy</h1>
                    <h2>Discover how specialized physiotherapy for back pain at Ramp Physio and Fitness can provide relief and restore function through personalized treatment plans and expert care.</h2>
                    <p>Back pain can be a debilitating condition that affects your daily life, but it's not something you have to endure without help. At Ramp Physio and Fitness, we understand the complexities of back pain and the importance of personalized care. Our team of skilled physiotherapists is dedicated to not only alleviating your pain but also to empowering you with the knowledge and exercises needed to prevent its recurrence. In this comprehensive guide, we'll explore the multifaceted approach to physiotherapy for back pain that can help you get back to your favorite activities with confidence and ease.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>The Science Behind Physiotherapy for Back Pain</h1>
                        <p>Physiotherapy has been recognized as a critical component in the management of back pain. It encompasses a range of treatments and exercises designed to reduce pain, improve mobility, and strengthen the muscles that support the spine. The spine itself is a complex structure made up of bones, joints, ligaments, and muscles, and understanding its mechanics is crucial to effective treatment.</p>
                        <p>Our physiotherapists employ evidence-based techniques to diagnose the root cause of your pain. Whether it's a result of poor posture, injury, overuse, or a chronic condition like arthritis, our tailored approach ensures that the treatment plan addresses your specific needs. Therapeutic exercises, manual therapy, and education about body mechanics are integral parts of the rehabilitation process, aiming to restore function and prevent future injuries.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                        <h1>Comprehensive Treatment Plans that Work</h1>
                        <p>At Ramp Physio and Fitness, we believe in a holistic approach to back pain management. After a thorough assessment, our physiotherapists create a comprehensive treatment plan that may include a combination of modalities such as heat and cold therapy, electrical stimulation, and ultrasound to ease pain and reduce inflammation.</p>
                        <p>However, our care extends beyond immediate pain relief. We focus on building a strong foundation through targeted exercises that improve flexibility, core strength, and stability. This not only helps to alleviate current discomfort but also equips you with the tools to maintain a healthy back. Our goal is to help you understand your body better, teaching you how to move, sit, and lift objects safely to minimize the risk of re-injury.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                        <h1>Support Beyond the Clinic</h1>
                        <p>The journey to overcoming back pain doesn't end when you step out of the clinic. At Ramp Physio and Fitness, we are committed to providing ongoing support to ensure you continue to progress. This includes personalized home exercise programs that are easy to integrate into your daily routine, ergonomic advice for your work and home environments, and guidance on how to manage pain during flare-ups.</p>
                        <p>Our physiotherapists are also available for follow-up appointments to monitor your progress and adjust your treatment plan as needed. We believe in building a partnership with our patients, where open communication and education are key to successful outcomes. With our support, you can take charge of your back health and enjoy a more active, pain-free lifestyle.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                        <h1>Empowering You with Knowledge and Tools</h1>
                        <p>Knowledge is power, especially when it comes to managing back pain. At Ramp Physio and Fitness, we emphasize patient education as a cornerstone of our treatment plans. Understanding the causes of back pain, how to prevent it, and how to manage it when it occurs, empowers you to take control of your health.</p>
                        <p>Our physiotherapists provide you with practical tools and strategies to manage your pain, whether you're at home, work, or engaging in physical activity. We also offer group training classes that focus on core strength and stability, which are crucial for a healthy back. For those with chronic conditions, we offer specialized programs that address long-term pain management, helping you to lead a fulfilling life despite your pain.</p>
                    <br></br>
                    <p>
                        In conclusion, physiotherapy for back pain at Ramp Physio and Fitness is not just about treating symptoms; it's about equipping you with the understanding and skills to take an active role in your recovery and future well-being. Whether you're an athlete managing a sports injury or someone struggling with chronic back pain, our team is here to support you every step of the way.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NDIS;
