import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';
const normatech3 = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Revolutionize Your Recovery with Normatec 3</h1>
                    <h2>Discover how Normatec 3's cutting-edge compression technology enhances recovery and performance at Ramp Physio and Fitness, providing a comprehensive solution for athletes and individuals managing chronic pain or seeking improved fitness outcomes.</h2>
                    <p>At Ramp Physio and Fitness, we are always on the lookout for innovative methods to support your health and athletic performance. That's why we're excited about the Normatec 3 systems, which represent the pinnacle of recovery technology. Whether you're an athlete looking to improve your game, a patient overcoming an injury, or someone managing chronic pain, Normatec 3 offers a dynamic solution to accelerate your recovery and optimize your health. In this article, we'll delve into how Normatec 3 can benefit you and why it's a vital addition to our suite of services.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Elevating Recovery with Normatec 3</h1>
                        <p>Normatec 3's groundbreaking technology is not just a trend; it's a transformation in physical therapy and fitness. This advanced compression device is designed to enhance blood flow, reduce muscle soreness, and accelerate recovery times. The Normatec 3 Legs system focuses on lower extremity recovery, proving invaluable for runners, cyclists, and anyone with leg-intensive routines. Meanwhile, the Normatec 3 Full Body system takes a holistic approach, targeting multiple body areas for those who need comprehensive recovery solutions. By incorporating Normatec 3 into your regimen, you're not just recovering; you're ramping up your body's natural healing processes.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Personalized Recovery Journeys</h1>
                    <p>Your recovery should be as unique as you are. That's the philosophy we embrace at Ramp Physio and Fitness, and Normatec 3 aligns perfectly with this approach. With customizable settings and pressure levels, Normatec 3 systems provide tailored recovery experiences that adapt to your specific needs and preferences. Whether you're dealing with post-marathon fatigue, rehabilitating from an injury, or managing chronic conditions like lymphedema, Normatec 3's personalized compression patterns mimic natural muscle contractions, promoting effective and efficient recovery across various scenarios.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>The Science Behind Compression</h1>
                    <p>Understanding the science behind compression therapy is crucial to appreciating the benefits of Normatec 3. Compression therapy works by applying controlled pressure to the limbs, enhancing venous return and lymphatic drainage. This process helps flush out metabolic waste, reduce inflammation, and improve nutrient delivery to tired muscles. By doing so, Normatec 3 not only aids in quicker recovery but also prepares your body for the next physical challenge, making it an essential tool for athletes and fitness enthusiasts aiming to maintain peak performance levels.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Group train.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Integrating Normatec 3 into Your Routine</h1>
                    <p>At Ramp Physio and Fitness, we believe in a holistic approach to health and wellness. Integrating Normatec 3 into your routine is a step towards achieving a more balanced and effective recovery strategy. Whether you opt for a session following a strenuous workout or as part of your regular wellness plan, Normatec 3 serves as a complement to traditional physiotherapy and fitness regimens. Our team is committed to guiding you through the process, ensuring that you get the most out of your Normatec 3 experience and step back into your daily activities or sports with renewed vigor and less downtime.</p>
                    <br></br>
                    <p>
                        In conclusion, the Normatec 3 systems are more than just recovery tools; they are investments in your health and performance. At Ramp Physio and Fitness, we are proud to offer these state-of-the-art solutions as part of our commitment to your well-being. Whether you're on a journey towards peak athletic performance or simply striving for a pain-free life, Normatec 3 is here to elevate your recovery and help you achieve your goals. Join us and experience the difference that advanced recovery technology can make in your fitness and rehabilitation journey.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default normatech3;
