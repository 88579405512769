import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';
const NDIS = () => {
    return (
        <div>
{/* While we test GTM for now, we have removed the Google Analytics code from here */}

            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Revolutionizing Recovery: Shockwave Therapy at Ramp</h1>
                    <h2>Discover the transformative impact of shockwave therapy on sports injuries and chronic pain management, as we delve into the science and success stories at Ramp Physio and Fitness.</h2>
                    <p>At Ramp Physio and Fitness, we understand that recovery is not just a process—it's a journey towards reclaiming your body's peak performance and overall well-being. As a dedicated physiotherapist, I've seen firsthand the frustration injuries can cause for athletes and fitness enthusiasts alike. That's why we're proud to offer shockwave therapy, a cutting-edge treatment that's been gaining recognition for its effectiveness in managing sports injuries and chronic pain conditions. This non-invasive method can accelerate recovery, reduce pain, and enhance the healing process, making it a game-changer in physiotherapy care.to navigate this often daunting path to healing and strength.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Shockwave Therapy</h1>
                        <p>Shockwave therapy, also known as Extracorporeal Shock Wave Therapy (ESWT), is the application of targeted sound waves to affected areas of the body. These high-energy waves stimulate the body's natural healing mechanisms, promote cellular repair, and improve blood circulation. It's a preferred treatment for a variety of musculoskeletal conditions, including plantar fasciitis, tendonitis, and muscle pain.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Revolutionizing Pain Management</h1>
                    <p>Shockwave therapy represents a paradigm shift in how we approach pain management. Traditional methods often rely on medication and invasive procedures, but shockwave therapy offers a safe and effective alternative. By using the body's intrinsic healing abilities, patients can experience relief without the side effects commonly associated with pain medications.</p>
                    <p>Also known as Extracorporeal Shockwave Therapy or ESWT, the treatment works by delivering shockwaves directly to the area of discomfort, which can lead to a cascade of beneficial effects. It improves blood flow, encourages tissue healing, and can even break down calcifications in tendons and soft tissues. For individuals with chronic pain, these effects can mean a significant improvement in quality of life.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Enhancing Athletic Performance</h1>
                    <p>Athletes are always looking for ways to improve their performance and reduce the risk of injury. Shockwave therapy not only aids in the recovery process but also has the potential to enhance muscle tone and elasticity. This makes it an invaluable tool for athletes looking to stay at the top of their game.In addition to treating existing injuries, shockwave therapy can be used preemptively to address areas of tension or stress before they develop into more serious conditions. This proactive approach to injury prevention is why many elite athletes have incorporated shockwave therapy into their regular training regimens.</p>  
                    <h1>The Non-Invasive Advantage</h1>
                    <p>One of the most appealing aspects of shockwave therapy is its non- invasive nature. Unlike surgical procedures, there is no need for anesthesia, incisions, or prolonged recovery periods. Treatments are typically quick, with sessions lasting around 15-20 minutes, and most patients can return to their daily activities immediately after. The lack of invasiveness also means that shockwave therapy carries fewer risks than surgical alternatives. There's no risk of infection or complications from anesthesia, making it a safer option for those who might not be candidates for surgery.</p>  

                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>A Tailored Approach to Treatment:</h1>
                    <p>At Ramp Physio and Fitness, we believe in a personalized approach to healthcare. Shockwave therapy is not a one-size-fits-all solution, and we work closely with our patients to determine the most effective treatment plan for their specific condition. Factors such as the severity of the injury, the patient's overall health, and their recovery goals all play a role in how we utilize shockwave therapy. Our experienced team of physiotherapists is skilled in assessing and applying shockwave therapy to ensure the best possible outcomes. We monitor progress closely and adjust treatments as needed to ensure that our patients are on the fastest track to recovery.</p>
                    <br></br>
                    <p>
                        Shockwave therapy is more than just a treatment; it's a transformative approach to healing that aligns with our mission at Ramp Physio and Fitness—to provide top-tier healthcare and personalized training to help our patients achieve their goals. Whether you're an athlete looking to enhance your performance or someone seeking relief from chronic pain, shockwave therapy offers a compelling, non-invasive option that can lead to remarkable results. Let us help you unlock your body's potential and take the first step towards a pain-free, more active life.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NDIS;
