import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';
const NDIS = () => {
    return (
        <div>
{/* While we test GTM for now, we have removed the Google Analytics code from here */}

            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Revolutionizing Pain Relief with Shockwave Therapy</h1>
                    <h2>Discover the transformative power of shockwave therapy for managing pain and accelerating recovery at Ramp Physio and Fitness.</h2>
                    <p>Welcome to Ramp Physio and Fitness, where we merge cutting-edge technology with personalized care to help you achieve optimal health and performance. As a dedicated physiotherapist, I'm excited to introduce you to a revolutionary treatment that's changing the landscape of pain relief and injury recovery: shockwave therapy. This non-invasive treatment option has been gaining traction for its effectiveness in managing various musculoskeletal conditions. Let's delve into what shockwave therapy entails and how it can be a game-changer in your healing journey.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>The Science Behind Shockwave Therapy</h1>
                        <p>Shockwave therapy, a modern approach to pain management, uses acoustic waves to target areas of chronic pain and stimulate the body's natural healing processes. The therapy works by emitting high-energy sound waves into the affected tissues, which can help break down calcifications, enhance blood circulation, and promote cell regeneration. This process not only alleviates pain but also contributes to faster recovery from injuries.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Understanding the Treatment Process</h1>
                    <p>When you come in for shockwave therapy, you'll experience a non-invasive procedure that typically lasts around 5 to 10 minutes per session. You may require a series of treatments, usually once a week for about 3 to 6 weeks, depending on the severity of your condition. It's normal to feel immediate pain relief after the first few sessions, but the most significant benefits tend to occur after the body has had time to respond fully to the treatment.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>A Versatile Solution for Diverse Conditions</h1>
                    <p>Shockwave therapy has a broad range of applications. It's particularly effective for conditions like plantar fasciitis, Achilles tendinopathy, tennis elbow, and jumper's knee. However, its benefits extend to other chronic pain scenarios, such as shoulder tendinitis and calcific rotator cuff tendinopathy. By enhancing blood flow and facilitating tissue repair, shockwave therapy can significantly reduce pain and improve function in these common ailments.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Complementing Your Recovery Journey</h1>
                    <p>At Ramp Physio and Fitness, we believe in a holistic approach to recovery. While shockwave therapy can be a standalone treatment, it often achieves the best results when combined with other physiotherapy modalities and personalized training programs. Our expert team will work with you to create a comprehensive recovery plan that may include manual therapy, exercise prescription, and lifestyle advice to support your healing process.</p>
                    <br></br>
                    <p>
                        In conclusion, shockwave therapy is a powerful tool in the arsenal of pain relief and injury management. At Ramp Physio and Fitness, we're committed to providing you with the latest and most effective treatments to help you overcome pain and reclaim your active lifestyle. If you're ready to experience the benefits of shockwave therapy, reach out to our team today and take the first step towards a pain-free life.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NDIS;
