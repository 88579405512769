import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const NDIS = () => {
    return (
        <div>
{/* While we test GTM for now, we have removed the Google Analytics code from here */}

            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Navigating the Road to ACL Recovery</h1>
                    <h2>Explore the critical stages of ACL rehab and the innovative approaches at Ramp Physio and Fitness for optimal recovery, tailored to individual needs and sports-specific demands.</h2>
                    <p>At Ramp Physio and Fitness, we understand that an ACL injury isn't just a setback; it's a challenge to come back stronger. With the right guidance and personalized care, we believe that every step of the ACL rehab journey can be a stride towards your ultimate fitness goals. Our approach combines evidence-based practices with a supportive environment, ensuring that you're not just recovering, but also learning how to prevent future injuries and enhance your performance.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>The Journey Begins: Early ACL Rehab Phases</h1>
                        <p>The initial stages of ACL rehabilitation are crucial for setting the foundation of a successful recovery. Post-surgery, the focus is on reducing swelling, regaining range of motion, and preventing muscle atrophy. This is where our physiotherapists step in with a gentle, yet effective regimen designed to get you moving safely. We employ methods to manage pain and inflammation, such as cryotherapy and soft tissue mobilization, while also introducing exercises that promote joint mobility without compromising the healing graft.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Strength and Stability: Mid-Stage Rehab</h1>
                        <p>As you progress, the emphasis shifts towards building strength and stability around the knee. Our team crafts a series of exercises that target the quadriceps, hamstrings, and hip muscles, essential supporters of the knee joint. We also incorporate balance and proprioception activities to retrain your nervous system, improving your body's awareness and control. This phase isn't just about recovering; it's about reconditioning your body to handle the demands of daily activities and your specific sport.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Functional Training: Advanced Rehab Techniques</h1>
                        <p>When you reach the advanced stages of ACL rehab, the real transformation begins. This is where functional training takes center stage at Ramp Physio and Fitness. We simulate real-world and sports-specific scenarios that challenge your knee in a controlled environment. By integrating plyometric exercises and agility drills, we help restore your confidence and prepare your body for the dynamic movements required in sports. Our goal is to bridge the gap between rehabilitation and peak performance, ensuring a seamless transition back to the field or court.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Lifelong Wellness: Prevention and Performance</h1>
                    <p>Recovery from an ACL injury doesn't end with the completion of a rehab program. At Ramp Physio and Fitness, we're committed to your long-term health and athletic success. Our approach extends beyond rehabilitation, focusing on injury prevention strategies and performance enhancement. We educate you on the importance of proper technique, muscle balance, and strength symmetry to minimize the risk of re-injury. Additionally, we offer ongoing support through group training classes and personalized training plans that keep you at the top of your game.</p>
                    <br></br>
                    <p>
                        In conclusion, ACL rehab is a multifaceted journey that requires patience, dedication, and the right support system. At Ramp Physio and Fitness, we're dedicated to providing that support through every twist and turn of the recovery process. With our comprehensive approach, state-of-the-art facilities, and expert team, we're here to ensure that your path to ACL recovery is as smooth and successful as possible.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NDIS;
