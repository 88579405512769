import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const Buildingmuscleonaplantbaseddiet = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Building Muscle on a Plant-Based Diet</h1>
                    <h2>Unlock the potential of plant-based nutrition for muscle growth at Ramp Physio and Fitness, where we guide you on your journey to achieving strength and fitness sustainably.</h2>
                    <p>At Ramp Physio and Fitness, we understand the importance of diet in achieving your health and fitness goals. Building muscle on a plant-based diet is not only possible but can be incredibly effective with the right knowledge and approach. In this comprehensive guide, you'll discover how to optimize your plant-based diet for muscle gain, debunk common misconceptions, and learn the benefits of fueling your body with plant-based power.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Maximize Muscle with Plants</h1>
                        <p>Plant-based diets are often associated with numerous health benefits, but they can also be tailored to support muscle growth and athletic performance. Learn the essential nutrients and best sources of plant-based protein that can help you build lean muscle mass, and understand how to balance your macronutrients for optimal results.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Plant-Powered Strength Training</h1>
                    <p>Strength training is a critical component of building muscle, and when combined with a plant-based diet, it can lead to significant gains. We'll explore the synergy between plant-based nutrition and strength training, and provide tips on how to structure your workouts to complement your diet for maximum muscle growth.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Debunking Plant-Based Myths</h1>
                    <p>There are many misconceptions about the efficacy of plant-based diets for muscle building. We'll address these myths head-on, providing scientific evidence and real-world examples that showcase the muscle-building potential of a well-planned plant-based diet.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Plant-Based Diet Success Stories</h1>
                    <p>Be inspired by success stories from athletes and fitness enthusiasts who have seen remarkable results from adopting a plant-based diet. Their experiences illustrate that building muscle and achieving peak fitness is entirely achievable on a diet rooted in plant-based nutrition.</p>
                    <br></br>
                    <p>
                        In conclusion, building muscle on a plant-based diet is not just a possibility; it's a reality for many. At Ramp Physio and Fitness, we're dedicated to supporting you in your quest for a stronger, healthier body through sustainable dietary choices. Let us show you how a plant-based diet can be a powerful ally in your muscle-building journey. Join us and empower your fitness with the natural strength of plants.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default Buildingmuscleonaplantbaseddiet;