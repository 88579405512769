import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const Gymworkoutsduringpregnancysafetytips = () => {
    return (
        <div>
            <Helmet>
                <title>Gym Workouts During Pregnancy: Safety Tips</title>
                <meta name="description" content="Stay fit and healthy during pregnancy with our expert tips on safe gym workouts. Learn the best exercises and precautions for a happy, healthy pregnancy." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Safe Gym Workouts During Pregnancy</h1>
                    <h2>Embrace a healthy pregnancy with our tailored gym workouts and safety tips designed for expectant mothers.</h2>
                    <p>At Ramp Physio and Fitness, we understand the importance of maintaining an active lifestyle during pregnancy. Exercising while expecting can benefit both you and your baby, promoting better sleep, reducing pregnancy discomforts, and preparing your body for childbirth. However, it's crucial to approach gym workouts with safety in mind. This comprehensive guide will walk you through the do's and don'ts of gym workouts during pregnancy, ensuring a safe and beneficial fitness journey.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Pregnant Woman Working Out" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Pregnancy and Exercise</h1>
                        <p>Before diving into gym workouts, it's essential to understand how pregnancy affects your body and the role of exercise. Moderate physical activity during pregnancy has numerous health benefits and is generally safe for most women. However, every pregnancy is unique, and it's crucial to consult with your healthcare provider before starting any new exercise routine. They can provide personalized advice based on your health and pregnancy progression.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Exercise Guidelines for Expectant Mothers</h1>
                    <p>Adhering to specific guidelines can help ensure the safety and effectiveness of your workouts during pregnancy. These include:
                        <ul>
                            <li>Listening to your body and adjusting your routine as needed.</li>
                            <li>Avoiding contact sports and activities with a high risk of falling.</li>
                            <li>Staying hydrated and avoiding overheating.</li>
                            <li>Including a mix of cardiovascular, strength, and flexibility exercises.</li>
                            <li>Limiting exercises that involve lying flat on your back after the first trimester.</li>
                        </ul>
                    </p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Top Safe Pregnancy Exercises</h1>
                    <p>While many exercises are safe during pregnancy, some are particularly beneficial. Here are our top recommendations:
                        <ul>
                            <li>Prenatal physiotherapist designed exercise programs for flexibility and stress relief.</li>
                            <li>Swimming for a low-impact full-body workout.</li>
                            <li>Walking for cardiovascular health without the strain.</li>
                            <li>RAMP individually tailored and physiotherapy designed and monitored core stability programs for core strength and stability.</li>
                            <li>Stationary cycling for a safe cardio option.</li>
                        </ul>
                    </p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Customizing Your Workout Plan</h1>
                    <p>Creating a personalized workout plan during pregnancy is key to staying safe and motivated. Our fitness experts at Ramp Physio and Fitness can help tailor a program that suits your needs, taking into consideration your fitness level, stage of pregnancy, and any medical concerns. We'll work with you to ensure your workouts are both safe and enjoyable, helping you maintain your fitness while nurturing your pregnancy.</p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default Gymworkoutsduringpregnancysafetytips;