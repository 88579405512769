import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const NDIS = () => {
    return (
        <div>
{/* While we test GTM for now, we have removed the Google Analytics code from here */}

            <div className="service-detail-content">
                <div className="service-detail-text">
                <br></br><br></br>
                    <h1>Back Pain</h1>
                    <h2>Expert Back Pain Treatment for Lasting Relief</h2>
                    <p>At Ramp Physio and Fitness, we understand that back pain can be debilitating and affect every aspect of your life. Whether you're dealing with acute pain from a recent injury or chronic discomfort that has persisted for years, our expert physiotherapists are here to help. We offer comprehensive assessments to identify the root cause of your pain and develop a personalized treatment plan aimed at providing lasting relief.</p>
                    <p>Struggling with back pain? Don’t let it hold you back any longer. Contact us today to book an assessment and start your journey to a pain-free life with our expert physiotherapists.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Uncovering the Causes and Symptoms of Back Pain</h1>
                        <p>Back pain can stem from various causes, including poor posture, muscle strain, herniated discs, or underlying medical conditions. Symptoms can range from a dull ache to sharp, shooting pains that radiate down the legs. At Ramp Physio and Fitness, we help you understand the specific cause of your back pain and address it with targeted treatments that focus on both relief and prevention.</p>
                        <p>Wondering what's causing your back pain? Let our experts help you identify the root cause and develop a treatment plan that works. Schedule an appointment today and take the first step toward relief.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Customized Back Pain Treatment Programs for Effective Relief</h1>
                    <p>No two cases of back pain are alike, which is why we offer customized treatment programs tailored to your specific needs. Our physiotherapy services include manual therapy, exercise prescription, posture correction, and ergonomic advice to help you manage and alleviate back pain. We work with you to restore mobility, strengthen your core, and prevent future episodes of pain.</p>
                    <p>Ready to find a solution to your back pain? Contact us to create a personalized treatment plan that fits your lifestyle and helps you achieve long-term relief.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Finding Relief from Back Pain with Our Physiotherapy</h1>
                    <p>Our clients' success stories demonstrate the effectiveness of our back pain treatment programs. From overcoming chronic pain to regaining full mobility, we are proud to have helped numerous clients improve their quality of life. Read their testimonials and discover how Ramp Physio and Fitness can assist you in achieving the same results.</p>
                    <p> Inspired by our clients' success? Join them in finding relief from back pain with our expert physiotherapy services. Contact us today to book your appointment and start your path to recovery.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="white" noMargin>
                <HealthSection />
            </SkewedContainer>
            <TestimonialSection />
        </div>
    );
};

export default NDIS;
