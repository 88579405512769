import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const BalancingCardioAndStrengthTraining = () => {
    return (
        <div>
            <Helmet>
                <title>Balancing Cardio and Strength Training | Ramp Physio and Fitness</title>
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Mastering the Art of Fitness</h1>
                    <h2>Discover the perfect blend of cardio and strength training to maximize your health and fitness results with Ramp Physio and Fitness.</h2>
                    <p>Finding the right balance between cardiovascular exercises and strength training can be a puzzle for many fitness enthusiasts. At Ramp Physio and Fitness, we provide expert guidance to help you craft the optimal workout regimen that combines the endurance-building benefits of cardio with the muscle-strengthening advantages of weight training. In this comprehensive guide, we'll explore strategies to harmonize these two essential fitness components for a well-rounded approach to your health and fitness goals.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Dual Benefits of Cardio and Strength</h1>
                        <p>Cardiovascular workouts and strength training are the pillars of a well-rounded fitness program. Cardio exercises such as running, cycling, and swimming are excellent for improving heart health, burning calories, and enhancing stamina. Strength training, on the other hand, focuses on building muscle mass, increasing metabolic rate, and bolstering bone density. When combined, they create a synergistic effect that can lead to better overall health and a leaner, more toned physique.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Creating a Balanced Workout Plan</h1>
                    <p>Developing a workout plan that accommodates both cardio and strength training can be challenging. At Ramp Physio and Fitness, we recommend a balanced approach that includes:
                        <ul>
                            <li>3-4 days of cardiovascular activities</li>
                            <li>2-3 days of strength training exercises</li>
                            <li>At least 1 day of rest for optimal recovery</li>
                            <li>Variation in intensity and duration to keep the body guessing</li>
                            <li>Incorporation of full-body exercises and targeted workouts</li>
                            <li>Consistency and progression for sustainable improvements</li>
                        </ul>
                    </p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Maximizing Fat Loss and Muscle Gain</h1>
                    <p>Understanding the role of cardio and strength training in fat loss and muscle gain is key to achieving your fitness goals. While cardio is great for burning calories and fat, strength training is essential for building muscle, which in turn can increase your resting metabolic rate. To maximize fat loss and muscle gain, it's important to:
                        <ul>
                            <li>Combine high-intensity interval training (HIIT) with traditional cardio for effective fat burning.</li>
                            <li>Incorporate compound lifts like squats, deadlifts, and bench presses to engage multiple muscle groups.</li>
                            <li>Include adequate protein intake in your diet to support muscle repair and growth.</li>
                            <li>Monitor your progress and adjust your training intensity as needed.</li>
                            <li>Ensure proper rest and recovery to prevent overtraining and injury.</li>
                            <li>Seek professional guidance to tailor a plan specific to your body type and goals.</li>
                        </ul>
                    </p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Personalized Training for Optimal Results</h1>
                        <p>At Ramp Physio and Fitness, we understand that every individual's fitness journey is unique. That's why we offer personalized training programs that take into account your specific needs, preferences, and goals. Our expert trainers will work with you to create a balanced routine that incorporates the right mix of cardio and strength training, ensuring you achieve the best possible results.</p>
                        <br></br>
                        <p>
                            Embrace the journey towards a healthier, stronger you with Ramp Physio and Fitness. Our state-of-the-art gym, professional trainers, and commitment to your success set us apart. Whether you're looking to shed pounds, build muscle, or simply improve your overall fitness, we're here to support you every step of the way. Join us and experience the transformation that a balanced approach to cardio and strength training can bring to your life.
                        </p>
                    </div>
                </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default BalancingCardioAndStrengthTraining;