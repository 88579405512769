import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';
const NDIS = () => {
    return (
        <div>
{/* While we test GTM for now, we have removed the Google Analytics code from here */}

            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Navigating the Recovery Terrain: Sports Injury Insights</h1>
                    <h2>Discover essential information on ACL injuries, including causes, This comprehensive guide delves into the complexities of sports injuries, offering insights into prevention, treatment, and recovery strategies at Ramp Physio and Fitness.</h2>
                    <p>At Ramp Physio and Fitness, we understand that sports and physical activities are vital for maintaining a healthy lifestyle. However, the risk of injury is an inherent part of any athletic endeavor. As a dedicated physiotherapist, I see the impact of sports injuries firsthand and am committed to guiding athletes through their recovery journeys. This article aims to shed light on the nature of sports injuries, drawing from authoritative sources to help you understand their prevention, management, and the tailored services we offer to get you back in the game.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Sports Injuries</h1>
                        <p>Sports injuries are a common concern for athletes and fitness enthusiasts alike. They can range from acute traumas, such as sprains and fractures, to overuse injuries like tendinitis and stress fractures. The National Institute of Arthritis and Musculoskeletal and Skin Diseases (NIAMS) emphasizes that understanding the mechanics of these injuries is crucial for effective prevention and treatment. At Ramp Physio and Fitness, our approach aligns with this perspective, as we assess each injury within the context of our clients' unique physical profiles and sporting activities.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Prevention and Early Intervention</h1>
                    <p>Prevention is the first line of defense against sports injuries. According to the Cleveland Clinic, proper training, warm-up routines, and the use of appropriate equipment play a significant role in minimizing injury risks. At Ramp Physio and Fitness, we advocate for a proactive approach, offering personalized training programs and education on injury prevention techniques. Moreover, for those who have already experienced an injury, the Australian Institute of Health and Welfare (AIHW) underscores the importance of early intervention to prevent further damage and promote a swift recovery.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Comprehensive Treatment and Management</h1>
                    <p>Once an injury occurs, the focus shifts to treatment and management. Johns Hopkins Medicine outlines various treatment options, including rest, ice, compression, and elevation (R.I.C.E.), as well as more advanced interventions like physical therapy and surgery. Our facility is equipped to provide a full spectrum of care, from initial acute management to ongoing physiotherapy. We specialize in chronic pain management, drawing on the latest research and techniques to tailor rehabilitation plans that cater to the individual needs of our clients.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Returning to Peak Performance</h1>
                    <p>The final phase of the sports injury journey is the return to peak performance. Healthline highlights the importance of a gradual return to activity, which allows the body to adapt without overstressing the injured area. At Ramp Physio and Fitness, we offer group training classes and NDIS support to ensure that our clients not only recover but also regain their strength and confidence to perform at their best. Our commitment to your health extends beyond the treatment of your injury; we're here to support your overall fitness and well-being.</p>
                    <br></br>
                    <p>
                       Sports injuries are a multifaceted challenge that requires a nuanced approach. At Ramp Physio and Fitness, we are dedicated to providing expert care that spans the full continuum from prevention to peak performance. With our comprehensive services and individualized attention, we are your trusted partner in overcoming sports injuries and achieving your fitness goals.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NDIS;
