import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const DegenerativeDiscs = () => {
    return (
        <div>
            <Helmet>
            <title>Physiotherapy for Degenerative Disc Disease | Ramp Physio and Fitness</title>
  <meta
    name="description"
    content="Effective physiotherapy treatments for Degenerative Disc Disease to alleviate pain and improve spinal function. Contact Ramp Physio and Fitness today."
  />
                {/* Google tag (gtag.js) */}
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-P52MNG23ES"></script>
                <script>
                    {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());

                        gtag('config', 'G-P52MNG23ES');
                    `}
                </script>
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br /><br />
                    <h1>Physiotherapy for Degenerative Disc Disease</h1>
                    <h2>Effective Treatment for Lasting Relief from Degenerative Disc Disease</h2>
                    <p>
                        At <strong>Ramp Physio and Fitness</strong>, we specialize in providing effective physiotherapy treatments for Degenerative Disc Disease to help you alleviate pain and improve spinal function. Degenerative Disc Disease is a condition where the intervertebral discs of the spine deteriorate or break down, leading to pain, discomfort, and reduced mobility. This degeneration is often a natural part of aging but can be accelerated by factors such as injury, repetitive stress, or genetics. Our experienced physiotherapists are dedicated to identifying the root cause of your symptoms and developing a personalized treatment plan to promote healing and prevent future issues.
                    </p>
                    <p>
                        Experiencing pain from Degenerative Disc Disease? Don't let it disrupt your life any longer. <a href="/contact">Contact us</a> today to schedule an appointment and take the first step toward a pain-free life.
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Degenerative Disc Disease and Its Causes</h1>
                        <p>
                            Degenerative Disc Disease occurs when the spinal discs lose their flexibility, elasticity, and shock-absorbing characteristics. This can result from aging, wear and tear, or injury. As the discs deteriorate, they may develop cracks or tears, leading to inflammation and pain. Symptoms often include chronic back or neck pain, numbness or tingling in the limbs, and decreased range of motion. Understanding the underlying cause is essential for effective treatment and long-term relief.
                        </p>
                        <p>
                            Not sure if your pain is due to Degenerative Disc Disease? Our experts can provide a thorough assessment to identify the source of your discomfort. <a href="/appointments">Schedule an appointment</a> today to begin your journey to recovery.
                        </p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Our Comprehensive Approach to Degenerative Disc Disease Treatment</h1>
                    <p>
                        At Ramp Physio and Fitness, we adopt a holistic approach to treating Degenerative Disc Disease. Our physiotherapists create individualized treatment plans that may include manual therapy, specific exercises, posture correction, and education on spinal mechanics. This comprehensive strategy aims to reduce pain, improve mobility, and strengthen supporting muscles to prevent further degeneration.
                    </p>
                    <p>
                        Ready to embark on a personalized treatment journey? <a href="/contact">Contact us</a> to develop a comprehensive plan tailored to your needs.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a
                        href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>

            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Success Stories from Our Clients</h1>
                    <p>
                        Many of our clients have experienced significant relief from Degenerative Disc Disease through our specialized physiotherapy programs. From regaining mobility to returning to daily activities without pain, we are proud to have made a positive impact on their lives. Read their testimonials to learn how Ramp Physio and Fitness has helped them manage Degenerative Disc Disease.
                    </p>
                    <br />
                      <h1>Our Physiotherapy Treatments for Degenerative Disc Disease</h1>
                    <p>
                        Our treatment approach involves a combination of therapies designed to address your specific symptoms and needs. Manual therapy techniques are used to reduce muscle tension and improve joint mobility, helping to alleviate pain and stiffness. We prescribe tailored exercise programs that focus on strengthening the core and back muscles, enhancing spinal support, and improving flexibility. Additionally, we provide education on posture correction and ergonomic adjustments to reduce strain on your spine during daily activities. By integrating these treatments, we aim to not only relieve your current discomfort but also empower you with the tools to manage your condition effectively.
                    </p>
                    <br />
                    <h1>Who Can Benefit from Our Degenerative Disc Disease Treatment?</h1>
                    <p>
                        Our Degenerative Disc Disease treatment is suitable for individuals experiencing chronic back or neck pain due to disc degeneration. Whether your symptoms are mild or severe, our physiotherapists can provide effective interventions to alleviate discomfort and improve function. Early intervention can slow the progression of the disease and promote a better quality of life.
                    </p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>What to Expect During Your Physiotherapy Sessions</h1>
                        <p>
                            Your comfort and recovery are our top priorities during your physiotherapy sessions. Our skilled physiotherapists will conduct a thorough assessment to understand your condition and develop a personalized treatment plan. During your sessions, you may receive manual therapy to improve joint mobility, guided exercises to strengthen supporting muscles, and advice on posture and ergonomics to support your recovery.
                        </p>
                        <p>
                            Have questions about the treatment process? Our team is here to guide you every step of the way. <a href="/contact">Contact us</a> to learn more.
                        </p>
                        <br />
                        <h1>Combining Therapies for Optimal Results</h1>
                        <p>
                            To maximize your recovery, we often combine various physiotherapy techniques tailored to your specific needs. This integrative approach addresses both immediate symptoms and underlying causes, promoting long-term relief and preventing further degeneration.
                        </p>
                        <p>
                            Ready to enhance your recovery journey? <a href="/contact">Contact us</a> to develop a comprehensive treatment plan designed just for you.
                        </p>
                    </div>
                </div>
            </SkewedContainer>
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default DegenerativeDiscs;
