import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Link } from 'react-router-dom'; // Import Link for routing
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const NdisPhysiotherapyForMultipleSclerosis = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Empowering Multiple Sclerosis Management through NDIS Physiotherapy</h1>
                    <h2>Discover the specialized NDIS physiotherapy services for Multiple Sclerosis at Ramp Physio and Fitness, tailored to enhance mobility, strength, and independence.</h2>
                    <p>Multiple Sclerosis (MS) is a lifelong neurological condition that can significantly affect physical function and independence. At Ramp Physio and Fitness, we are dedicated to providing NDIS participants with expert physiotherapy services designed to manage the symptoms of MS. Our approach is rooted in understanding the unique challenges faced by individuals with MS and offering evidence-based, personalized treatment plans to help them reach their health and fitness goals.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding MS and Physiotherapy Benefits</h1>
                        <p>Multiple Sclerosis is a condition where the immune system mistakenly attacks the protective myelin sheath covering nerve fibers, causing communication issues between the brain and the body. Symptoms can vary widely, including fatigue, mobility issues, and muscle spasticity. Physiotherapy plays a crucial role in managing these symptoms, improving functional abilities, and maintaining quality of life.</p>

                        <p>At Ramp Physio and Fitness, we begin with a thorough assessment to understand each client's specific MS-related challenges. From there, we develop a physiotherapy plan that may include exercises to improve balance, coordination, and strength, as well as strategies to combat fatigue and maximize energy conservation.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/dumbbells.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Personalized NDIS Physiotherapy Plans</h1>
                    <p>Recognizing the variability of MS, our NDIS physiotherapy plans are highly individualized. We focus on creating strategies that align with each person's NDIS goals, whether that involves improving mobility, returning to work, or engaging in community activities.</p>

                    <p>Our physiotherapists are trained in various techniques, including neuro-rehabilitation and exercise therapy, to help manage MS symptoms. We emphasize a collaborative approach, involving clients in the decision-making process and continually adapting the program to ensure the best possible outcomes.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>

            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Techniques to Enhance Neuroplasticity</h1>
                    <p>Neuroplasticity is the brain's ability to reorganize itself by forming new neural connections. For individuals with MS, harnessing neuroplasticity can be pivotal in regaining function. Our physiotherapists employ techniques such as task-specific training and repetition to promote neural adaptation and recovery.</p>

                    <p>We also incorporate technology-assisted interventions, such as functional electrical stimulation, to facilitate muscle activation and improve gait patterns. By leveraging the principles of neuroplasticity, we aim to maximize functional gains and enhance the independence of our clients with MS.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Pinloaded.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Supporting MS Clients Beyond Therapy</h1>
                    <p>At Ramp Physio and Fitness, we understand that living with MS extends beyond the therapy session. We provide ongoing support and education to empower our clients to manage their condition confidently. This includes advice on lifestyle modifications, ergonomic assessments, and adaptive equipment to enhance daily living.</p>
                    <p>Our team is committed to fostering a supportive community where clients can share experiences and strategies for coping with MS. We believe that a strong support network is essential for maintaining motivation and a positive outlook throughout the rehabilitation journey.</p>

                    <br></br>
                    <p>
                        In summary, our NDIS physiotherapy services for Multiple Sclerosis at Ramp Physio and Fitness are designed to address the unique needs of each individual. With a focus on evidence-based practice, personalization, and compassionate care, we strive to improve our clients' mobility, strength, and independence. If you or a loved one is living with MS and looking for supportive physiotherapy services, we invite you to book a session with us and take the next step towards managing your health.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            <SubpagesLink />
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NdisPhysiotherapyForMultipleSclerosis;