import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const RehabilitationAfterHipReplacementSurgery = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Rehabilitation After Hip Replacement Surgery</h1>
                    <h2>Embark on a successful recovery journey with Ramp Physio and Fitness, your partners in regaining mobility and strength after hip replacement surgery.</h2>
                    <p>Undergoing hip replacement surgery is a significant life event that requires dedicated post-operative care and rehabilitation. At Ramp Physio and Fitness, we specialize in guiding patients through this critical phase. Our expert physiotherapy team in Homebush, NSW, is committed to helping you restore function, improve mobility, and return to your daily activities with confidence. In this comprehensive guide, we'll explore the essential steps and strategies for effective rehabilitation following hip replacement surgery.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>The Path to Recovery</h1>
                        <p>Rehabilitation after hip replacement begins almost immediately following surgery. The early stages focus on managing pain, reducing inflammation, and preventing complications. As you progress, the rehabilitation journey involves regaining strength, balance, and range of motion. At Ramp Physio and Fitness, we tailor your recovery plan to suit your individual needs, ensuring a safe and effective return to everyday life.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Customized Rehab Programs</h1>
                    <p>Your rehabilitation program post-hip replacement is as unique as you are. Our experienced physiotherapists at Ramp Physio and Fitness develop personalized rehab plans that reflect your specific surgery, lifestyle, and goals. We utilize evidence-based exercises and modalities to ensure you receive the highest standard of care during your recovery.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>

            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Key Stages of Hip Rehab</h1>
                    <p>Understanding the key stages of rehabilitation after hip replacement surgery is crucial for a successful recovery. These stages include acute post-surgical care, early rehabilitation, strengthening and conditioning, and advanced functional training. Each phase plays a vital role in ensuring optimal outcomes and a smooth transition back to your pre-surgery activities.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Incorporating Exercise and Movement</h1>
                        <p>Exercise and movement are integral to rehabilitation after hip replacement surgery. We emphasize the importance of early mobilization and gradually introduce a range of exercises designed to improve joint mobility, muscle strength, and overall function. Our gym and personal training services complement your physiotherapy sessions, offering a holistic approach to your recovery.</p>
                        <br></br>
                        <p>
                            Engaging in a structured exercise program under the supervision of our qualified professionals ensures that you regain your mobility safely and effectively. We are dedicated to supporting you every step of the way, from initial recovery to achieving your long-term health and fitness goals.
                        </p>
                    </div>
                </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default RehabilitationAfterHipReplacementSurgery;