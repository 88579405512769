import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';
const NDIS = () => {
    return (
        <div>
{/* While we test GTM for now, we have removed the Google Analytics code from here */}
            <div className="service-detail-content">
                <div className="service-detail-text">
                <br></br><br></br>
                    <h1>Post-Operation Rehabilitation</h1>
                    <h2>Accelerate Your Recovery with Expert Post-Op Rehabilitation</h2>
                    <p>At Ramp Physio and Fitness, we provide specialized post-op rehabilitation programs designed to help you recover safely and efficiently after surgery. Whether you've undergone orthopedic surgery, joint replacement, or another procedure, our expert physiotherapists are here to support you every step of the way. Our goal is to reduce pain, restore mobility, and get you back to your daily activities with confidence.</p>
                    <p>Recently had surgery? Start your recovery journey with us. Contact us today to book a consultation and begin your personalized post-op rehabilitation plan.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Why Post-Op Rehabilitation is Essential for a Full Recovery?</h1>
                        <p>Post-operative rehabilitation is a critical component of the recovery process, helping to ensure that you regain strength, mobility, and function after surgery. Without proper rehab, you may experience prolonged pain, stiffness, or even complications. At Ramp Physio and Fitness, we focus on personalized rehab plans that address your specific needs, promoting faster healing and reducing the risk of setbacks.</p>
                        <p>Don’t let your surgery recovery slow you down. Contact us to learn how our post-op rehabilitation programs can help you heal faster and more effectively.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Customized Rehabilitation Programs for Effective Post-Op Recovery</h1>
                    <p>Every surgical procedure and patient is unique, which is why we offer customized post-op rehabilitation programs tailored to your specific condition and recovery goals. Our physiotherapists at Ramp Physio and Fitness use a combination of manual therapy, therapeutic exercises, and advanced modalities to help you regain strength, flexibility, and confidence. We work closely with your surgeon to ensure a cohesive recovery plan.</p>
                    <p>Ready to get back on your feet after surgery? Contact us to create a personalized rehabilitation plan that meets your recovery goals and supports your return to an active lifestyle.</p>

                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Recovering Stronger with Our Post-Op Rehabilitation</h1>
                    <p>Our clients' success stories demonstrate the effectiveness of our post-op rehabilitation programs. From regaining full mobility after joint replacement to overcoming post-surgery challenges, our clients have achieved remarkable recoveries with our dedicated support. Read their testimonials to see how Ramp Physio and Fitness can help you achieve a successful recovery and return to your normal activities.</p>
                    <p>Inspired by our clients' recovery stories? Join them in achieving a strong and successful recovery with our expert post-op rehabilitation services. Contact us today to book your appointment and start your healing journey.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="white" noMargin>
            <HealthSection />
            </SkewedContainer>
            <TestimonialSection />
        </div>
    );
};

export default NDIS;
