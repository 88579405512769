import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Link } from 'react-router-dom'; // Import Link for routing
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const NdisPhysiotherapyForPeripheralNeuropathy = () => {
    return (
        <div>
            <Helmet>
                <title>NDIS Physiotherapy for Peripheral Neuropathy | Ramp Physio and Fitness</title>
                <meta name="description" content="Expert NDIS Physiotherapy for managing Peripheral Neuropathy at Ramp Physio and Fitness. Personalized care plans to improve your quality of life." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>NDIS Physiotherapy for Peripheral Neuropathy</h1>
                    <h2>Personalized Care for Nerve Health and Functionality</h2>
                    <p>Peripheral neuropathy can be a challenging condition, causing discomfort, weakness, and loss of sensation in the extremities. At Ramp Physio and Fitness, we specialize in NDIS physiotherapy tailored to the needs of individuals with peripheral neuropathy. Our goal is to enhance nerve health and restore functionality, helping you live a more comfortable and active life.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Peripheral Neuropathy</h1>
                        <p>Peripheral neuropathy arises when nerves outside the brain and spinal cord are damaged. Symptoms can include tingling, numbness, pain, and muscle weakness. Our physiotherapists at Ramp Physio and Fitness utilize a range of treatments to manage these symptoms effectively.</p>

                        <p>We begin with a detailed assessment to understand the extent of your neuropathy and its impact on your daily life. Based on this, we develop a personalized care plan that aligns with your goals and preferences, ensuring you receive the most beneficial treatment under the NDIS scheme.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/dumbbells.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Expert NDIS Physiotherapy Services
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Treatment Techniques for Neuropathy</h1>
                    <p>Our treatment for peripheral neuropathy may include manual therapy to improve nerve mobility, exercises to strengthen muscles, and strategies to manage pain. We focus on restoring balance and preventing falls, which are common concerns for those with neuropathy.</p>

                    <p>Here are 10 examples of therapeutic interventions we may use:</p>
                    <p>
                        - Nerve gliding exercises to enhance mobility<br></br>
                        - Strengthening exercises for affected muscles<br></br>
                        - Balance training to reduce fall risk<br></br>
                        - Pain relief techniques such as TENS<br></br>
                        - Education on foot care and monitoring<br></br>
                        - Gait training to improve walking patterns<br></br>
                        - Functional activities to enhance daily living skills<br></br>
                        - Sensory re-education to compensate for lost sensation<br></br>
                        - Joint protection strategies to avoid further damage<br></br>
                        - Home exercise programs for continued progress<br></br>
                    </p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/Pinloaded.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Empowering Independence</h1>
                        <p>Our commitment extends beyond in-clinic sessions. We empower you with knowledge and self-management strategies to maintain and improve your condition. Education on neuropathy and its management is an integral part of our service.</p>
                        <p>At Ramp Physio and Fitness, we believe in the power of partnership. Our physiotherapists work closely with you, your caregivers, and other health professionals to ensure a coordinated and effective approach to your care under the NDIS.</p>
                    </div>
                </div>
            </SkewedContainer>
            <SubpagesLink />
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NdisPhysiotherapyForPeripheralNeuropathy;