import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import physioLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const PhysiotherapyTreatmentAndExercisesForSciatica = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Effective Sciatica Management at Ramp Physio and Fitness</h1>
                    <h2>Discover the benefits of targeted physiotherapy treatment and exercises for sciatica relief.</h2>
                    <p>Dealing with sciatica can be a painful and frustrating experience. At Ramp Physio and Fitness, we specialize in providing effective physiotherapy treatment and exercises tailored to alleviate sciatic nerve pain. Our team of experts is committed to helping you understand your condition and guiding you through a personalized recovery plan. Let's delve into the world of sciatica management and explore how our physiotherapy approaches can offer you the relief you need.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Sciatica and Its Causes</h1>
                        <p>Sciatica is a condition characterized by pain that radiates along the path of the sciatic nerve, which extends from the lower back through the hips and buttocks and down each leg. Typically, sciatica affects only one side of the body and is often the result of a herniated disc, bone spur, or spinal stenosis compressing the nerve.</p>

                        <p>At Ramp Physio and Fitness, we start with a thorough assessment to determine the underlying cause of your sciatica. This enables us to create a physiotherapy program that not only addresses your symptoms but also targets the root of the problem. By understanding the cause, we can prevent future episodes and help you maintain a pain-free lifestyle.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/dumbbells.webp" alt="Understanding Sciatica" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Physiotherapy Treatment" />
                </div>
                <div className="service-intro-content2">
                    <h1>Personalized Physiotherapy Approaches</h1>
                    <p>Our physiotherapy treatments for sciatica are customized to meet the specific needs of each patient. We use a combination of manual therapy and modalities to decrease pain and inflammation. This may include techniques such as soft tissue massage, joint mobilization, and the use of heat or cold therapy.</p>

                    <p>Central to our philosophy is the empowerment of our patients through education. By understanding your condition and the rationale behind each treatment, you're more likely to adhere to your recovery plan and achieve better outcomes. Our physiotherapists will provide you with the knowledge and tools needed to manage your sciatica effectively.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={physioLogo} alt="Ramp Physio and Fitness Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>

            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Exercises for Sciatica Pain Relief</h1>
                    <p>Exercise is a crucial component of sciatica treatment. Our physiotherapists will guide you through a series of targeted exercises designed to relieve sciatic pain. These may include:</p>

                    <p>• Stretching exercises to improve flexibility of the lower back and hamstrings</p>
                    <p>• Core strengthening exercises to provide better support for the lower back</p>
                    <p>• Low-impact aerobic exercises such as walking or swimming to increase blood flow</p>
                    <p>• Postural exercises to reduce nerve compression</p>
                    <p>• Neuromuscular re-education to improve the coordination of back and core muscles</p>
                    <p>• Gluteal and piriformis muscle exercises to reduce tightness and irritation of the sciatic nerve</p>

                    <p>By incorporating these exercises into your routine, you can not only find relief from pain but also strengthen your body to prevent future episodes of sciatica.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Sciatica Exercises" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Pinloaded.webp" alt="Exercise Equipment" />
                </div>
                <div className="service-intro-content2">
                    <h1>Advanced Techniques for Sciatic Pain</h1>
                    <p>For persistent sciatica, we may recommend advanced treatment techniques such as:</p>

                    <p>• Dry needling to release muscle tension and improve nerve function</p>
                    <p>• TENS (Transcutaneous Electrical Nerve Stimulation) to provide pain relief</p>
                    <p>• Spinal decompression therapy to alleviate pressure on the sciatic nerve</p>
                    <p>• Ultrasound therapy to promote tissue healing and reduce inflammation</p>

                    <p>These techniques, combined with our comprehensive exercise program, can significantly enhance your recovery and help you return to your daily activities without pain.</p>
                </div>
            </div>
            </SkewedContainer>
            <SubpagesLink />

            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default PhysiotherapyTreatmentAndExercisesForSciatica;