import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const GymWorkoutsForImprovingAgilityAndSpeed = () => {
    return (
        <div>
            <Helmet>
                <title>Gym Workouts for Agility and Speed | Ramp Physio and Fitness</title>
                <meta name="description" content="Boost your agility and speed with our tailored gym workouts at Ramp Physio and Fitness in Homebush, NSW." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Enhance Agility and Speed</h1>
                    <h2>Maximize your movement efficiency with specialized gym workouts designed to improve agility and speed at Ramp Physio and Fitness.</h2>
                    <p>Whether you're an athlete aiming to gain a competitive edge or someone looking to enhance your overall fitness, agility and speed are crucial components of your physical toolkit. At Ramp Physio and Fitness, we've curated a selection of gym workouts that focus on these vital attributes, helping you move with precision and swiftness. In this comprehensive guide, we'll explore the best exercises to boost your agility and speed, along with the science behind their effectiveness.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Agility and Speed Training" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Agility Training Fundamentals</h1>
                        <ul>
                            <li>Ladder drills for quick footwork</li>
                            <li>Cones for change-of-direction speed</li>
                            <li>Hurdles for explosive power</li>
                            <li>Resistance bands for strength and resistance</li>
                            <li>Plyometric boxes for jump training</li>
                            <li>Bungee cords for dynamic resistance</li>
                            <li>Medicine balls for core strength and coordination</li>
                            <li>Agility rings for precision foot placement</li>
                            <li>Treadmill sprints for endurance and speed</li>
                            <li>Balance boards for stability and control</li>
                        </ul>
                        <p>Our agility training fundamentals incorporate a variety of equipment and exercises that target different aspects of agility and speed. From ladder drills that enhance footwork to plyometric boxes that improve explosive power, we ensure a well-rounded approach to boost your athletic performance.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Speed Training Techniques</h1>
                    <p>Speed isn't just about how fast you can run; it's about how quickly you can accelerate, maintain pace, and decelerate efficiently. Our speed training techniques focus on all these elements, utilizing advanced methods to help you reach your top speed faster and sustain it longer.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Science of Agility and Speed</h1>
                    <p>Understanding the science behind agility and speed training can empower you to train smarter. Agility is about the ability to change direction rapidly without losing speed, balance, or body control. Speed is a combination of stride frequency and stride length, both of which can be developed through targeted exercises. Our scientifically-backed training sessions are designed to improve neuromuscular coordination, reaction time, and explosive strength, all of which contribute to better agility and speed.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Your Path to Peak Performance</h1>
                        <p>At Ramp Physio and Fitness, we believe that everyone has the potential to reach their peak performance. Our agility and speed training programs are tailored to meet your individual goals, whether you're looking to improve your sports performance or simply want to move better in your daily life. Join us and take the first step towards unlocking your true athletic potential.</p>
                        <br></br>
                        <p>
                            In conclusion, mastering agility and speed requires dedication, the right guidance, and effective training methods. Ramp Physio and Fitness is committed to providing you with the expertise and resources needed to excel. With our specialized gym workouts, you'll not only achieve your fitness goals but also enjoy the journey along the way. Get ready to transform your agility and speed with us – book your session now.
                        </p>
                    </div>
                </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default GymWorkoutsForImprovingAgilityAndSpeed;