import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const ManagingChronicPainWithPhysiotherapy = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Alleviating Chronic Pain Through Physiotherapy</h1>
                    <h2>Uncover the path to managing chronic pain with tailored physiotherapy interventions at Ramp Physio and Fitness, and take control of your well-being today.</h2>
                    <p>Chronic pain can be a debilitating condition, affecting every aspect of life. At Ramp Physio and Fitness, we understand the complexities of pain and the impact it has on our clients. Our expert physiotherapists employ a multifaceted approach to managing chronic pain, focusing on evidence-based techniques and personalized care plans to help you regain function and improve your quality of life. This article explores the role of physiotherapy in chronic pain management and how our services can support your journey towards relief and recovery.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Chronic Pain</h1>
                        <p>Chronic pain is a complex condition that persists for extended periods, often without a clear cause. It can arise from various sources, including injuries, surgeries, nerve damage, and systemic diseases. Unlike acute pain, which serves as a warning sign of injury or illness, chronic pain can continue even after the initial injury has healed. Physiotherapy plays a crucial role in addressing the multifactorial nature of chronic pain, offering strategies that target both the physical and psychological components of pain.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Evidence-Based Pain Management</h1>
                    <p>Our approach to managing chronic pain is grounded in the latest research and evidence-based practices. We incorporate a range of therapeutic techniques, including manual therapy, exercise prescription, and education, to address pain from all angles. By staying informed on the most current studies and guidelines, we ensure our clients receive the most effective treatment possible.</p>
                    <ul>
                        <li>Manual therapy to reduce muscle tension</li>
                        <li>Therapeutic exercises to improve mobility</li>
                        <li>Pain education to empower self-management</li>
                        <li>Mind-body techniques to address psychological factors</li>
                        <li>Individualized home exercise programs for sustained progress</li>
                        <li>Electrotherapy modalities for pain modulation</li>
                    </ul>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Customized Therapeutic Exercise</h1>
                    <p>Exercise is a cornerstone of chronic pain management. Our physiotherapists design customized exercise programs that not only aim to alleviate pain but also enhance overall physical function. By focusing on strength, flexibility, endurance, and coordination, we facilitate a gradual return to daily activities and hobbies. Here are ten examples of how we tailor exercises to individual needs:</p>
                    <ul>
                        <li>Low-impact aerobic conditioning for endurance</li>
                        <li>Stretching routines for flexibility</li>
                        <li>Strength training to support weak muscles</li>
                        <li>Balance exercises to prevent falls</li>
                        <li>Functional training for everyday tasks</li>
                        <li>Postural correction to relieve strain</li>
                        <li>Joint protection techniques</li>
                        <li>Pain coping skills through pacing and grading</li>
                        <li>Relaxation exercises to reduce stress</li>
                        <li>Hydrotherapy for gentle resistance training</li>
                    </ul>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Integrative Pain Treatment Approach</h1>
                    <p>At Ramp Physio and Fitness, we recognize that chronic pain is best managed through an integrative approach that combines various treatment modalities. Our physiotherapists collaborate with other healthcare professionals, such as psychologists and dietitians, to provide a comprehensive pain management plan. This multidisciplinary strategy ensures that all aspects of your pain are addressed, leading to more effective and lasting outcomes.</p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default ManagingChronicPainWithPhysiotherapy;