import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Link } from 'react-router-dom'; // Import Link for routing
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const NdisPhysiotherapyForPtsd = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Empowering Recovery: NDIS Physiotherapy for PTSD</h1>
                    <h2>Explore how specialized NDIS physiotherapy can support individuals with PTSD in achieving their rehabilitation and wellness goals at Ramp Physio and Fitness.</h2>
                    <p>Post-Traumatic Stress Disorder (PTSD) can profoundly affect an individual's physical and emotional well-being. At Ramp Physio and Fitness, we provide NDIS-funded physiotherapy services tailored to the unique needs of those living with PTSD. Our compassionate and skilled team is dedicated to creating a safe and supportive environment where clients can work towards recovery and empowerment. This article delves into the intersection of physiotherapy and PTSD care, showcasing how our services can make a positive impact on your health journey.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding PTSD and Physiotherapy</h1>
                        <p>PTSD is a condition triggered by experiencing or witnessing traumatic events. It can manifest in various ways, including anxiety, depression, and physical symptoms like chronic pain. Physiotherapy plays a crucial role in addressing these physical manifestations by promoting movement, reducing pain, and improving the quality of life.</p>

                        <p>At Ramp Physio and Fitness, our NDIS-approved physiotherapy services begin with a thorough assessment to understand each client's specific symptoms and challenges. We then develop a personalized treatment plan that may include gentle exercise, manual therapy, and education on pain management techniques.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/dumbbells.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Personalized Care and Treatment</h1>
                    <p>Recognizing that each individual's experience with PTSD is unique, we offer customized care plans designed to meet your specific needs. Our treatments focus on alleviating symptoms associated with PTSD, such as muscle tension, headaches, and sleep disturbances.</p>

                    <p>Our physiotherapists use a combination of targeted exercises, relaxation techniques, and hands-on therapy to help manage these symptoms. We also work closely with other healthcare professionals to ensure a holistic approach to your recovery.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>

            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Integrating Mind and Body Wellness</h1>
                    <p>Understanding the mind-body connection is essential in treating PTSD. Our physiotherapy services extend beyond physical treatment to incorporate strategies that address psychological well-being.</p>

                    <p>Through mindful movement and breathing exercises, we help clients develop greater body awareness and emotional regulation. These practices can be particularly beneficial in managing stress responses and facilitating a sense of calm.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Pinloaded.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Building Strength and Resilience</h1>
                    <p>Empowering clients to build physical strength is a key objective of our NDIS physiotherapy program. Strengthening exercises not only improve physical capacity but also contribute to emotional resilience.</p>
                    <p>We encourage gradual progress through safe and effective training, allowing clients to experience the benefits of increased fitness and confidence in their physical abilities.</p>

                    <br></br>
                    <p>
                        In summary, NDIS physiotherapy for PTSD at Ramp Physio and Fitness is a holistic, client-focused service. We are committed to supporting your recovery with compassionate care and a comprehensive approach that addresses both the physical and psychological aspects of PTSD. Our goal is to help you regain control, improve your functional abilities, and enhance your overall quality of life.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            <SubpagesLink />
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NdisPhysiotherapyForPtsd;