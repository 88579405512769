import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const ManagingSciaticaWithPhysiotherapyExercises = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Overcoming Sciatica with Expert Physiotherapy</h1>
                    <h2>Discover the power of physiotherapy exercises to manage sciatica pain and enhance mobility at Ramp Physio and Fitness in Homebush, NSW.</h2>
                    <p>Sciatica can be a debilitating condition, but with the right physiotherapy approach, it's possible to alleviate pain and improve function. At Ramp Physio and Fitness, we specialize in helping individuals manage sciatica through targeted exercises and personalized care plans. In this comprehensive guide, we'll explore various physiotherapy exercises designed to address sciatic pain and promote recovery.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Sciatica Pain Relief Strategies</h1>
                        <p>Effective management of sciatica often involves a combination of physiotherapy exercises that stretch and strengthen the spinal and leg muscles. These exercises are designed to relieve pressure on the sciatic nerve, enhance flexibility, and build support for the lower back. Below are 10 exercises commonly recommended for sciatica pain relief:</p>
                        <ul>
                            <li>Reclining Pigeon Pose</li>
                            <li>Sitting Spinal Stretch</li>
                            <li>Knee to Opposite Shoulder</li>
                            <li>Sciatic Nerve Mobilization</li>
                            <li>Standing Hamstring Stretch</li>
                            <li>Bridges</li>
                            <li>Knee to Chest Stretch</li>
                            <li>Lower Back Stretch</li>
                            <li>Pelvic Tilt</li>
                            <li>Deep Gluteal Stretch</li>
                        </ul>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Customizing Your Exercise Plan</h1>
                    <p>Every individual's experience with sciatica is unique, which is why we tailor our physiotherapy programs to meet your specific needs. We'll work with you to create an exercise regimen that takes into account your pain levels, mobility, and overall health. Our physiotherapists will guide you through each exercise, ensuring proper form and technique to maximize the benefits and minimize the risk of further injury.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Understanding Sciatica and Its Causes</h1>
                    <p>Sciatica is a symptom of an underlying medical condition, not a diagnosis itself. It's characterized by pain that radiates along the path of the sciatic nerve, which extends from the lower back to the buttock and down the leg. Common causes of sciatica include herniated discs, bone spurs, and spinal stenosis. Understanding the root cause of your sciatica is essential to developing an effective treatment plan.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Integrative Approach to Sciatica</h1>
                    <p>At Ramp Physio and Fitness, we believe in an integrative approach to managing sciatica. Alongside physiotherapy exercises, we may incorporate other treatment modalities such as manual therapy, massage, and personalized gym programs. This holistic strategy is designed to not only relieve sciatic pain but also to prevent its recurrence, helping you achieve long-term health and mobility.</p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default ManagingSciaticaWithPhysiotherapyExercises;