import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const NdisPhysiotherapyForIntellectualDisabilities = () => {
    return (
        <div>
            <Helmet>
                <title>NDIS Physiotherapy for Intellectual Disabilities | Ramp Physio and Fitness</title>
                <meta name="description" content="Discover how NDIS physiotherapy can support individuals with intellectual disabilities in achieving greater independence and improved quality of life." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Empowering Lives with NDIS Physiotherapy for Intellectual Disabilities</h1>
                    <h2>Expert physiotherapy services tailored for individuals with intellectual disabilities, fostering independence and enhancing daily living.</h2>
                    <p>At Ramp Physio and Fitness, we are dedicated to providing specialized NDIS physiotherapy for individuals with intellectual disabilities. Our approach is centered on empowering our clients to achieve their highest level of independence and quality of life. In this extensive guide, we delve into the benefits of physiotherapy for intellectual disabilities and how our compassionate team can support NDIS participants in their journey towards wellness and autonomy.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Intellectual Disabilities</h1>
                        <p>Intellectual disabilities encompass a range of cognitive impairments that can affect an individual's ability to perform daily tasks. Our NDIS physiotherapy program begins with a comprehensive assessment to understand each participant's unique needs and challenges. We then design personalized interventions that promote skill development, mobility, and functional independence.</p>
                        <p>Through targeted exercises and therapeutic techniques, we aim to enhance motor skills, coordination, and balance, which are often areas of difficulty for those with intellectual disabilities. Our physiotherapists work closely with NDIS participants and their support networks to create meaningful and achievable goals.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/dumbbells.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Personalized Physiotherapy Plans</h1>
                    <p>Our NDIS physiotherapy services are tailored to cater to the diverse needs of individuals with intellectual disabilities. We incorporate a variety of therapeutic activities, including strength and conditioning programs, functional task practice, and sensory integration therapies. Each plan is carefully crafted to be both engaging and effective, ensuring that participants remain motivated throughout their physiotherapy journey.</p>
                    <p>With a focus on enhancing daily living skills, our physiotherapists provide training in areas such as mobility, self-care, and community participation. We utilize evidence-based practices to maximize outcomes and support participants in leading more independent lives.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>

            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Enhancing Motor Skills and Coordination</h1>
                    <p>Developing motor skills and coordination is crucial for individuals with intellectual disabilities. Our physiotherapists employ a variety of techniques to improve gross and fine motor skills, allowing for greater participation in everyday activities. From simple exercises to more complex movement patterns, we focus on building the physical capabilities that serve as the foundation for independence.</p>
                    <p>Additionally, we support the development of coordination and balance, which are essential for tasks such as walking, running, and navigating different environments. By improving these skills, NDIS participants can experience reduced risk of falls and increased confidence in their physical abilities.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Pinloaded.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Fostering Independence and Participation</h1>
                    <p>One of the primary goals of NDIS physiotherapy is to foster independence and encourage active participation in the community. Our programs are designed to empower participants by teaching them how to navigate their environments safely and effectively. We work on skills such as transferring, walking, and using public transportation, which are critical for independence.</p>
                    <p>We also recognize the importance of social participation and recreation for overall well-being. Our physiotherapists support individuals in engaging with community activities, sports, and leisure pursuits that are meaningful to them, contributing to a more fulfilling life.</p>
                    <br></br>
                    <p>Overall, our commitment is to deliver physiotherapy services that not only address physical needs but also contribute to a sense of autonomy and joy in the lives of those with intellectual disabilities.</p>
                </div>
            </div>
            </SkewedContainer>
            <SubpagesLink />
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NdisPhysiotherapyForIntellectualDisabilities;