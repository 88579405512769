import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const NDIS = () => {
    return (
        <div>
{/* While we test GTM for now, we have removed the Google Analytics code from here */}
            <div className="service-detail-content">
                <div className="service-detail-text">
                <br></br><br></br>
                    <h1>Personal Training</h1>
                    <h2>Achieve Your Fitness Goals with Expert Personal Training</h2>
                    <p>
                        Welcome to Ramp Physio and Fitness, where our expert personal trainers are dedicated to helping you achieve your fitness goals. Whether you're looking to lose weight, build muscle, or improve your overall health, our personalized training programs are designed to fit your unique needs. With a focus on safe and effective exercises, we ensure you get the best results without the risk of injury.
                    </p>
                    <p>Ready to transform your body and health? Contact us today to schedule your first personal training session and start your journey to a healthier you!</p>

                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Discover the Benefits of Personal Training</h1>
                        <p>Personal training offers numerous benefits that go beyond just physical appearance. Our trainers provide you with the motivation, accountability, and expert guidance needed to stay on track and reach your fitness milestones. Experience improved strength, endurance, and flexibility while learning the proper techniques to maximize your workouts.</p>
                        <p>Experience the benefits of personal training firsthand. Book a session with one of our certified trainers and take the first step towards a healthier lifestyle!</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Tailored Personal Training Programs for Every Individual.</h1>
                    <p>At Ramp Physio and Fitness, we understand that everyone has different fitness levels and goals. That's why we offer a variety of personal training programs tailored to meet your specific needs. From beginner to advanced, our trainers will develop a customized plan that fits your lifestyle and helps you achieve your objectives efficiently.</p>
                    <p>Find the perfect personal training program for you! Contact us to learn more about our customized plans and how we can help you reach your fitness goals.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>

                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Success Stories: Real Results from Our Personal Training Clients</h1>
                    <p>Our clients' success stories speak volumes about the effectiveness of our personal training programs. From dramatic weight loss transformations to significant strength gains, we are proud of the positive impact we've made on our clients' lives. Read their testimonials and get inspired to start your own fitness journey with us.</p>
                    <p>Be inspired by our clients' success stories! Join them in achieving incredible results with our personal training programs. Contact us today to book your session and start your transformation.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="white" noMargin>
            <HealthSection />
            </SkewedContainer>
            <TestimonialSection />
        </div>
    );
};

export default NDIS;
