import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const PhysiotherapyForTemporomandibularJointTmjDisorders = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Expert TMJ Disorder Management</h1>
                    <h2>Find relief from jaw pain and dysfunction with specialized physiotherapy for TMJ disorders at Ramp Physio and Fitness.</h2>
                    <p>Temporomandibular Joint (TMJ) disorders can cause significant discomfort and impact your quality of life. At Ramp Physio and Fitness, we understand the complexities of TMJ dysfunctions and offer expert physiotherapy to alleviate pain, restore function, and improve your overall well-being. Our evidence-based treatment approaches are tailored to meet your individual needs, ensuring you receive the best possible care for your TMJ condition.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding TMJ Disorders</h1>
                        <p>TMJ disorders encompass a range of conditions that affect the jaw joint and muscles responsible for jaw movement. These disorders can lead to symptoms such as pain, clicking sounds, and limited jaw movement. The causes of TMJ disorders are multifactorial and can include habits like teeth grinding, misalignment, stress, and arthritis. Physiotherapy plays a crucial role in the non-surgical management of TMJ disorders, offering a variety of treatment techniques aimed at reducing symptoms and improving function.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Personalized TMJ Treatment Plans</h1>
                    <p>Our physiotherapists at Ramp Physio and Fitness are experienced in diagnosing and treating TMJ disorders. We create personalized treatment plans that may include manual therapy, exercises to strengthen and stretch the jaw muscles, relaxation techniques, and education on posture and jaw use. Our goal is to provide relief from pain and to empower you with the knowledge and tools to manage your TMJ symptoms effectively.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Effective TMJ Exercises and Techniques</h1>
                    <p>Exercises for TMJ disorders are designed to improve jaw mobility and strength. These exercises may include controlled jaw movements, stretching exercises to relieve tightness, and strengthening exercises to improve muscle function. Additionally, our physiotherapists may employ techniques such as dry needling, ultrasound therapy, and laser treatment to reduce pain and inflammation.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Comprehensive TMJ Care at Ramp</h1>
                    <p>At Ramp Physio and Fitness, our comprehensive care for TMJ disorders extends beyond in-clinic treatment. We provide guidance on lifestyle modifications, ergonomic advice, and stress management techniques to address contributing factors to TMJ dysfunction. Our approach is holistic, considering all aspects of your health to ensure the best outcomes.</p>
                    <br></br>
                    <p>
                        In conclusion, if you're struggling with TMJ disorders, Ramp Physio and Fitness is here to help. Our expert team is dedicated to providing you with the quality care you need to relieve your symptoms and regain your quality of life. Don't let jaw pain hold you back any longer. Book an appointment today and take the first step towards a pain-free jaw.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default PhysiotherapyForTemporomandibularJointTmjDisorders;