import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import physioLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const PhysiotherapyTreatmentAndExercisesForMiddleBackPain = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Alleviating Middle Back Pain: Effective Strategies</h1>
                    <h2>Explore expert physiotherapy treatment and exercises for managing and preventing middle back pain, tailored by Ramp Physio and Fitness.</h2>
                    <p>Middle back pain can be a disruptive ailment that affects your daily activities and quality of life. At Ramp Physio and Fitness, we specialize in providing personalized physiotherapy treatments and exercises designed to target and alleviate middle back pain. Our expert team combines hands-on techniques with tailored exercises to help you achieve relief and maintain a healthy back. In this article, we delve into the effective strategies to manage middle back pain and how our services can help you live a pain-free life.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Middle Back Pain</h1>
                        <p>The middle back, or the thoracic spine, is a complex structure of vertebrae, muscles, and ligaments. Pain in this area can stem from poor posture, muscle strain, or underlying conditions such as a herniated disc. Recognizing the causes and symptoms of middle back pain is the first step towards effective treatment.</p>

                        <p>At Ramp Physio and Fitness, our physiotherapists perform a thorough assessment to identify the root cause of your discomfort. We then develop a personalized treatment plan that may include manual therapy, modalities, and targeted exercises to relieve pain and restore function.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/dumbbells.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Targeted Physiotherapy Techniques</h1>
                    <p>Our approach to treating middle back pain includes a combination of hands-on techniques and therapeutic modalities. These may involve soft tissue massage, spinal mobilizations, and the application of heat or cold therapy to reduce inflammation and promote healing.</p>

                    <p>Our physiotherapists are trained in a variety of techniques that are selected based on your specific condition and response to treatment. We aim to not only alleviate pain but also to educate you on how to maintain a healthy spine through proper body mechanics and posture.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={physioLogo} alt="Physio Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>

            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Exercise Regimen for Middle Back</h1>
                    <p>Incorporating specific exercises into your daily routine can significantly impact your recovery from middle back pain. Our physiotherapists will guide you through a series of exercises aimed at strengthening the muscles that support your thoracic spine, improving flexibility, and enhancing range of motion.</p>

                    <p>These exercises are carefully selected to match your fitness level and gradually progress in intensity as your back becomes stronger. We focus on ensuring that you perform each exercise with proper form to prevent further injury.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Pinloaded.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Preventative Strategies and Tips</h1>
                    <p>Prevention is key in managing middle back pain. Our team provides you with strategies to prevent future episodes, including ergonomic advice for your workplace, tips for maintaining good posture, and recommendations for regular physical activity.</p>
                    <p>We believe in empowering our clients with the knowledge and tools needed to take control of their back health. By implementing these preventative measures, you can reduce the risk of recurring pain and lead a more active lifestyle.</p>
                    <br></br>
                    <p>
                        To conclude, middle back pain can be effectively managed with the right combination of physiotherapy and exercise. Ramp Physio and Fitness is dedicated to providing you with expert care and personalized treatment plans to help you overcome middle back pain and enjoy a healthier, more active life. Contact us today to book your consultation and take the first step towards recovery.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            <SubpagesLink />

            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default PhysiotherapyTreatmentAndExercisesForMiddleBackPain;