import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const NdisPhysiotherapyForCerebralPalsy = () => {
    return (
        <div>
            <Helmet>
                <title>NDIS Physiotherapy for Cerebral Palsy | Ramp Physio and Fitness</title>
                <meta name="description" content="Ramp Physio and Fitness offers specialized NDIS-funded physiotherapy services for individuals with Cerebral Palsy in Homebush, NSW." />
            </Helmet>
            
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Empowering Movement with NDIS Physiotherapy for Cerebral Palsy</h1>
                    <h2>Discover the transformative power of tailored physiotherapy for Cerebral Palsy supported by NDIS funding at Ramp Physio and Fitness.</h2>
                    <p>Cerebral Palsy is a neurological condition that affects movement and muscle tone, presenting unique challenges for those affected. At Ramp Physio and Fitness, we recognize the importance of specialized physiotherapy to enhance mobility, strength, and independence for our clients with Cerebral Palsy. Supported by NDIS funding, our services are designed to align with individual goals, providing comprehensive and compassionate care. In this article, we will delve into the aspects of NDIS physiotherapy for Cerebral Palsy and how our expert team can make a significant difference in the lives of our clients.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Cerebral Palsy and Physiotherapy</h1>
                        <p>Cerebral Palsy is a condition often resulting from brain damage before or during birth, or in early childhood. Physiotherapy plays a crucial role in managing this condition by promoting functional movement, reducing muscle stiffness, and preventing contractures. Our NDIS-funded physiotherapy services are centered around creating individualized treatment plans that cater to the specific needs of each client.</p>

                        <p>At Ramp Physio and Fitness, we begin with a detailed assessment to understand the severity of the condition and the client's daily challenges. With these insights, our physiotherapists develop a targeted approach that focuses on enhancing quality of life through improved mobility and independence.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/dumbbells.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/massage.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Tailored Strategies for Enhanced Mobility</h1>
                        <p>Each individual with Cerebral Palsy faces distinct challenges; therefore, our physiotherapy interventions are tailored to meet those specific needs. We utilize a combination of hands-on techniques, therapeutic exercises, and assistive technology to facilitate movement and improve daily function.</p>

                        <p>Our NDIS-funded programs include strength training, balance activities, and coordination drills, all aimed at maximizing potential for mobility and fostering greater self-reliance.</p>
                    </div>
                </div>
            </SkewedContainer>
            
            <SkewedContainer top="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness is proud to support the community with NDIS-funded services, ensuring high-quality care for those with Cerebral Palsy.
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Integrating Technology in Cerebral Palsy Care</h1>
                    <p>Advancements in physiotherapy have introduced innovative technologies that can significantly benefit individuals with Cerebral Palsy. At Ramp Physio and Fitness, we embrace these technologies, such as gait analysis and virtual reality, to create engaging and effective therapy sessions.</p>

                    <p>Our focus on incorporating cutting-edge solutions allows us to deliver precise and personalized care, enhancing the therapeutic experience and outcomes for our clients.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
            </div>
            
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/Pinloaded.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Maximizing NDIS Funding for Optimal Care</h1>
                        <p>Understanding the NDIS funding process can be overwhelming, but it is essential to ensure that individuals with Cerebral Palsy receive the care they need. At Ramp Physio and Fitness, we assist our clients in navigating the NDIS plan to utilize their funding effectively for physiotherapy services.</p>

                        <p>We are committed to providing transparent and supportive guidance to help our clients make the most of their NDIS benefits and achieve their health and mobility goals.</p>
                    </div>
                </div>
            </SkewedContainer>
            
            <SubpagesLink />

            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NdisPhysiotherapyForCerebralPalsy;