import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const PhysiotherapyForOsteitisPubisInAthletes = () => {
    return (
        <div>
            <Helmet>
                <title>Physiotherapy for Osteitis Pubis in Athletes - Ramp Physio and Fitness</title>
                <meta name="description" content="Expert physiotherapy for osteitis pubis in athletes to manage pain and improve performance. Personalized treatment plans at Ramp Physio and Fitness." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Targeting Osteitis Pubis in Athletes</h1>
                    <h2>Specialized Physiotherapy Approaches for Effective Management and Recovery</h2>
                    <p>At Ramp Physio and Fitness, we understand the unique challenges athletes face with conditions like osteitis pubis. Our expert team offers tailored physiotherapy services to address this painful disorder, ensuring athletes can return to their sport with confidence. This article will explore the condition, its impact on athletes, and how our specialized physiotherapy approaches aid in effective management and recovery.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Physiotherapy for Osteitis Pubis" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Osteitis Pubis</h1>
                        <p>Osteitis pubis is an overuse injury affecting the pubic symphysis and surrounding muscle insertions. Commonly seen in athletes who engage in sports that involve running, kicking, or rapid directional changes, this condition can lead to chronic groin pain and inflammation. Let's break down the key aspects of osteitis pubis:</p>
                        <ul>
                            <li>Pathophysiology and etiology</li>
                            <li>Common symptoms and diagnosis</li>
                            <li>Risk factors and preventive measures</li>
                            <li>Latest research and treatment modalities</li>
                            <li>The importance of early intervention</li>
                            <li>Role of physiotherapy in management</li>
                        </ul>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Physiotherapy Treatment Strategies</h1>
                    <p>Effective management of osteitis pubis requires a comprehensive physiotherapy approach. Our treatment strategies include:</p>
                    <ul>
                        <li>Manual therapy techniques</li>
                        <li>Targeted exercise programs</li>
                        <li>Pain management and modalities</li>
                        <li>Education on activity modification</li>
                        <li>Progressive loading and strengthening</li>
                        <li>Strategies for preventing recurrence</li>
                    </ul>
                    <p>Each athlete receives a personalized treatment plan designed to address their specific needs and goals, leading to optimal recovery and performance.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Rehabilitation and Return to Sport</h1>
                    <p>Our goal is to not only alleviate pain but also to ensure a safe and timely return to sport. Our rehabilitation process focuses on:</p>
                    <ul>
                        <li>Restoration of joint mobility and stability</li>
                        <li>Re-education of movement patterns</li>
                        <li>Gradual reintroduction to sport-specific activities</li>
                        <li>Monitoring progress and adjusting the plan accordingly</li>
                        <li>Collaboration with coaches and trainers for optimal outcomes</li>
                        <li>Long-term strategies to maintain pelvic health</li>
                    </ul>
                    <p>The collaborative efforts of our physiotherapists, athletes, and their support teams are crucial for a successful return to sport.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Complementary Therapies and Support</h1>
                    <p>In addition to physiotherapy, we offer complementary therapies to support recovery:</p>
                    <ul>
                        <li>Hydrotherapy and aquatic exercises</li>
                        <li>Soft tissue massage and myofascial release</li>
                        <li>Acupuncture and dry needling</li>
                        <li>Electrotherapy and ultrasound</li>
                        <li>Biomechanical analysis and correction</li>
                        <li>Nutritional guidance for inflammation reduction</li>
                    </ul>
                    <p>These therapies can enhance the healing process, reduce recovery time, and contribute to overall well-being.</p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default PhysiotherapyForOsteitisPubisInAthletes;