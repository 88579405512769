import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';
const NDIS = () => {
    return (
        <div>
{/* While we test GTM for now, we have removed the Google Analytics code from here */}

            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Dry Needling and Acupuncture</h1>
                    <h2>Expert Dry Needling Services for Effective Pain Relief, How Can Physiotherapy Help</h2>
                    <p>At Ramp Physio and Fitness, we offer specialized dry needling services to help alleviate muscle pain and improve functional movement. Dry needling is a therapeutic technique performed by our qualified physiotherapists, involving the insertion of fine needles into trigger points within muscles. This treatment targets muscle knots and tightness, promoting relaxation and reducing pain. While similar to acupuncture, dry needling is based on Western anatomical and neurophysiological principles.</p>
                    <p>Interested in experiencing the benefits of dry needling? Contact us today to schedule an appointment with our expert physiotherapists and take the first step toward pain-free living.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Dry Needling and Its Benefits</h1>
                        <p>Dry needling is an effective treatment for a variety of musculoskeletal conditions, including back pain, neck pain, shoulder tension, and sports injuries. By targeting trigger points, dry needling helps to release muscle tightness, enhance blood flow, and stimulate the body's natural healing processes. This can lead to improved range of motion, decreased pain, and enhanced performance.</p>
                        <p>Curious about how dry needling can help you? Let our experts provide a personalized assessment. Schedule an appointment today and discover the benefits for yourself.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Our Comprehensive Approach to Dry Needling</h1>
                    <p>At Ramp Physio and Fitness, we integrate dry needling into a holistic treatment plan tailored to your specific needs. Our physiotherapists combine dry needling with other therapeutic modalities such as manual therapy, exercise prescription, and education on posture and movement patterns. This comprehensive approach ensures that we address not just the symptoms but also the underlying causes of your pain.</p>
                    <p>Ready to embark on a personalized treatment journey? Contact us to create a comprehensive plan that includes dry needling and other effective therapies.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Acupuncture and How It Complements Our Services</h1>
                    <p>While our primary focus is on dry needling, we also acknowledge the benefits of acupuncture, a traditional Chinese medicine technique that involves inserting needles into specific points along the body's meridians to balance energy flow. Acupuncture can complement physiotherapy by reducing pain and promoting overall well-being. If you're interested in exploring acupuncture, our team can provide guidance and integrate appropriate techniques into your treatment plan.</p>  
                    <br></br>
                     <h1>Success Stories from Our Clients</h1>
                    <p>Many of our clients have experienced significant relief and improved function through our dry needling services. From athletes recovering from injuries to individuals managing chronic pain, we are proud to have helped numerous people enhance their quality of life. Read their testimonials to learn how Ramp Physio and Fitness has made a positive impact on their recovery journeys.</p>  
                    <br></br>
                    <h1>Who Can Benefit from Dry Needling?</h1>
                    <p>Dry needling is suitable for a wide range of individuals experiencing musculoskeletal issues. Whether you're an athlete recovering from a sports injury, an office worker dealing with chronic neck and shoulder tension, or someone suffering from persistent back pain, dry needling can provide significant relief. It is effective for conditions such as muscle strains, tendinitis, tension headaches, and joint dysfunctions. By targeting specific trigger points, we can help reduce pain and improve your overall function.</p>  

                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>What to Expect During Your Dry Needling Session</h1>
                    <p>Your comfort and safety are our top priorities during the dry needling process. During your session, our skilled physiotherapist will insert sterile, ultra-thin needles into designated trigger points in your muscles. You may feel a slight prick or a twitch response, which is a positive sign indicating the muscle is releasing tension. Sessions are typically brief, and many clients report immediate improvements in pain levels and mobility. We'll provide guidance on aftercare to enhance the treatment's effectiveness.</p>
                    <br></br>
                    <h1>Combining Dry Needling with Other Therapies for Optimal Results</h1>
                    <p>
                       To maximize the benefits of dry needling, we often combine it with other physiotherapy treatments such as manual therapy, exercise rehabilitation, and ergonomic advice. This integrative approach addresses both the symptoms and the underlying causes of your discomfort. By enhancing muscle function, improving joint mobility, and correcting movement patterns, we aim to provide long-term relief and prevent future issues.
                    </p>
                    <p>Ready to experience the transformative effects of dry needling? Contact us today to schedule your appointment and take the next step toward a healthier, pain-free life.</p>
                </div>
            </div>
            </SkewedContainer>
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NDIS;
