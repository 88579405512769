import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const TheRoleOfPhysiotherapyInManagingDiabetes = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Managing Diabetes with Expert Physiotherapy</h1>
                    <h2>Explore the integral role of physiotherapy in managing diabetes and how Ramp Physio and Fitness in Homebush, NSW, can support your journey towards better health.</h2>
                    <p>Diabetes management is a multifaceted challenge that involves maintaining blood sugar levels, diet, exercise, and overall health. At Ramp Physio and Fitness, our physiotherapy services offer personalized care plans that are essential in managing diabetes effectively. This article will discuss the vital role physiotherapy plays in diabetes management and how it can help improve your quality of life.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Physiotherapy for Blood Sugar Control</h1>
                        <p>Engaging in a physiotherapy program can significantly aid in regulating blood sugar levels. The right exercises improve insulin sensitivity and aid glucose uptake by muscles, which is crucial for people with diabetes. Our physiotherapists at Ramp Physio and Fitness are trained to design exercise programs that not only help manage blood sugar levels but also enhance overall fitness.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Weight Management and Diabetes</h1>
                    <p>Maintaining a healthy weight is critical for managing diabetes, and physiotherapy can be a key component in achieving this goal. Through tailored exercise plans and education, our physiotherapists help you understand the importance of weight control and provide strategies to maintain a healthy weight, reducing the risk of diabetes-related complications.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Improving Circulation with Physiotherapy</h1>
                    <p>Poor circulation is a common issue for those with diabetes, leading to complications like neuropathy. Physiotherapy exercises are designed to enhance circulation, promote wound healing, and prevent further complications. Our team uses a combination of aerobic and resistance training to stimulate blood flow throughout the body.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Education and Diabetes Self-Management</h1>
                    <p>Knowledge is power when it comes to managing diabetes. Our physiotherapists provide valuable education on self-management techniques, including glucose monitoring, diet, and recognizing the signs of blood sugar fluctuations. This empowers our clients to take an active role in their health and manage their condition effectively.</p>
                    <br></br>
                    <p>
                        In conclusion, physiotherapy is an essential part of diabetes management. At Ramp Physio and Fitness, we are committed to providing our clients with the best possible care to manage their diabetes effectively. Our personalized programs and expert guidance are designed to improve your health and enable you to live a more active, fulfilling life. Contact us to learn more about how we can support your diabetes management plan.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default TheRoleOfPhysiotherapyInManagingDiabetes;