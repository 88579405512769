import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';
const NDIS = () => {
    return (
        <div>
{/* While we test GTM for now, we have removed the Google Analytics code from here */}

            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Revolutionize Recovery with Normatec Compression Therapy</h1>
                    <h2>Explore the innovative Normatec compression therapy, a game-changer in sports recovery and pain management, and discover how it integrates into comprehensive care at Ramp Physio and Fitness.</h2>
                    <p>In the dynamic world of physiotherapy and fitness, embracing cutting-edge technology is pivotal to optimizing recovery and enhancing performance. At Ramp Physio and Fitness, we pride ourselves on staying at the forefront of therapeutic advancements, and one such innovation that has significantly elevated our care offerings is Normatec compression therapy. This article delves into the benefits of Normatec, its role in sports injury management, chronic pain relief, and how it complements our tailored healthcare and training services.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>The Science Behind Normatec Compression</h1>
                        <p>Normatec compression therapy is not just a trend; it's a scientifically-backed approach that leverages the principles of pneumatic compression to enhance circulation and promote recovery. The system utilizes a series of overlapping chambers that rhythmically inflate and deflate, creating a massage-like sensation that helps to increase blood flow, reduce swelling, and facilitate the removal of metabolic waste from the muscles. As physiotherapists, we understand the intricacies of the lymphatic and circulatory systems, and Normatec's design is a testament to how technology can harness natural processes to accelerate the body's healing mechanisms.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Personalized Recovery for Every Athlete</h1>
                    <p>Every athlete's body responds to training and injury in unique ways, which is why personalization is key in rehabilitation and recovery. Normatec's cutting-edge technology allows for customization of pressure, timing, and pattern of compression to suit individual needs. Whether it's a runner with shin splints or a weightlifter recovering from a heavy session, Normatec systems can be tailored to target specific areas, providing relief and aiding in faster recovery. At Ramp Physio and Fitness, we integrate Normatec therapy into our treatment plans, ensuring that each athlete receives a recovery protocol that is as unique as their training regimen.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Chronic Pain Management with Normatec</h1>
                    <p>Chronic pain can be a debilitating condition, affecting every aspect of life. Normatec compression therapy has emerged as a valuable tool in managing such pain. By improving circulation and reducing edema, Normatec aids in alleviating the discomfort associated with chronic conditions like lymphedema, venous insufficiency, and more. At our facility, we believe in a holistic approach to pain management, and Normatec serves as a complementary therapy that can be combined with other treatments to provide comprehensive care and improve the quality of life for our patients.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Group Training and Normatec</h1>
                    <p>The benefits of Normatec are not limited to individual therapy sessions; they extend into the realm of group training as well. Group training classes at Ramp Physio and Fitness are designed to be inclusive, challenging, and supportive, and incorporating Normatec compression sessions post- workout can significantly reduce recovery time and improve overall performance. It's a community experience where members can relax together, share their training experiences, and enjoy the recovery benefits of Normatec, fostering a sense of camaraderie and a culture of wellness.</p>
                    <br></br>
                    <p>
                        Normatec compression therapy represents a symbiosis of technology and therapeutic expertise, providing a non-invasive, highly effective tool for recovery and pain management. At Ramp Physio and Fitness, we're committed to integrating such innovations into our practice, offering our clients the best in sports injury management, chronic pain relief, and performance enhancement. Whether you're a high-level athlete or someone seeking relief from persistent pain, Normatec, combined with our expert care, can pave the way to optimal health and fitness..
                    </p>
                </div>
            </div>
            </SkewedContainer>
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NDIS;
