import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const TrainingStrategiesForEnduranceAthletes = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Mastering Endurance: Training Strategies</h1>
                    <h2>Unlock your potential with effective training strategies for endurance athletes, designed to enhance stamina, performance, and overall athletic ability at Ramp Physio and Fitness.</h2>
                    <p>Endurance athletes are a unique breed, requiring specialized training strategies to optimize performance, increase stamina, and prevent injuries. At Ramp Physio and Fitness, we understand the intricacies of endurance sports and offer tailored programs to help you achieve your peak. In this comprehensive guide, we'll explore the key principles of endurance training and provide actionable strategies to help you thrive in your chosen discipline.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Building a Solid Foundation</h1>
                        <p>The cornerstone of any successful endurance program is a solid foundation. This includes developing a strong aerobic base, which can be achieved through consistent, low-intensity training. By focusing on the fundamentals, you lay the groundwork for advanced techniques and increased training loads.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Integrating Strength and Mobility</h1>
                    <p>Strength and mobility are crucial for endurance athletes to improve performance and reduce the risk of injury. Incorporating resistance training and flexibility exercises into your routine enhances muscle endurance and joint range of motion, contributing to more efficient movement patterns.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Periodization and Recovery</h1>
                    <p>Adopting a periodized training approach allows for structured variation in intensity, volume, and specificity of training. Coupled with strategic recovery practices, such as active rest, proper nutrition, and sleep, this approach ensures continual progress without overtraining.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Technique and Efficiency</h1>
                        <p>Refining technique is essential for minimizing energy expenditure and improving overall efficiency. Our expert coaches provide personalized feedback to help you optimize your form, whether it's running mechanics, swim strokes, or cycling posture.</p>
                        <br></br>
                        <p>
                            To cap it off, here are 10 key points to remember when formulating your endurance training strategy:
                            <ul>
                                <li>Establish a consistent aerobic base</li>
                                <li>Incorporate strength training to enhance endurance</li>
                                <li>Integrate mobility work to prevent injuries</li>
                                <li>Utilize periodization to structure your training</li>
                                <li>Focus on recovery to rebuild and strengthen</li>
                                <li>Adopt proper nutrition for fueling and repair</li>
                                <li>Emphasize technique to improve efficiency</li>
                                <li>Include high-intensity interval training (HIIT) for speed</li>
                                <li>Balance training with rest to avoid burnout</li>
                                <li>Set realistic goals and track your progress</li>
                            </ul>
                        </p>
                    </div>
                </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default TrainingStrategiesForEnduranceAthletes;