import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const NdisPhysiotherapyForAnxiety = () => {
    return (
        <div>
            <Helmet>
                <title>NDIS Physiotherapy for Anxiety - Ramp Physio and Fitness</title>
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>NDIS Physiotherapy for Anxiety: Transforming Lives</h1>
                    <h2>Discover how specialized NDIS physiotherapy can alleviate anxiety symptoms and promote mental well-being at Ramp Physio and Fitness.</h2>
                    <p>Anxiety disorders can significantly impact an individual's daily life, but with the support of NDIS-funded physiotherapy, many are finding new hope. At Ramp Physio and Fitness, we offer tailored physiotherapy programs designed to address not only the physical manifestations of anxiety but also to contribute to overall mental health support. Read on to learn how our qualified team can assist participants in managing anxiety through physical interventions and a holistic approach to care.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>The Role of Physiotherapy in Mental Health</h1>
                        <p>Physiotherapy extends beyond physical recovery; it is a powerful tool for mental health management. By incorporating techniques that promote relaxation, improve sleep, and increase physical activity, physiotherapy can significantly reduce symptoms of anxiety. Our programs are designed to empower NDIS participants to take control of their mental and physical health.</p>
                        <p>Here are 10 ways physiotherapy can aid in anxiety management:</p>
                        <p>
                            Exercise routines to boost mood <br/>
                            Breathing techniques for relaxation <br/>
                            Progressive muscle relaxation <br/>
                            Posture correction to reduce tension <br/>
                            Movement therapy to improve body awareness <br/>
                            Tailored exercise plans to increase confidence <br/>
                            Pain management strategies to alleviate anxiety-related discomfort <br/>
                            Sleep hygiene education to improve rest <br/>
                            Mindfulness and grounding exercises during sessions <br/>
                            Home exercise programs to maintain progress
                        </p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/dumbbells.webp" alt="Exercise" />
                    </div>
                </div>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/massage.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Physical Manifestations of Anxiety and Treatment</h1>
                        <p>Anxiety often presents with physical symptoms that can be effectively managed through physiotherapy. These may include muscle tension, rapid breathing, increased heart rate, and physical restlessness. Our physiotherapists use evidence-based techniques like therapeutic massage, guided relaxation, and targeted exercises to help reduce these physical symptoms. Through regular sessions, we can help identify tension patterns in your body and develop strategies to release stress, leading to both physical and mental relief from anxiety symptoms.</p>
                    </div>
                </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>

            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Understanding Anxiety and Physiotherapy</h1>
                    <p>Physiotherapy interventions are becoming increasingly recognized for their role in treating psychosocial disabilities, including anxiety disorders. By addressing the physical symptoms that often accompany anxiety, such as muscle tension and headaches, physiotherapy can provide a non-pharmacological approach to improving mental health.</p>

                    <p>Our physiotherapists at Ramp Physio and Fitness are trained to create a safe and supportive environment, helping individuals with anxiety to engage in physical activity, which has been shown to have a positive effect on mental health. We focus on building a strong therapeutic relationship that fosters trust and confidence, enabling participants to progress towards their mental health goals.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="service-intro-content2">
                        <h1>Personalized Care with NDIS Support</h1>
                        <p>Each individual's experience with anxiety is unique, which is why personalized care is at the heart of our NDIS physiotherapy services. We work closely with participants to develop individualized treatment plans that align with their NDIS goals, addressing both physical health and mental well-being.</p>

                        <p>Our commitment to personalized care means we are constantly evaluating and adapting our strategies to meet the evolving needs of our clients. We aim to provide an inclusive environment where every individual feels valued and supported on their journey to improved health and fitness.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/Pinloaded.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <SubpagesLink />
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NdisPhysiotherapyForAnxiety;