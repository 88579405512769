import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const ManagingHypermobilitySyndromeWithPhysiotherapy = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Mastering Hypermobility with Physiotherapy</h1>
                    <h2>Uncover the benefits of physiotherapy in managing Hypermobility Syndrome at Ramp Physio and Fitness, your pathway to improved stability and quality of life.</h2>
                    <p>Hypermobility Syndrome presents unique challenges and requires specialized care. At Ramp Physio and Fitness, our expert physiotherapists offer tailored strategies to help individuals with hypermobility enhance joint stability, manage pain, and improve overall function. This article explores the critical role of physiotherapy in managing Hypermobility Syndrome and how our personalized approach can empower you to take control of your condition.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Hypermobility Disorders</h1>
                        <p>Joint hypermobility, often seen in conditions such as Ehlers-Danlos Syndrome (EDS) and Hypermobility Spectrum Disorders (HSD), means joints can move beyond the normal range of motion. While it can be an asset for dancers and gymnasts, it often comes with symptoms such as pain, fatigue, and frequent dislocations or sprains. Physiotherapy plays a vital role in managing these symptoms by strengthening the muscles around the joints, improving proprioception, and teaching energy conservation techniques.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Customized Physiotherapy Programs</h1>
                    <p>Every individual with hypermobility has different needs and goals. Our physiotherapists create customized programs that focus on strengthening, joint protection, and pain management. These programs are designed to increase muscle endurance, enhance joint stability, and prevent injuries, allowing individuals to engage in daily activities with greater confidence and less discomfort.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Strategies for Pain Management</h1>
                    <p>Managing pain is a significant aspect of living with hypermobility. Our physiotherapists employ various techniques, including manual therapy, therapeutic exercises, and modalities like heat or ice, to alleviate pain. Education on pacing and joint protection is also provided to help patients minimize the risk of overuse injuries and manage pain effectively in their daily lives.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Enhancing Stability and Balance</h1>
                        <p>One of the key concerns for individuals with hypermobility is the increased risk of falls due to poor proprioception and balance. Our physiotherapy programs include exercises aimed at improving balance, coordination, and proprioception, which are essential for preventing falls and maintaining mobility.</p>
                    </div>
                </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default ManagingHypermobilitySyndromeWithPhysiotherapy;