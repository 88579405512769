import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const NdisPhysiotherapyForGuillainBarreSyndrome = () => {
    return (
        <div>
            <Helmet>
                <title>NDIS Physiotherapy for Guillain Barre Syndrome | Ramp Physio and Fitness</title>
                <meta name="description" content="Ramp Physio and Fitness offers specialized NDIS physiotherapy services for Guillain Barre Syndrome, assisting in recovery and improving quality of life." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Expert NDIS Physiotherapy for Guillain Barre Syndrome</h1>
                    <h2>Personalized Care Plans to Navigate GBS Recovery</h2>
                    <p>Guillain Barre Syndrome (GBS) presents unique challenges to those affected, often requiring specialized physiotherapy to aid in recovery. Ramp Physio and Fitness is dedicated to providing expert NDIS physiotherapy services to help individuals with GBS achieve their health and fitness goals. Our approach is tailored to meet the specific needs of our clients, ensuring a recovery process that is both effective and compassionate.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding GBS and Its Impact</h1>
                        <p>Guillain Barre Syndrome is an autoimmune disorder where the body's immune system mistakenly attacks peripheral nerves. This can result in muscle weakness, paralysis, and sensory disturbances. Early intervention and a multidisciplinary approach, including physiotherapy, are crucial for improving outcomes and enhancing quality of life.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/dumbbells.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Personalized NDIS Physiotherapy Approach</h1>
                    <p>Every individual's experience with GBS is different, which is why our NDIS physiotherapy plans are carefully crafted to address the unique needs of each person. Our certified physiotherapists employ a variety of techniques, including muscle strengthening, balance training, and functional mobility exercises, to support recovery.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Stages of GBS Recovery</h1>
                    <p>GBS recovery can be a long journey, often occurring in stages. Initial treatment focuses on stabilizing the condition, followed by gradual retraining of muscles and nerves. Our physiotherapists work closely with each client through every phase, from acute care to long-term rehabilitation.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/Pinloaded.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Maximizing Independence and Mobility</h1>
                        <p>Our goal is to maximize independence and mobility for individuals with GBS. Through targeted exercises and the use of assistive devices when necessary, we strive to empower our clients to perform daily activities with greater ease and confidence.</p>
                    </div>
                </div>
            </SkewedContainer>
            <SubpagesLink />

            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NdisPhysiotherapyForGuillainBarreSyndrome;