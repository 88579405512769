import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import shoulderPainLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const PhysiotherapyTreatmentAndExercisesForShoulderPain = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Effective Shoulder Pain Management</h1>
                    <h2>Unlock the path to recovery with physiotherapy treatment and exercises tailored for shoulder pain at Ramp Physio and Fitness.</h2>
                    <p>Shoulder pain can significantly impact your daily activities and overall quality of life. At Ramp Physio and Fitness, we specialize in providing targeted physiotherapy treatment and exercises designed to alleviate shoulder pain and restore function. Our experienced team is dedicated to helping you achieve your health and fitness goals through a personalized and evidence-based approach. In this comprehensive guide, we'll delve into the various aspects of managing shoulder pain and how our services can lead to effective recovery.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Shoulder Anatomy</h1>
                        <p>Comprehending the complexity of the shoulder joint is vital for effective treatment. The shoulder is a ball-and-socket joint with a wide range of motion, making it susceptible to various injuries and conditions. Our physiotherapy treatments begin with a thorough assessment to identify the root cause of your shoulder pain, considering factors like joint stability, muscle strength, and movement patterns.</p>

                        <p>Our initial focus is on relieving pain and inflammation, followed by exercises that aim to improve range of motion and strengthen the muscles surrounding the shoulder. This foundational approach is crucial for preventing further injury and ensuring a successful recovery.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/dumbbells.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Personalized Treatment Plans</h1>
                    <p>Each individual's shoulder pain is unique, which is why we offer customized treatment plans tailored to your specific needs. Our physiotherapists employ a combination of manual therapy, including joint mobilizations and soft tissue techniques, alongside therapeutic exercises to promote healing and prevent recurrence.</p>

                    <p>Progressive strengthening and stabilization exercises are integral to our treatment regimen. We emphasize functional movements and proper form to ensure that your shoulder is not only pain-free but also strong and capable of handling daily tasks and athletic activities.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={shoulderPainLogo} alt="Shoulder Pain Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>

            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Strategies for Pain Relief</h1>
                    <p>Managing pain is a priority for those experiencing shoulder discomfort. We integrate a variety of pain-relief techniques such as ice or heat application, taping, and education on activity modification to ensure a comfortable recovery process.</p>

                    <p>Our team also provides guidance on ergonomics and postural correction to address any contributing factors to your shoulder pain. By combining these strategies with a structured exercise program, we aim to facilitate a quicker return to your daily routines and activities.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Pinloaded.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Comprehensive Rehabilitative Exercises</h1>
                        <p>Effective rehabilitation requires a diverse range of exercises. Our physiotherapists guide you through movements aimed at improving flexibility, strength, and control of the shoulder girdle. We incorporate exercises such as rotator cuff strengthening, scapular stabilization, and dynamic shoulder movements to ensure a comprehensive recovery.</p>
                        <p>Our approach is to progress these exercises as your pain decreases and strength improves, always keeping in mind the ultimate goal of returning to your pre-injury level of function or better.</p>

                        <br></br>
                        <p>
                            In conclusion, our expert team at Ramp Physio and Fitness is committed to providing high-quality physiotherapy treatment and exercises for those suffering from shoulder pain. Through a combination of manual therapy, personalized exercise programs, and pain management techniques, we strive to help you achieve a swift and sustainable recovery. Let us support you in overcoming shoulder pain and regaining your active lifestyle.
                        </p>
                    </div>
                </div>
            </SkewedContainer>
            <SubpagesLink />

            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default PhysiotherapyTreatmentAndExercisesForShoulderPain;