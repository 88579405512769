import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const PhysiotherapyForPeripheralNeuropathy = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Managing Peripheral Neuropathy</h1>
                    <h2>Explore physiotherapy's role in alleviating symptoms and enhancing quality of life for those with peripheral neuropathy at Ramp Physio and Fitness.</h2>
                    <p>Peripheral neuropathy can be a challenging condition, leading to symptoms like pain, numbness, and weakness, primarily in the hands and feet. At Ramp Physio and Fitness, our expert physiotherapists provide comprehensive care designed to address these symptoms through tailored exercise programs, manual therapy, and education. In this article, we'll discuss how physiotherapy can be a critical component in managing peripheral neuropathy and improving daily function.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Peripheral Neuropathy</h1>
                        <p>Peripheral neuropathy arises when the peripheral nerves, the extensive communication network that transmits information from the central nervous system to the rest of the body, are damaged. It can result from various causes, including diabetes, infections, and exposure to toxins. Symptoms vary widely, from tingling and numbness to debilitating pain and muscle weakness. Our physiotherapists at Ramp Physio and Fitness are dedicated to understanding your unique presentation and creating a management plan that addresses your specific needs.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Physiotherapy Treatment Approaches</h1>
                    <p>Our physiotherapists employ a variety of treatment approaches to help manage peripheral neuropathy. These may include:</p>
                    <ul>
                        <li>Personalized exercise programs to improve strength and balance</li>
                        <li>Manual therapy techniques to reduce pain and improve mobility</li>
                        <li>Education on self-management strategies and lifestyle modifications</li>
                        <li>Use of modalities like TENS for pain relief</li>
                        <li>Guidance on the use of assistive devices to enhance safety and independence</li>
                        <li>Neurodynamic techniques to improve nerve mobility</li>
                        <li>Tailored stretching routines to maintain flexibility</li>
                        <li>Balance and coordination exercises to prevent falls</li>
                        <li>Functional training to improve daily activities</li>
                        <li>Condition-specific advice for underlying causes like diabetes</li>
                    </ul>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>The Role of Education and Support</h1>
                    <p>Education is a cornerstone of effective neuropathy management. Our physiotherapists provide crucial information on how to manage symptoms, avoid complications, and make informed decisions about daily activities. Support extends beyond the clinic, as we encourage active participation in self-care and offer resources for ongoing management. The goal is to empower our clients with knowledge and skills for long-term success in managing their condition.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Integrating Physiotherapy into Your Life</h1>
                    <p>Incorporating physiotherapy into your life can seem daunting, but at Ramp Physio and Fitness, we make the transition seamless. Our team is committed to working with you to integrate treatment into your routine, ensuring it complements your lifestyle and maximizes your potential for improvement. We focus on practical solutions and realistic goals, helping you take proactive steps towards managing peripheral neuropathy effectively.</p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default PhysiotherapyForPeripheralNeuropathy;