import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const TopSupplementsToSupportYourFitnessJourney = () => {
    return (
        <div>
            {/* While we test GTM for now, we have removed the Google Analytics code from here */}

            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Optimizing Your Fitness Journey with Supplements</h1>
                    <h2>Discover the top supplements to enhance your workouts, recovery, and overall health at Ramp Physio and Fitness.</h2>
                    <p>Embarking on a fitness journey is an exciting and rewarding endeavor. At Ramp Physio and Fitness, we believe in supporting your health and exercise routine with evidence-based supplements that can enhance your performance, aid in recovery, and contribute to your overall well-being. In this detailed guide, we'll explore the top supplements that can complement your fitness regimen, backed by scientific research and industry expertise.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Supplement Fundamentals</h1>
                        <p>Understanding the role of supplements in fitness is crucial. They are designed to fill nutritional gaps, support recovery, and potentially enhance athletic performance. However, they should not replace a balanced diet. It is important to consult with healthcare professionals before starting any supplement regimen, especially if you have underlying health conditions or are taking other medications.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Protein Powders</h1>
                    <p>Protein is essential for muscle repair and growth. Protein powders, such as whey, casein, or plant-based options, are a convenient way to ensure you're meeting your protein needs, particularly after a workout. They can be easily mixed with water or milk and consumed on the go.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>

            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Branched-Chain Amino Acids</h1>
                    <p>Branched-chain amino acids (BCAAs) are three essential amino acids that are critical for muscle protein synthesis. Supplementing with BCAAs can help reduce muscle soreness, accelerate recovery, and may even aid in preserving muscle mass during intense training or weight loss.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Vitamins and Minerals</h1>
                        <p>Micronutrients play key roles in energy production, bone health, and immune function. A multivitamin or targeted supplements like vitamin D, magnesium, and iron can be beneficial, especially if you have a deficiency or are engaged in rigorous training.</p>
                        <br></br>
                        <p>
                            When selecting supplements, it's important to consider quality and efficacy. Here are 10 examples of supplements that can support your fitness journey:
                            <ul>
                                <li>Whey Protein Isolate</li>
                                <li>Casein Protein</li>
                                <li>Plant-Based Protein Blends</li>
                                <li>BCAAs</li>
                                <li>Creatine Monohydrate</li>
                                <li>Omega-3 Fatty Acids</li>
                                <li>Pre-Workout Formulas</li>
                                <li>Glutamine</li>
                                <li>Antioxidant Supplements</li>
                                <li>Electrolyte Tablets</li>
                            </ul>
                        </p>
                    </div>
                </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default TopSupplementsToSupportYourFitnessJourney;