import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const PhysiotherapyForLabralTearsInTheHip = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Overcoming Hip Labral Tears</h1>
                    <h2>Expert Physiotherapy for Labral Tears in the Hip at Ramp Physio and Fitness: Your Path to Recovery</h2>
                    <p>A labral tear in the hip can significantly impact your mobility and quality of life. At Ramp Physio and Fitness, we specialize in providing comprehensive physiotherapy services to address and rehabilitate hip labral tears. Our team of dedicated professionals is equipped with the knowledge and tools to guide you through a tailored recovery plan. This article will explore the benefits of physiotherapy for labral tears in the hip and how our approach at Ramp Physio and Fitness can help you regain strength and function.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Hip Labral Tears</h1>
                        <p>The hip labrum is a ring of cartilage that lines the outside rim of the socket of your hip joint. A tear in this structure can cause pain, stiffness, and instability in the hip. Physiotherapy plays a crucial role in the non-surgical management of hip labral tears. Our physiotherapists at Ramp Physio and Fitness use evidence-based techniques to reduce symptoms and improve hip function.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Personalized Treatment Plans</h1>
                    <p>Every individual's experience with a hip labral tear is unique, which is why personalized treatment plans are essential. Our physiotherapists conduct thorough assessments to create customized rehabilitation programs that address your specific needs and goals. These plans may include a combination of manual therapy, exercise prescription, and education on activity modification to facilitate recovery.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Exercise Therapy for Labral Tears</h1>
                    <p>Exercise therapy is a cornerstone of treatment for hip labral tears. Our physiotherapists design exercise programs that focus on improving hip strength, flexibility, and stability. These exercises are carefully selected to ensure they are safe, effective, and progressive, allowing for gradual improvement without exacerbating symptoms.</p>
                    <ul>
                        <li>Strengthening exercises for hip muscles</li>
                        <li>Stretching routines for improved flexibility</li>
                        <li>Stability exercises to enhance joint support</li>
                        <li>Core strengthening to reduce hip strain</li>
                        <li>Functional training for daily activities</li>
                        <li>Aquatic therapy for low-impact rehabilitation</li>
                        <li>Balance exercises to prevent falls</li>
                        <li>Proprioceptive training for joint awareness</li>
                        <li>Cardiovascular exercises for overall fitness</li>
                        <li>Pain management techniques</li>
                    </ul>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Advanced Therapeutic Techniques</h1>
                    <p>At Ramp Physio and Fitness, we employ advanced therapeutic techniques to assist in the healing of hip labral tears. These may include manual therapy to improve joint mobility, soft tissue mobilization to alleviate muscle tension, and modalities like ultrasound or electrical stimulation to reduce pain and inflammation.</p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default PhysiotherapyForLabralTearsInTheHip;