import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const BenefitsOfFunctionalTrainingExercises = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Maximize Your Movement with Functional Training</h1>
                    <h2>Uncover the multifaceted benefits of functional training exercises and how they enhance everyday activities, sports performance, and overall wellness at Ramp Physio and Fitness.</h2>
                    <p>Functional training exercises are a core component of Ramp Physio and Fitness's approach to holistic health and wellness. These exercises are designed to mimic and support the movements and activities you perform in daily life, improving your physical performance both in and out of the gym. In this comprehensive guide, we'll explore the top benefits of incorporating functional training into your fitness regimen and how it can lead to a stronger, more agile, and resilient body.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Enhance Real-World Strength</h1>
                        <p>Functional training exercises are grounded in the natural movements of the body, such as pushing, pulling, squatting, and lifting. By focusing on these foundational actions, functional training strengthens the muscles that are used for everyday tasks, reducing the risk of injury and improving your ability to perform day-to-day activities with ease.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Boost Athletic Performance</h1>
                    <p>For athletes and fitness enthusiasts, functional training is a game-changer. It enhances coordination, agility, and power, directly translating to improved performance in sports. By simulating sport-specific movements, functional training exercises help athletes fine-tune their skills and gain a competitive edge.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Improve Balance and Stability</h1>
                    <p>Functional training often involves exercises that require balance and stabilization of the core muscles. This focus on the core enhances your center of gravity, leading to better balance and stability in both athletic pursuits and everyday movements, such as walking, reaching, or bending.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Promote Joint Mobility and Flexibility</h1>
                    <p>Functional exercises often involve full-body movements that improve the range of motion in your joints and flexibility in your muscles. Over time, these exercises can lead to increased mobility, which is critical for performing both simple and complex tasks with greater ease and less discomfort.</p>
                </div>
            </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Cultivate Mind-Body Connection</h1>
                    <p>Functional training is not only about physical benefits; it also enhances the mind-body connection. As you engage in exercises that require coordination, concentration, and spatial awareness, you'll develop a heightened sense of how your body moves through space, leading to improved mental clarity and focus.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Encourage Muscle Synergy</h1>
                    <p>Unlike isolated exercises that target individual muscle groups, functional training encourages different muscle groups to work together synergistically. This muscle synergy not only makes you stronger and more efficient in your movements but also helps prevent muscle imbalances and related injuries.</p>
                </div>
            </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Functional Training Examples</h1>
                    <ul>
                        <li>Squats to mimic sitting and standing</li>
                        <li>Deadlifts to replicate lifting objects from the ground</li>
                        <li>Push-ups to enhance upper body strength for pushing movements</li>
                        <li>Pull-ups to improve grip strength and upper body pulling motions</li>
                        <li>Lunges for better walking and running mechanics</li>
                        <li>Planks to build a strong and stable core</li>
                        <li>Medicine ball throws for explosive power and coordination</li>
                        <li>Kettlebell swings for dynamic hip hinge movements</li>
                        <li>Farmer's walks to develop grip strength and endurance</li>
                        <li>Box jumps to improve lower body power and plyometric ability</li>
                    </ul>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default BenefitsOfFunctionalTrainingExercises;