import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const NdisPhysiotherapyForFibromyalgia = () => {
    return (
        <div>
            <Helmet>
                <title>NDIS Physiotherapy for Fibromyalgia | Ramp Physio and Fitness</title>
                <meta name="description" content="Discover how NDIS Physiotherapy can help manage Fibromyalgia symptoms. Ramp Physio and Fitness provides tailored support for effective pain relief and improved quality of life." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Fibromyalgia Management through NDIS Physiotherapy</h1>
                    <h2>Explore evidence-based physiotherapy for Fibromyalgia under the NDIS at Ramp Physio and Fitness, offering a pathway to improved wellness and function.</h2>
                    <p>Fibromyalgia, a condition characterized by widespread chronic pain, fatigue, and tenderness in specific areas, can significantly impact one's quality of life. At Ramp Physio and Fitness, we specialize in NDIS Physiotherapy designed for those living with Fibromyalgia. Our goal is to provide relief from symptoms, enhance physical function, and empower clients to lead a more active and fulfilling life.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Fibromyalgia and Its Impact</h1>
                        <p>Fibromyalgia is a complex syndrome that requires a multifaceted approach to management. Symptoms such as sleep disturbances, cognitive difficulties, and emotional stress often accompany the primary pain complaints, making it essential to address these issues in tandem with physical therapy.</p>
                        <p>Our NDIS accredited physiotherapists at Ramp Physio and Fitness are equipped with the expertise to understand the nuances of Fibromyalgia. We work closely with clients to develop a personalized treatment plan that caters to their specific needs, focusing on pain management, strength building, and improving overall functionality.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/dumbbells.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Tailored Therapeutic Interventions</h1>
                    <p>Our therapeutic interventions are carefully chosen based on the latest research and our clients' responses to treatment. Techniques such as manual therapy, soft tissue massage, and joint mobilization are utilized to alleviate discomfort and improve movement.</p>
                    <p>Exercise therapy plays a crucial role in our treatment protocol. We incorporate low-impact exercises to strengthen muscles, improve endurance, and increase flexibility without exacerbating pain. Our physiotherapists also provide guidance on pacing and energy conservation techniques to help manage fatigue.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Empowering Self-Management and Education</h1>
                    <p>Educating our clients about Fibromyalgia is a cornerstone of our practice. We offer strategies for pain management, stress reduction, and self-help techniques to empower clients in managing their condition. This includes advice on sleep hygiene, nutrition, and activity modification to support a healthier lifestyle.</p>
                    <p>In addition to in-clinic sessions, we provide resources and tools for clients to use at home, ensuring continuity of care and encouraging self-efficacy. Our team is committed to supporting clients every step of the way, fostering independence and resilience.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/Pinloaded.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Integrating Technology for Enhanced Care</h1>
                        <p>Innovations in physiotherapy technology allow us to enhance the care we provide to our clients with Fibromyalgia. We utilize equipment and software to monitor progress, tailor exercises, and provide biofeedback, which can be particularly beneficial for pain management and improving body awareness.</p>
                        <p>By integrating technology into our practice, we can offer more precise and effective treatments, track client outcomes, and adjust interventions as needed to ensure the best possible results.</p>
                        <br></br>
                        <p>
                            With a commitment to excellence and client-centered care, Ramp Physio and Fitness is dedicated to helping those with Fibromyalgia navigate their symptoms through NDIS Physiotherapy. Our team is ready to support your journey to better health and wellness.
                        </p>
                    </div>
                </div>
            </SkewedContainer>
            <SubpagesLink />
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NdisPhysiotherapyForFibromyalgia;