import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const NDIS = () => {
    return (
        <div>
{/* While we test GTM for now, we have removed the Google Analytics code from here */}
            <div className="service-detail-content">
                <div className="service-detail-text">
                <br></br><br></br>
                    <h1>EMS and Shockwave Therapy</h1>
                    <h2>Boost Muscle Recovery and Strength with EMS Therapy</h2>
                    <p>At Ramp Physio and Fitness, we offer Electrical Muscle Stimulation (EMS) therapy to help you recover faster and strengthen muscles effectively. EMS therapy uses controlled electrical impulses to stimulate muscle contractions, which can enhance muscle strength, improve circulation, and accelerate recovery from injuries. Whether you’re an athlete looking to improve performance or someone recovering from a muscle injury, our expert physiotherapists incorporate EMS into your treatment plan to maximize your results.</p>
                    <p>Ready to boost your recovery and muscle strength? Contact us today to schedule a consultation and learn how EMS therapy can help you achieve your goals.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Discover the Benefits of EMS Therapy for Muscle Health</h1>
                        <p>EMS therapy offers a range of benefits, including enhanced muscle recovery, improved circulation, and increased muscle endurance. It’s an effective treatment for conditions like muscle atrophy, post-surgery rehabilitation, and even as part of athletic training programs. At Ramp Physio and Fitness, we utilize EMS therapy to help our clients achieve better muscle health and faster recovery times, ensuring that you get back to your activities with improved strength and resilience.</p>
                        <p>Interested in the benefits of EMS therapy? Contact us to see how this treatment can improve your muscle health and support your recovery journey.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Customized EMS Therapy Programs for Optimal Muscle Recovery</h1>
                    <p>At Ramp Physio and Fitness, we understand that each client’s needs are unique. That’s why we create personalized EMS therapy programs tailored to your specific condition and goals. Whether you need to recover from an injury, prevent muscle atrophy, or enhance athletic performance, our physiotherapists will design an EMS treatment plan that fits your needs and helps you achieve the best possible outcomes.</p>
                    <p>Looking for a customized approach to muscle recovery and strength? Contact us to start your personalized EMS therapy program and experience the benefits of targeted care.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Success Stories: Achieving Stronger Muscles with EMS Therapy</h1>
                    <p>Our clients have seen remarkable improvements in muscle strength, recovery, and overall performance with EMS therapy. From recovering from surgery to enhancing athletic abilities, our success stories highlight the effectiveness of EMS as a key part of physiotherapy. Read their testimonials to learn how Ramp Physio and Fitness has helped others achieve their recovery and performance goals with EMS therapy.</p>
                    <p>Inspired by our clients’ success with EMS therapy? Start your journey to stronger muscles and faster recovery today. Contact us to book your appointment and discover how EMS therapy can benefit you.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="white" noMargin>
            <HealthSection />
            </SkewedContainer>
            <TestimonialSection />
        </div>
    );
};

export default NDIS;
