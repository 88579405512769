import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const GymWorkoutsForBoostingMetabolism = () => {
    return (
        <div>
            <Helmet>
                <title>Gym Workouts for Boosting Metabolism - Ramp Physio and Fitness</title>
                <meta name="description" content="Discover effective gym workouts for boosting metabolism with Ramp Physio and Fitness. Achieve your health and fitness goals in Homebush, NSW." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Maximize Metabolism with Gym Workouts</h1>
                    <h2>Unleash your body's full potential with targeted gym workouts designed to enhance metabolism and promote optimal health at Ramp Physio and Fitness.</h2>
                    <p>Are you looking to revamp your fitness routine and ignite your metabolism? At Ramp Physio and Fitness, we specialize in gym workouts that not only build strength and endurance but also boost your metabolic rate. In this comprehensive guide, we will explore the science behind metabolism and provide you with an actionable plan to increase your calorie burn both during and after your workout.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Metabolism</h1>
                        <p>Metabolism refers to the process by which your body converts what you eat and drink into energy. A higher metabolic rate means more calories burned, which can lead to weight loss and increased energy levels. Our gym workouts are designed to challenge your body in new ways, effectively boosting your metabolism through a variety of resistance training and high-intensity exercises.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Metabolism-Boosting Techniques</h1>
                    <p>Our expert trainers utilize cutting-edge techniques to help you maximize your metabolic burn. From strength training to maximize muscle mass, which naturally increases calorie burn, to circuit training that keeps your heart rate up, our programs are tailored to help you achieve the best results.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>High-Intensity Interval Training</h1>
                    <p>High-Intensity Interval Training (HIIT) is a cornerstone of our metabolism-boosting strategy. By alternating short bursts of intense activity with periods of rest, HIIT can significantly increase your metabolic rate and improve your cardiovascular health.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container5">
                <div className="service-intro-content5">
                    <h1>Strength Training for Metabolic Health</h1>
                    <p>Building muscle is essential for a healthy metabolism. Our strength training programs are designed to help you build lean muscle mass, which not only shapes your body but also increases your resting metabolic rate, allowing you to burn more calories even when you're not working out.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
            </div>
            </SkewedContainer>
            <div className="service-intro-container6">
                <div className="service-intro-content6">
                    <h1>Customized Workout Plans</h1>
                    <p>At Ramp Physio and Fitness, we understand that every individual is unique. That's why we offer personalized workout plans that consider your fitness level, goals, and metabolic needs. Our trainers will work with you to create a regimen that's both challenging and achievable, ensuring you stay motivated and see results.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container7">
                    <h1>Top 10 Metabolism-Boosting Workouts</h1>
                    <ul>
                        <li>HIIT Circuit Sessions</li>
                        <li>Full-Body Strength Training</li>
                        <li>Powerlifting Techniques</li>
                        <li>Bodyweight Workouts</li>
                        <li>Kettlebell Training</li>
                        <li>Functional Fitness Challenges</li>
                        <li>Tabata Workouts</li>
                        <li>Plyometric Drills</li>
                        <li>Core Strengthening Exercises</li>
                        <li>Cardio Intervals on Treadmill</li>
                    </ul>
                </div>
            </SkewedContainer>
            <div className="service-intro-container8">
                <div className="service-intro-content8">
                    <h1>Nutrition and Metabolism</h1>
                    <p>Exercise is just one piece of the metabolism puzzle. Proper nutrition is equally important, and our team can provide guidance on how to fuel your body to complement your workout regimen and further enhance your metabolic rate.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
            </div>
            <div className="service-intro-container9">
                <div className="service-intro-content9">
                    <h1>Recovery and Metabolism</h1>
                    <p>Recovery is an essential part of any fitness program. Adequate rest and recovery not only prevent injury but also ensure that your metabolism functions at its best. Our facility offers various recovery services, including physiotherapy, to help you get back to your workouts faster and stronger.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container10">
                    <div className="service-intro-content10">
                        <h1>Join Us for Metabolic Success</h1>
                        <p>In conclusion, boosting your metabolism through gym workouts is a powerful way to achieve your health and fitness goals. At Ramp Physio and Fitness, we're committed to providing you with the knowledge, resources, and support to help you succeed. Join our community and experience the transformative power of metabolism-boosting workouts.</p>
                        <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="servicedetail-book-now"
                        >
                            Book Now
                        </a>
                    </div>
                </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default GymWorkoutsForBoostingMetabolism;