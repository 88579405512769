import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const PhysiotherapyInOccupationalHealth = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Optimizing Workplace Health</h1>
                    <h2>Uncover the role of physiotherapy in occupational health and how Ramp Physio and Fitness fosters a healthier, more productive workplace through expert physiotherapy interventions.</h2>
                    <p>Physiotherapy plays a pivotal role in occupational health, contributing to the prevention and management of work-related injuries and illnesses. At Ramp Physio and Fitness, we understand the importance of maintaining a healthy workforce and offer specialized services designed to optimize workplace health and safety. In this detailed article, we'll explore the various aspects of physiotherapy in the context of occupational health, its benefits, and how our expert team can help individuals and organizations in Homebush, NSW, achieve their health and fitness goals.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Enhancing Workplace Ergonomics</h1>
                        <p>One of the primary focuses of occupational health physiotherapy is improving workplace ergonomics to prevent musculoskeletal disorders. Our physiotherapists assess work environments and provide tailored advice on workstation setup, posture, and movement patterns to reduce the risk of injury and enhance employee well-being.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Rehabilitation and Recovery</h1>
                    <p>Effective rehabilitation is crucial for employees recovering from work-related injuries. Our physiotherapists design comprehensive rehabilitation programs that not only facilitate a safe return to work but also prevent future injuries by addressing underlying risk factors.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Workplace Health Assessments</h1>
                    <p>Our team conducts thorough workplace health assessments to identify potential hazards and implement strategies to mitigate risks. By evaluating tasks, equipment, and the physical demands of job roles, we help create safer work environments that promote health and productivity.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Education and Training</h1>
                    <p>Education is a cornerstone of occupational health physiotherapy. We offer training sessions for employees and management on injury prevention, proper lifting techniques, and the importance of physical fitness, empowering individuals to take an active role in their workplace health.</p>
                    <br></br>
                    <ul>
                        <li>Understanding body mechanics</li>
                        <li>Safe material handling practices</li>
                        <li>Stress management techniques</li>
                        <li>Stretching and strengthening exercises</li>
                        <li>Importance of regular breaks and movement</li>
                        <li>Ergonomic equipment usage</li>
                        <li>Personal protective equipment (PPE) training</li>
                        <li>Early intervention strategies</li>
                        <li>Health and wellness promotion</li>
                        <li>Customized workplace wellness programs</li>
                    </ul>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default PhysiotherapyInOccupationalHealth;