import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const NdisPhysiotherapyForChromosomalDisorders = () => {
    return (
        <div>
            <Helmet>
                <title>NDIS Physiotherapy for Chromosomal Disorders | Ramp Physio and Fitness</title>
                <meta name="description" content="Tailored NDIS physiotherapy services for individuals with chromosomal disorders at Ramp Physio and Fitness in Homebush, NSW." />
            </Helmet>

            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Empowering Lives Through NDIS Physiotherapy</h1>
                    <h2>Discover how our specialized NDIS physiotherapy programs for chromosomal disorders can enhance mobility, function, and independence at Ramp Physio and Fitness.</h2>
                    <p>Chromosomal disorders present unique challenges that require specialized care and support. At Ramp Physio and Fitness, we are dedicated to providing personalized NDIS physiotherapy services to help individuals with chromosomal disorders achieve their health and fitness goals. Our expert team combines clinical expertise with compassionate care to create an environment where every client can thrive. In this article, we explore the importance of tailored physiotherapy and how our approach can make a real difference in the lives of those we serve.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>

            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Chromosomal Disorders</h1>
                        <p>Chromosomal disorders are genetic conditions that result from changes in the number or structure of chromosomes. These changes can lead to a variety of health issues, including developmental delays, physical disabilities, and other medical complexities. NDIS physiotherapy is a crucial component in managing these conditions, offering support to enhance physical capabilities and promote a higher quality of life.</p>

                        <p>Our physiotherapists at Ramp Physio and Fitness are trained to assess and address the specific needs of individuals with chromosomal disorders. We work closely with participants, their families, and other healthcare professionals to develop comprehensive care plans that target mobility, strength, and functional independence.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/dumbbells.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>

            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/massage.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Personalized Therapy for Individual Needs</h1>
                        <p>Our NDIS physiotherapy services are tailored to the individual, ensuring that each person receives care that is specifically designed for their unique needs. We focus on creating achievable goals that are aligned with the participant's NDIS plan, taking into consideration their personal aspirations and the recommendations of their healthcare team.</p>

                        <p>Through a combination of hands-on therapy, exercise prescription, and assistive technology, we empower our clients to overcome obstacles and work towards greater autonomy in their daily activities. Our state-of-the-art gym facilities are equipped to accommodate a wide range of abilities, ensuring a safe and effective workout experience for all.</p>
                    </div>
                </div>
            </SkewedContainer>

            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>

            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Integrating Holistic Health Strategies</h1>
                    <p>Our approach to NDIS physiotherapy extends beyond physical treatment. We believe in the power of holistic health strategies that encompass the emotional and social aspects of living with a chromosomal disorder. Our team provides education and support to help clients and their families understand their condition and manage it effectively.</p>

                    <p>We integrate a variety of techniques to promote overall well-being, including stress management, nutritional guidance, and sleep hygiene. By addressing these key areas, we aim to improve not just physical health, but also the overall happiness and life satisfaction of our clients.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
            </div>

            <div className="service-intro-container5">
                <div className="service-intro-content5">
                    <h1>Collaborative Care and Support Networks</h1>
                    <p>Building a support network is essential for individuals with chromosomal disorders and their families. We collaborate with a range of professionals, including occupational therapists, speech pathologists, and psychologists, to ensure a coordinated and comprehensive approach to care.</p>

                    <p>Our team also works closely with community organizations and support groups to connect clients with resources and peer support. This network of care helps to foster a sense of community and belonging, which is vital for long-term success and personal growth.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Pinloaded.webp" alt="Exercise" />
                </div>
            </div>

            <SubpagesLink />

            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NdisPhysiotherapyForChromosomalDisorders;