import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const PhysiotherapyAndGymForCoreStrengthAndStability = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Building Your Foundation</h1>
                    <h2>Unlock your potential with our expert-led physiotherapy and gym programs designed to enhance core strength and stability at Ramp Physio and Fitness.</h2>
                    <p>Core strength and stability are the bedrock of a healthy and active lifestyle. At Ramp Physio and Fitness, we understand that a robust core is crucial for optimal performance, injury prevention, and overall well-being. Our tailored physiotherapy and gym services are meticulously crafted to help you build and maintain a strong, stable core. In this comprehensive guide, we'll explore the importance of core strength, the role of physiotherapy in achieving it, and how our gym facilities complement your journey towards a resilient core.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Core Strength" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Core Stability Explained</h1>
                        <p>Core stability refers to the body's ability to maintain balance and control of the spine and pelvis during movement. A stable core provides a foundation for all physical activities, from daily tasks to high-level athletics. Our physiotherapists employ evidence-based methods to assess and enhance your core stability, ensuring that you can perform at your best without the risk of injury.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Physiotherapy for Core Development</h1>
                    <p>Physiotherapy is integral to developing and maintaining core strength and stability. Our physiotherapists specialize in creating personalized exercise programs that target the deep core muscles. By incorporating a variety of techniques, including manual therapy, movement analysis, and specific exercises, we guide you towards a stronger, more resilient core.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Gym Training for Core Fortitude</h1>
                    <p>The gym at Ramp Physio and Fitness is equipped with state-of-the-art facilities designed to support your core strength and stability goals. Our personal trainers work closely with our physiotherapy team to ensure that your gym routine complements your therapeutic exercises, leading to balanced and effective core development.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Core Strength in Daily Life</h1>
                    <p>Core strength and stability extend beyond the gym and therapy sessions. They play a significant role in your daily activities, improving posture, reducing the risk of falls, and enhancing your ability to perform tasks with ease. At Ramp Physio and Fitness, we emphasize functional core training to ensure that the benefits you gain are transferable to your everyday life.</p>
                    <br></br>
                    <p>
                        Core strength and stability are more than just fitness buzzwords; they are essential components of a healthy, active lifestyle. Whether you're an athlete, a weekend warrior, or someone looking to improve your quality of life, our team at Ramp Physio and Fitness is here to support you every step of the way. Join us and discover how a strong, stable core can transform your physical capabilities and enhance your overall well-being.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default PhysiotherapyAndGymForCoreStrengthAndStability;