import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const IncorporatingMindfulnessAndMeditationIntoFitness = () => {
    return (
        <div>
            <Helmet>
                <title>Mindfulness in Fitness - Ramp Physio and Fitness</title>
                <meta name="description" content="Learn how to integrate mindfulness and meditation into your fitness routine with Ramp Physio and Fitness for enhanced mental and physical well-being." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Mindfulness Meets Muscle</h1>
                    <h2>Unite Your Mind and Body for Optimal Fitness and Well-being at Ramp Physio and Fitness</h2>
                    <p>At Ramp Physio and Fitness, we believe that true health encompasses both the physical and the mental. By incorporating mindfulness and meditation into your fitness regimen, you can achieve a higher level of well-being, enhance your exercise performance, and foster a deeper connection with your body. In this comprehensive guide, we'll explore the transformative power of mindful fitness and provide practical tips to seamlessly blend mindfulness into your routine.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Mindfulness and Fitness" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Harmonizing Body and Mind</h1>
                        <p>Merging mindfulness with physical activity can lead to profound benefits for both mental and physical health. This harmonious approach to fitness not only improves focus and reduces stress but also enhances the quality of your workouts. By practicing mindful movement, you can deepen your body awareness, prevent injury, and improve recovery, leading to a more fulfilling and sustainable fitness journey.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Maximizing Mental Clarity</h1>
                    <p>The integration of meditation into your fitness routine can significantly enhance mental clarity and concentration. By cultivating a mindful practice, you can train your brain to focus on the present moment, which can lead to improved performance in your workouts and daily life. Discover how to incorporate meditation techniques before, during, and after exercise to maximize your mental and physical potential.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Stress Reduction Techniques</h1>
                    <p>Learn effective stress-reduction techniques that blend mindfulness with physical exercise. By engaging in mindful breathing and visualization exercises during your workouts, you can lower cortisol levels and enhance your body's relaxation response. This section will provide you with practical strategies to reduce stress and increase the restorative benefits of your fitness regimen.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Enhancing Physical Awareness</h1>
                    <p>Deepen your connection with your body by enhancing physical awareness through mindfulness. This section will guide you through exercises designed to increase body consciousness, allowing you to tune into your body's signals and movements more effectively. By doing so, you can improve posture, alignment, and movement efficiency, leading to better exercise outcomes and reduced risk of injury.</p>
                    <br></br>
                    <ul>
                        <li>Focus on the breath during exercise.</li>
                        <li>Practice body scans pre and post-workout.</li>
                        <li>Engage in mindful walking or running.</li>
                        <li>Incorporate RAMP individually tailored and physiotherapy designed and monitored flexibility and core stability programs into your routine.</li>
                        <li>Use guided imagery to enhance performance.</li>
                        <li>Adopt a non-judgmental attitude towards your fitness level.</li>
                        <li>Set intention-based goals for each workout session.</li>
                        <li>Listen to your body and adjust your workouts accordingly.</li>
                        <li>Practice gratitude for your body's capabilities.</li>
                        <li>Reflect on your fitness journey with a mindfulness journal.</li>
                    </ul>
                    <p>
                        Embracing these techniques, you can create a more intuitive and responsive approach to fitness, leading to a healthier and more balanced lifestyle. Join us at Ramp Physio and Fitness as we guide you through integrating mindfulness into your training, helping you achieve a state of peak physical and mental health.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default IncorporatingMindfulnessAndMeditationIntoFitness;