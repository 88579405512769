import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const UnderstandingBodyCompositionAndSettingFitnessGoals = () => {
    return (
        <div>
            {/* While we test GTM for now, we have removed the Google Analytics code from here */}

            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Unlocking Your Fitness Potential</h1>
                    <h2>Discover the importance of body composition in your fitness journey and how setting personalized goals can transform your health and well-being at Ramp Physio and Fitness.</h2>
                    <p>Understanding body composition is a game-changer when it comes to achieving your fitness goals. It's not just about the number on the scale; it's about understanding what your body is made of. At Ramp Physio and Fitness, we believe in looking beyond weight loss to help you achieve a balanced and healthy body composition. In this detailed exploration, we'll guide you through the concepts of body composition, its significance, and how to set realistic and effective fitness goals tailored to your unique body and aspirations.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Comprehending Body Composition</h1>
                        <p>Body composition refers to the percentages of fat, bone, water, and muscle in human bodies. Two people of the same gender and body weight may look completely different because they have a different body composition. At Ramp Physio and Fitness, our advanced body composition analysis gives you a detailed breakdown of your body's components, providing a clearer picture of your health than traditional scales can offer.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/massage.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Setting Tailored Fitness Goals</h1>
                        <p>Setting the right fitness goals is crucial to your success. Your goals should be personalized, measurable, attainable, relevant, and time-bound (SMART). Whether you're aiming to lose fat, gain muscle, or improve overall health, our team at Ramp Physio and Fitness works with you to set realistic goals that are in line with your body composition and lifestyle.</p>
                    </div>
                </div>
            </SkewedContainer>
            <SkewedContainer top="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>

            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Body Composition Analysis</h1>
                    <p>Our comprehensive body composition analysis is the first step toward setting effective goals. This analysis provides insights into your fat mass, lean body mass, and body water levels. Understanding these metrics helps tailor your fitness plan to target specific areas, whether it's increasing muscle mass or decreasing body fat percentage.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Personalized Training Programs</h1>
                        <p>At Ramp Physio and Fitness, we develop personalized training programs based on your body composition analysis. Our expert trainers design routines that not only help you reach your fitness goals but also support sustainable health improvements. Here are 10 key components we focus on:</p>
                        <ul>
                            <li>Customized strength training exercises</li>
                            <li>Cardiovascular workouts for fat loss</li>
                            <li>Nutritional guidance for muscle gain</li>
                            <li>Stretching routines for flexibility</li>
                            <li>Core stability exercises for better posture</li>
                            <li>Balance training to prevent injuries</li>
                            <li>High-Intensity Interval Training (HIIT) for endurance</li>
                            <li>Recovery strategies, including rest and recovery days</li>
                            <li>Hydration and its role in body composition</li>
                            <li>Continuous progress monitoring and adjustments</li>
                        </ul>
                    </div>
                </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default UnderstandingBodyCompositionAndSettingFitnessGoals;