import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const GymWorkoutsForManagingDiabetes = () => {
    return (
        <div>
            <Helmet>
                <title>Gym Workouts for Managing Diabetes | Ramp Physio and Fitness</title>
                <meta name="description" content="Discover effective gym workouts tailored for managing diabetes at Ramp Physio and Fitness. Enhance your health and fitness journey with our expert guidance." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Gym Workouts for Managing Diabetes</h1>
                    <h2>Take control of your diabetes with targeted gym workouts designed to improve blood sugar management and overall health at Ramp Physio and Fitness.</h2>
                    <p>Living with diabetes doesn't mean you have to give up on your fitness goals. In fact, regular exercise can be a powerful tool for managing blood sugar levels and improving your quality of life. At Ramp Physio and Fitness, we specialize in creating gym workouts that cater to the needs of individuals with diabetes. This article will explore how the right exercise regimen can help you manage your diabetes and maintain optimal health.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Gym Workouts for Diabetes" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Exercise Essentials for Diabetes</h1>
                        <p>Exercise is a cornerstone of diabetes management, helping to increase insulin sensitivity and control blood glucose levels. A combination of aerobic activities, strength training, and flexibility exercises can have a profound impact on your diabetes. Here are ten key workouts that can be incorporated into your fitness routine:</p>
                        <ul>
                            <li>Aerobic exercises like brisk walking or cycling</li>
                            <li>Resistance training with weights or bands</li>
                            <li>Swimming or water aerobics for low-impact cardio</li>
                            <li>RAMP individually tailored and physiotherapy designed and monitored flexibility and core stability programs</li>
                            <li>Bodyweight exercises such as push-ups and squats</li>
                            <li>Circuit training to combine cardio and strength</li>
                            <li>Elliptical training for joint-friendly cardio</li>
                            <li>High-intensity interval training (HIIT) for a quick workout</li>
                            <li>Balance exercises to prevent falls and improve coordination</li>
                            <li>Stretching routines to maintain flexibility and reduce injury risk</li>
                        </ul>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Strength Training's Role in Diabetes</h1>
                    <p>Strength training is particularly beneficial for individuals with diabetes as it helps to build muscle mass, which in turn improves glucose uptake by the muscles and reduces blood sugar levels. Engaging in strength training at least twice a week can lead to better diabetes control and a host of other health benefits.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Cardiovascular Workouts for Blood Sugar</h1>
                    <p>Cardiovascular exercises are essential for burning calories and improving heart health, which is crucial for diabetes management. Regular cardio workouts can help regulate blood sugar levels, reduce the risk of heart disease, and promote weight loss. Incorporating a variety of cardio exercises ensures a well-rounded approach to your diabetes care plan.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Customized Workouts for Individual Needs</h1>
                    <p>Every individual with diabetes has unique health needs and fitness levels. At Ramp Physio and Fitness, we understand the importance of personalized workout plans. Our team of experts will work with you to create a tailored exercise program that not only helps manage your diabetes but also aligns with your personal fitness goals.</p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default GymWorkoutsForManagingDiabetes;