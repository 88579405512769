import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const Physiotherapytreatmentandexercisesforpostnatalphysiotherapy = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Restoring Strength Post-Pregnancy</h1>
                    <h2>Exploring Physiotherapy Treatment and Exercises for Post-Natal Rehabilitation at Ramp Physio and Fitness.</h2>
                    <p>Post-pregnancy, women face unique physical challenges as their bodies recover from childbirth. At Ramp Physio and Fitness, we specialize in providing physiotherapy treatment and exercises tailored for post-natal rehabilitation. Our expert team is dedicated to helping new mothers regain their strength, improve core stability, and address any pregnancy-related conditions. Discover how our physiotherapy services can support your post-natal journey to wellness.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Post-Natal Physiotherapy" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Post-Natal Body Changes</h1>
                        <p>The post-natal period brings about numerous changes to a woman's body. Understanding these changes is crucial to creating an effective physiotherapy plan. Common issues include weakened pelvic floor muscles, diastasis recti, and changes in posture. Our physiotherapists work closely with new mothers to address these concerns through targeted exercises and hands-on treatment.</p>

                        <p>Our initial assessment focuses on evaluating your specific post-natal condition, allowing us to personalize your recovery plan. We prioritize techniques that safely restore function and strength, particularly in the core and pelvic floor muscles, which are essential for overall stability and health.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/dumbbells.webp" alt="Post-Natal Changes" />
                    </div>
                </div>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/massage.webp" alt="Post-Natal Massage" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Post-Natal Rehabilitation Focus</h1>
                        <p>Rehabilitation post-pregnancy is a delicate process. Our physiotherapists are trained to guide new mothers through exercises that are not only effective but also safe for their recovering bodies. We emphasize gradual progression and listen to the body's cues to prevent any setbacks in recovery.</p>

                        <p>Key components of our post-natal physiotherapy program include pelvic floor exercises, breathing techniques, and gentle core strengthening. We also provide education on proper body mechanics to support daily activities involving your new baby.</p>
                    </div>
                </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>

            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Essential Post-Natal Exercises</h1>
                    <p>Exercise is a vital component of post-natal recovery. At Ramp Physio and Fitness, we advocate for a series of essential exercises that cater to the needs of post-partum women. These exercises are designed to rebuild pelvic floor strength, enhance core stability, and improve overall fitness.</p>

                    <p>Our physiotherapy approach includes a mix of low-impact cardiovascular activities, strength training for major muscle groups, and flexibility exercises. We ensure that each exercise is performed with proper form to maximize benefits and minimize risk.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Post-Natal Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Pinloaded.webp" alt="Post-Natal Strength" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Individualized Treatment Plans</h1>
                        <p>Each woman's post-natal journey is unique, and so is our treatment plan. We customize every aspect of the physiotherapy program to fit the individual needs of our clients. From the initial evaluation to the final stages of recovery, our plans are flexible and adapt to the changing needs of new mothers.</p>
                        <p>Our physiotherapists are committed to providing a supportive and nurturing environment where post-natal women can thrive and regain their pre-pregnancy fitness levels. We set realistic goals and celebrate each milestone in the recovery process.</p>

                        <br></br>
                        <p>
                            In summary, Ramp Physio and Fitness offers a comprehensive approach to post-natal physiotherapy, with a focus on safe, effective treatment and exercises. Our dedicated team is here to support you in restoring your strength and confidence as you navigate the challenges of motherhood. Contact us today to book your post-natal physiotherapy session and take the first step towards a healthier, more vibrant post-pregnancy life.
                        </p>
                    </div>
                </div>
            </SkewedContainer>
            <SubpagesLink />

            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default Physiotherapytreatmentandexercisesforpostnatalphysiotherapy;