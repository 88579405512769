import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';
const NDIS = () => {
    return (
        <div>
{/* While we test GTM for now, we have removed the Google Analytics code from here */}

            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Unveiling the Benefits of Musculoskeletal Physiotherapy</h1>
                    <h2>This article demystifies musculoskeletal physiotherapy, exploring its benefits and applications in injury management and chronic pain relief, highlighting the expert care provided at Ramp Physio and Fitness..</h2>
                    <p>Welcome to a deeper understanding of musculoskeletal physiotherapy—an essential service we proudly offer at Ramp Physio and Fitness. As a dedicated physiotherapist, I witness daily the transformative effects this specialized care can have on individuals dealing with pain, injury, and mobility issues. Musculoskeletal physiotherapy isn't just about treatment; it's about empowering you to understand your body, optimize your recovery, and enhance your overall well-being. Let's explore how this therapy can be your ally in achieving a pain-free, active lifestyle.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>The Essence of Musculoskeletal Physiotherapy</h1>
                        <p>Musculoskeletal physiotherapy is a specialized area of physical therapy that focuses on the treatment of injuries and conditions affecting muscles, bones, joints, and soft tissues. It's a realm of healthcare where science meets personalized care, aiming to alleviate pain, repair damage, and increase mobility. At Ramp Physio and Fitness, our approach to musculoskeletal physiotherapy is rooted in evidence-based treatment, tailored to meet the unique needs of each patient.</p>
                        <p>Our team of experienced therapists is equipped with advanced knowledge and skills to assess, diagnose, and manage a wide range of musculoskeletal conditions. Whether you're an athlete managing a sports injury or someone struggling with chronic back pain, we're here to guide you through a journey of recovery and resilience. Our commitment to your health is unwavering, and through our comprehensive treatment plans, we aim to restore your quality of life and get you back to the activities you love.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Injury Management and Recovery</h1>
                    <p>Injury can be a major setback, but with the right physiotherapy support, it doesn't have to sideline you for long. Musculoskeletal physiotherapy plays a pivotal role in the management and recovery of sports-related and accidental injuries. By employing techniques such as manual therapy, exercise prescription, and movement re-education, we help patients regain strength, function, and flexibility.</p>
                    <p>Our therapists understand the demands of various sports and activities, which allows us to create rehabilitation programs that not only address the injury but also focus on preventing future issues. We work closely with you to ensure that each phase of recovery is approached with care, enabling a safe and efficient return to peak performance.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Chronic Pain Alleviation Strategies</h1>
                    <p>Living with chronic pain can be an exhausting battle, but it's not one you have to fight alone. Musculoskeletal physiotherapy offers a beacon of hope, providing strategies to manage and reduce long-term pain. Our approach is holistic, considering not just the physical aspects of pain, but also the psychological and social factors that can influence your experience.</p>
                    <p>Through a combination of hands-on treatment, therapeutic exercises, and education on pain science, we empower you to take control of your pain. Our goal is to improve your function and independence, allowing you to engage more fully in your daily life without the constant shadow of discomfort.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Empowerment Through Education and Training</h1>
                    <p>At Ramp Physio and Fitness, we believe that education is power—especially when it comes to managing your health. That's why musculoskeletal physiotherapy includes a strong educational component. We take the time to explain your condition, the reasons behind your symptoms, and how the treatments work. This knowledge is a powerful tool that can help you become an active participant in your recovery.</p>
                    <p>Additionally, we offer personalized training programs designed to strengthen your body and prevent further injury. Our group training classes provide a supportive community where you can work towards your fitness goals under the guidance of our physiotherapists. Whether you're looking to improve your general health or optimize your athletic performance, we're here to support you every step of the way.</p>
                    <br></br>
                    <p>
                        Musculoskeletal physiotherapy at Ramp Physio and Fitness is more than just a service—it's a pathway to a better, healthier you. Our dedicated team is committed to providing exceptional care that addresses your unique needs, whether you're recovering from an injury, managing chronic pain, or seeking to improve your overall fitness. With our expertise and your commitment, we can achieve remarkable results that empower you to live your life to the fullest. Join us on this journey to wellness, and experience the difference that professional, compassionate care can make.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NDIS;
