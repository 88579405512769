import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const NdisPhysiotherapyForChronicFatigue = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>NDIS Physiotherapy for Chronic Fatigue</h1>
                    <h2>Expert management plans for chronic fatigue symptoms under the NDIS at Ramp Physio and Fitness.</h2>
                    <p>Living with chronic fatigue syndrome (CFS) can be profoundly challenging, impacting daily activities and overall quality of life. Ramp Physio and Fitness recognizes the complexity of CFS and the crucial role physiotherapy plays in managing this condition. Through the National Disability Insurance Scheme (NDIS), we provide personalized care plans tailored to support individuals with CFS. Our focus is on improving functional capacity, reducing symptoms, and empowering participants to lead a more active lifestyle.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Chronic Fatigue Syndrome</h1>
                        <p>Chronic fatigue syndrome is characterized by extreme fatigue that doesn't improve with rest and may worsen with physical or mental activity. CFS can be accompanied by a range of symptoms including sleep disturbances, pain, and cognitive impairments. It is a complex condition that requires an interdisciplinary approach for effective management.</p>

                        <p>At Ramp Physio and Fitness, we begin with an NDIS-funded comprehensive assessment to understand the unique challenges faced by each individual. This assessment forms the basis of a tailored physiotherapy plan that aligns with the participant's NDIS goals and aspirations.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/dumbbells.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/massage.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Personalized NDIS Physiotherapy Plans</h1>
                        <p>Our physiotherapy services under the NDIS are designed to cater to the specific needs of those with CFS. We focus on strategies to manage fatigue, enhance energy conservation, and gradually increase activity levels without exacerbating symptoms. The personalized plans may include:</p>
                        <p>
                            - Gentle aerobic exercise to improve stamina<br/>
                            - Strength training to build muscle endurance<br/>
                            - Flexibility exercises to maintain joint health<br/>
                            - Breathing techniques to optimize oxygen consumption<br/>
                            - Pacing strategies to balance activity and rest<br/>
                            - Education on symptom management and lifestyle adjustments
                        </p>
                        <p>Our experienced physiotherapists work collaboratively with participants, their families, and other healthcare professionals to ensure a coordinated and holistic approach to care.</p>
                    </div>
                </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Strategies for Symptom Management</h1>
                    <p>Effective management of chronic fatigue symptoms involves a multifaceted approach. Our physiotherapists employ various techniques to help manage the symptoms of CFS, including:</p>
                    <p>
                        - Pain relief strategies to alleviate discomfort<br/>
                        - Sleep hygiene education to improve restorative sleep<br/>
                        - Stress reduction techniques to minimize symptom flare-ups<br/>
                        - Nutritional advice to support energy levels<br/>
                        - Cognitive strategies to cope with mental fatigue<br/>
                        - Regular monitoring and adaptation of the care plan
                    </p>
                    <p>These strategies are incorporated into our NDIS physiotherapy services, ensuring participants receive comprehensive care that addresses all aspects of their condition.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/Pinloaded.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Empowering Participants Through Education</h1>
                        <p>Education is a powerful tool in the management of chronic fatigue syndrome. We provide participants with the knowledge and resources to understand their condition and take an active role in their recovery. This includes:</p>
                        <p>
                            - Understanding the nature of CFS and its impact on function<br/>
                            - Learning about the importance of self-care and rest<br/>
                            - Identifying personal triggers and how to manage them<br/>
                            - Developing a personal toolkit for symptom management<br/>
                            - Engaging with support networks and community resources<br/>
                            - Setting realistic goals and celebrating progress
                        </p>
                        <p>Our goal is to empower individuals to manage their symptoms effectively and achieve a greater sense of control over their health and well-being.</p>
                    </div>
                </div>
            </SkewedContainer>
            <SubpagesLink />
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NdisPhysiotherapyForChronicFatigue;