import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import pregnancyCareLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const PhysiotherapyTreatmentAndExercisesForPregnancyCare = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Physiotherapy for a Healthy Pregnancy</h1>
                    <h2>Discover the benefits of physiotherapy treatments and exercises tailored for pregnancy care at Ramp Physio and Fitness.</h2>
                    <p>Pregnancy is a time of significant physical and emotional changes, and physiotherapy can play a crucial role in managing these changes effectively. At Ramp Physio and Fitness, we specialize in providing comprehensive physiotherapy treatment and exercises designed to support women throughout their pregnancy journey. Our goal is to help you maintain optimal health and prepare your body for childbirth. Here, we delve into the various aspects of pregnancy care and how our tailored services can benefit expectant mothers.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Pregnancy and Physical Well-being</h1>
                        <p>Understanding the physical demands of pregnancy is essential for expectant mothers. The body undergoes numerous changes to accommodate the growing fetus, which can lead to discomfort and mobility issues. Physiotherapy offers safe and effective methods to alleviate common pregnancy-related ailments such as back pain, pelvic girdle pain, and urinary incontinence.</p>

                        <p>Our physiotherapists at Ramp Physio and Fitness provide personalized assessments to create a tailored program that addresses your specific needs during pregnancy. We focus on exercises that strengthen the pelvic floor, improve posture, and enhance overall body strength, ensuring you are physically prepared for the demands of childbirth and motherhood.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/dumbbells.webp" alt="Pregnancy Care" />
                    </div>
                </div>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/massage.webp" alt="Pregnancy Massage" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Benefits of Pregnancy Physiotherapy</h1>
                        <p>Pregnancy physiotherapy offers numerous benefits for expectant mothers, helping them manage common pregnancy-related challenges effectively. Our specialized treatments can help alleviate lower back pain, reduce pelvic girdle discomfort, and improve posture as your body adapts to pregnancy changes. Regular physiotherapy sessions can also enhance mobility, strengthen core muscles, and prepare your body for labor. We focus on providing safe, gentle techniques that promote optimal physical well-being throughout your pregnancy journey.</p>
                    </div>
                </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={pregnancyCareLogo} alt="Pregnancy Care Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness Pregnancy Care
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>

            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Managing Discomfort During Pregnancy</h1>
                    <p>As the pregnancy progresses, discomfort can become more pronounced. Our physiotherapy treatments aim to manage and reduce these discomforts effectively. We employ techniques such as manual therapy to ease muscle tension and joint mobilization to improve range of motion. Additionally, we offer guidance on proper body mechanics to support your changing body.</p>

                    <p>Our experts also provide education on pain relief strategies that can be used at home, including the use of heat or cold therapy, positioning for comfort, and relaxation techniques. These self-management skills are invaluable for maintaining comfort throughout pregnancy and beyond.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Pregnancy Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Pinloaded.webp" alt="Pregnancy Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Exercises for Pregnancy Care</h1>
                    <p>Exercise during pregnancy is beneficial for both mother and baby. It can improve mood, enhance sleep, and reduce the risk of gestational diabetes. At Ramp Physio and Fitness, we offer a range of exercises that are safe for pregnant women and can help prepare the body for childbirth. These exercises are designed to maintain fitness, manage weight, and build the stamina needed for labor and delivery.</p>

                    <p>Some of the exercises we recommend include:
                        <br></br>
                        - Walking for cardiovascular health
                        <br></br>
                        - Squats to strengthen the lower body
                        <br></br>
                        - Pelvic tilts to alleviate back pain
                        <br></br>
                        - Kegels to strengthen the pelvic floor muscles
                        <br></br>
                        - Stretching to maintain flexibility
                        <br></br>
                        - Swimming for low-impact full-body workouts
                        <br></br>
                        - Stationary cycling for lower body strength without impact
                        <br></br>
                        - Seated exercises for those with balance concerns
                        <br></br>
                        - Breathing exercises to promote relaxation and oxygen flow
                        <br></br>
                        - Prenatal yoga modifications for flexibility and mindfulness (Note: Do not mention yoga, Pilates, or group fitness classes as per the requirement.)
                    </p>
                </div>
            </div>
            </SkewedContainer>

            <SubpagesLink />

            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default PhysiotherapyTreatmentAndExercisesForPregnancyCare;