import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';
const NDIS = () => {
    return (
        <div>
{/* While we test GTM for now, we have removed the Google Analytics code from here */}

            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Revolutionizing Plantar Fasciitis Treatment with Shockwave Therapy</h1>
                    <h2>Explore the innovative approach of shockwave therapy for plantar fasciitis, a non-invasive treatment that offers pain relief and promotes healing for those suffering from this debilitating foot condition.</h2>
                    <p>Plantar fasciitis can be a persistent and painful condition affecting the heel and arch of the foot, often making everyday activities challenging. At Ramp Physio and Fitness, we understand the importance of effective treatment options that not only provide relief but also fit seamlessly into our patients' lives. Shockwave therapy has emerged as a groundbreaking solution for those suffering from plantar fasciitis, offering a non-invasive alternative to traditional treatments with promising results.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Plantar Fasciitis and Its Impact</h1>
                        <p>Plantar fasciitis is a common affliction where the plantar fascia, the thick band of tissue that runs across the bottom of your foot, becomes inflamed, leading to heel pain and stiffness. This condition can severely impact the quality of life, limiting physical activity and causing persistent discomfort. Traditional treatments have ranged from physical therapy and orthotics to more invasive procedures like injections or surgery. However, with shockwave therapy, patients now have access to a novel treatment modality that can effectively address the root cause of pain without the need for more invasive methods.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>The Science Behind Shockwave Therapy</h1>
                    <p>OShockwave therapy, also known as extracorporeal shockwave therapy (ESWT), is a non-invasive treatment that uses sound waves to stimulate healing in injured tissue. These waves create microtrauma that promotes the body's natural healing processes, increasing blood flow and cellular activity in the affected area. This therapy has been gaining traction for its efficacy in treating a variety of musculoskeletal conditions, particularly plantar fasciitis. By delivering focused energy to the plantar fascia, shockwave therapy can help break down scar tissue, reduce inflammation, and alleviate pain, setting the stage for long-term recovery.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Real-world Benefits of Shockwave Therapy for Patients</h1>
                    <p>Patients seeking relief from plantar fasciitis at Ramp Physio and Fitness have experienced the real-world benefits of shockwave therapy. The treatment is quick, typically lasting only about 15 minutes per session, and requires no downtime, allowing patients to return to their daily routines immediately. Many report significant pain reduction after just a few sessions, with lasting improvements in function and mobility. Furthermore, shockwave therapy is a safe alternative to surgical interventions, with a lower risk of complications and a higher satisfaction rate among patients.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Integrating Shockwave Therapy into Your Recovery Plan</h1>
                    <p>At Ramp Physio and Fitness, we believe in a holistic approach to healthcare, where treatments are tailored to the individual needs of each patient. Incorporating shockwave therapy into your recovery plan can be a game-changer for those struggling with plantar fasciitis. Our team of skilled physiotherapists works closely with patients to ensure that shockwave therapy is integrated effectively into their overall treatment strategy, which may include personalized training, chronic pain management techniques, and support through services like NDIS. By embracing this innovative therapy, patients can take an active role in their recovery and regain control over their mobility and comfort.</p>
                    <br></br>
                    <p>
                        In conclusion, shockwave therapy for plantar fasciitis represents a significant advancement in the management of this condition. At Ramp Physio and Fitness, we are dedicated to providing our patients with the latest and most effective treatments to help them overcome pain and lead active, fulfilling lives. With the promise of reduced pain and improved function, shockwave therapy is an exciting addition to our comprehensive range of services aimed at enhancing physical well-being.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NDIS;
