import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import whiplashLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const PhysiotherapyTreatmentAndExercisesForWhiplash = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Overcoming Whiplash: Effective Physiotherapy and Exercises</h1>
                    <h2>Unlock the path to recovery from whiplash with targeted physiotherapy treatment and exercises at Ramp Physio and Fitness.</h2>
                    <p>Whiplash injuries can occur unexpectedly and lead to persistent discomfort and reduced mobility. At Ramp Physio and Fitness, we specialize in delivering precise physiotherapy treatment and exercises designed to alleviate whiplash symptoms and restore your neck's normal function. Let's delve into the best practices for whiplash recovery and how our expert team can assist you in your journey to wellness.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Whiplash Injuries</h1>
                        <p>Whiplash is a neck injury due to forceful, rapid back-and-forth movement of the neck, like the cracking of a whip. It often occurs during rear-end auto accidents, but can also result from sports accidents, physical abuse, or other trauma. Common symptoms include neck pain, stiffness, headaches, and dizziness.</p>

                        <p>At Ramp Physio and Fitness, we begin with a detailed assessment to understand the severity of your whiplash injury. This initial evaluation is crucial to creating a personalized treatment plan that addresses your specific symptoms and promotes optimal recovery.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/dumbbells.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Effective Treatment Modalities</h1>
                    <p>Our physiotherapy approach for whiplash involves a combination of manual therapy techniques and targeted exercises. Manual therapy may include gentle neck mobilizations and soft tissue work to relieve pain and improve mobility.</p>

                    <p>Exercises are then introduced to strengthen neck muscles, improve posture, and enhance range of motion. These exercises are carefully selected and customized to each individual's condition and recovery stage, ensuring safe and effective rehabilitation.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={whiplashLogo} alt="Whiplash Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>

            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Exercise Strategies for Whiplash</h1>
                    <p>Exercise is a cornerstone of whiplash recovery. We advocate for a proactive approach with exercises that include cervical retraction, shoulder blade squeezes, and isometric strengthening. These exercises, among others, are instrumental in rebuilding the strength and stability of the neck and upper back.</p>

                    <p>Our physiotherapists will guide you through each exercise, ensuring proper form and technique to maximize benefits and prevent further injury. We'll also provide you with a home exercise program to continue your progress outside of our clinic.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Pinloaded.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Advanced Rehabilitation Techniques</h1>
                        <p>As you progress in your recovery, we may introduce more advanced exercises to challenge your neck muscles further and restore full function. These can include dynamic movements, resistance training, and proprioceptive exercises to enhance neuromuscular control.</p>
                        <p>Our goal is to not only relieve your symptoms but to strengthen your neck to a level that can prevent future injuries. We'll work with you to ensure that each exercise is both safe and effective, and aligned with your recovery goals.</p>

                        <br></br>
                        <p>
                            Recovery from whiplash can be a challenging process, but with the right physiotherapy treatment and exercises, you can achieve significant improvements in pain, mobility, and overall quality of life. At Ramp Physio and Fitness, we are committed to providing the highest level of care to help you return to your daily activities without the burden of whiplash symptoms. Trust our team to support you every step of the way.
                        </p>
                    </div>
                </div>
            </SkewedContainer>
            <SubpagesLink />

            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default PhysiotherapyTreatmentAndExercisesForWhiplash;