import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const PhysiotherapyForStrokeRehabilitation = () => {
    return (
        <div>
            <Helmet>
                <title>Stroke Rehabilitation with Expert Physiotherapy</title>
                <meta name="description" content="Achieve effective recovery from stroke with tailored physiotherapy programs at Ramp Physio and Fitness. Our expert team is dedicated to your rehabilitation." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Expert Stroke Rehabilitation</h1>
                    <h2>Regain Independence and Improve Quality of Life with Personalized Physiotherapy</h2>
                    <p>Stroke can be a life-altering event, often requiring dedicated rehabilitation to regain lost functions and improve quality of life. At Ramp Physio and Fitness in Homebush, NSW, our expert physiotherapists specialize in stroke rehabilitation, helping individuals navigate the path to recovery with personalized treatment plans. This article explores the critical role of physiotherapy in stroke recovery and how our approach can support your journey to wellness.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Stroke and Recovery</h1>
                        <p>Stroke occurs when blood flow to an area of the brain is interrupted or reduced, causing brain cells to die. Recovery from a stroke involves relearning skills that are lost when part of the brain is damaged. Physiotherapy plays a vital role in this process, with treatment approaches designed to improve mobility, balance, and strength. Our physiotherapists utilize evidence-based techniques to facilitate your rehabilitation journey.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Personalized Treatment Strategies</h1>
                    <p>Every stroke survivor has unique rehabilitation needs. Our physiotherapists develop personalized treatment strategies that focus on individual goals. We assess your specific challenges and tailor interventions to improve function and accelerate recovery. Our comprehensive approach includes:</p>
                    <ul>
                        <li>Muscle strengthening and coordination exercises</li>
                        <li>Balance and gait training</li>
                        <li>Manual therapy and massage</li>
                        <li>Functional electrical stimulation</li>
                        <li>Education and support for family and caregivers</li>
                    </ul>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>The Role of Exercise in Stroke Recovery</h1>
                    <p>Exercise is a cornerstone of stroke rehabilitation. It helps to retrain the brain through neuroplasticity, the brain's ability to form new neural connections. Our physiotherapists guide you through exercises that are safe, effective, and specifically designed to address the deficits caused by stroke. This includes exercises to improve:</p>
                    <ul>
                        <li>Motor skills and movement patterns</li>
                        <li>Strength and endurance</li>
                        <li>Flexibility and range of motion</li>
                        <li>Postural control and core stability</li>
                        <li>Sensory and perceptual skills</li>
                        <li>Activities of daily living</li>
                    </ul>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Community and Ongoing Support</h1>
                    <p>Stroke rehabilitation is not just a physical journey; it's an emotional and social one as well. We offer support beyond the clinic, encouraging community engagement and providing resources for continued progress. Our commitment to your recovery extends to:</p>
                    <ul>
                        <li>Support groups and social activities</li>
                        <li>Education on stroke prevention and health maintenance</li>
                        <li>Guidance for home exercise programs</li>
                        <li>Collaboration with other healthcare professionals</li>
                        <li>Assistance with return to work or leisure activities</li>
                        <li>Ongoing assessments and follow-up care</li>
                    </ul>
                    <br></br>
                    <p>
                        Stroke recovery is a marathon, not a sprint. At Ramp Physio and Fitness, we are your partners in this journey, providing expert guidance, compassionate care, and a supportive community to help you reclaim your independence and enhance your quality of life. Contact us to learn more about our stroke rehabilitation services and take the first step towards your recovery.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default PhysiotherapyForStrokeRehabilitation;