import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';
const NDIS = () => {
    return (
        <div>
{/* While we test GTM for now, we have removed the Google Analytics code from here */}

            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Navigating Recovery: Physiotherapy for Whiplash Injuries</h1>
                    <h2>Discover the pivotal role of physiotherapy in the management and recovery of whiplash injuries, offering patients a pathway to regain mobility and reduce pain.</h2>
                    <p>Whiplash injuries can be a significant source of discomfort and disability for many individuals, particularly those involved in motor vehicle accidents. The sudden, forceful movement of the neck, characteristic of whiplash, can lead to a range of debilitating symptoms that may affect daily life. At Ramp Physio and Fitness, we understand the complexities of whiplash-associated disorders (WAD) and provide comprehensive care tailored to each patient's unique needs. Our approach integrates top-tier sports injury management techniques with evidence-based physiotherapy practices to support your journey back to health and fitness.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Whiplash and Its Impact on Your Health</h1>
                        <p>Whiplash injuries occur when the head is rapidly thrust backward and then forward, straining the neck muscles and ligaments. This type of injury is most commonly associated with rear-end vehicle collisions but can also result from sports accidents, physical abuse, or other trauma. The aftermath of whiplash can range from mild neck stiffness to severe chronic pain, often accompanied by headaches, dizziness, and sometimes cognitive or emotional disturbances.</p>
                        <p>The initial assessment of a whiplash injury is crucial. A comprehensive examination helps determine the extent of the damage and informs the creation of a personalized treatment plan. This plan may include pain management strategies, manual therapy, and exercises designed to restore range of motion and strengthen the neck muscles.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Tailored Treatment Plans for Whiplash Recovery</h1>
                        <p>Physiotherapy for whiplash is not a one-size-fits-all solution. At Ramp Physio and Fitness, we recognize that each individual's experience with whiplash is unique. Our treatment plans are carefully crafted to address the specific challenges and goals of our patients. We employ a variety of techniques, including manual therapy, exercise prescription, and education on posture and ergonomics, to facilitate recovery.</p>
                        <p>Manual therapy techniques, such as soft tissue mobilization and joint manipulation, can be particularly effective in alleviating pain and restoring movement. Meanwhile, targeted exercises help to rebuild strength and flexibility in the neck and surrounding muscles, reducing the likelihood of future injury.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Integrative Approach to Chronic Pain and Rehabilitation</h1>
                    <p>Chronic pain is a potential consequence of whiplash, and managing it effectively is essential for long-term recovery. Our integrative approach combines physical therapies with lifestyle modifications to help patients cope with and overcome chronic pain. We may also collaborate with other healthcare professionals, such as psychologists or occupational therapists, to provide comprehensive support for the physical and emotional aspects of chronic pain.</p>
                    <p>Group training classes at Ramp Physio and Fitness can also serve as a valuable component of the rehabilitation process. Participating in group sessions allows patients to engage in supervised exercises tailored to their recovery stage, promoting a sense of community and shared progress.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>NDIS Support and Personalized Training for Whiplash Injuries</h1>
                    <p>For patients navigating the National Disability Insurance Scheme (NDIS), Ramp Physio and Fitness offers dedicated support to ensure access to necessary physiotherapy services. We work closely with NDIS participants to develop personalized training programs that align with their individual treatment plans and recovery goals.</p>
                     <p>Our expert physiotherapists are committed to helping patients with whiplash injuries regain their strength, mobility, and confidence. Whether you're dealing with an acute injury or chronic pain, our personalized training sessions are designed to challenge and support you at every step of your rehabilitation journey.</p>
                    <br></br>
                    <p>
                        Whiplash injuries can be a challenging roadblock on the path to optimal health and fitness. However, with the right support and guidance, recovery is within reach. At Ramp Physio and Fitness, we are dedicated to providing our patients with the highest standard of care, from acute injury management to long-term pain relief and rehabilitation. If you or someone you know is struggling with the effects of whiplash, reach out to our team. Together, we'll work towards restoring your well-being and getting you back to the activities you love.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NDIS;
