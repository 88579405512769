import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const IncorporatingDanceFitnessForFunWorkouts = () => {
    return (
        <div>
            <Helmet>
                <title>Incorporating Dance Fitness for Fun Workouts - Ramp Physio and Fitness</title>
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Dance Your Way to Fitness</h1>
                    <h2>Experience the joy of movement with dance fitness at Ramp Physio and Fitness, where workouts become an exhilarating blend of rhythm and exercise.</h2>
                    <p>At Ramp Physio and Fitness, we believe that exercise should be as enjoyable as it is effective. That's why we're thrilled to introduce our dance fitness programs, designed to make your workouts feel like a party. Whether you're a seasoned dancer or have two left feet, our dance fitness classes offer a fun, high-energy way to get in shape and improve your overall well-being. In this article, we'll explore the benefits of incorporating dance into your fitness routine and how it can transform your workout experience.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Dance Fitness" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Unleash Your Inner Dancer</h1>
                        <p>Our dance fitness classes are more than just a workout; they're a celebration of life and movement. With a variety of styles to choose from, including Zumba, hip-hop, and salsa, you'll find your groove and burn calories without even realizing it. Dance fitness is an excellent way to improve coordination, flexibility, and cardiovascular health, all while having a blast.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Benefits Beyond the Beat</h1>
                    <p>Dance fitness offers a full-body workout that challenges your body in new and exciting ways. The benefits of dance extend beyond physical health, also enhancing mental clarity and emotional well-being. As you lose yourself in the music and movement, you'll also be boosting your mood, reducing stress, and increasing self-confidence.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Dynamic Dance Workouts</h1>
                    <ul>
                        <li>Zumba: The Ultimate Cardio Dance Party</li>
                        <li>Hip-Hop Fitness: Street Dance Meets Gym</li>
                        <li>Belly Dancing: Core Strengthening with a Twist</li>
                        <li>Ballet Fitness: Graceful and Powerful</li>
                        <li>Salsa Fitness: Spice Up Your Routine</li>
                        <li>Jazzercise: Jazz Up Your Fitness</li>
                        <li>Barre: Ballet-Inspired Toning</li>
                        <li>Reggaeton: Feel the Latin Heat</li>
                        <li>Bollywood Dance: Energetic and Expressive</li>
                        <li>Afrobeat Workout: Rhythmic and Raw Energy</li>
                    </ul>
                    <p>Dance workouts are a creative way to get fit while embracing various cultures and musical styles. Our dance fitness classes offer a myriad of options to keep you engaged and excited. Let's explore 10 dynamic workouts that can be part of your fitness journey:</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Join the Dance Revolution</h1>
                    <p>At Ramp Physio and Fitness, we're dedicated to making fitness accessible and enjoyable for everyone. Join our dance fitness revolution and discover a new way to stay active and healthy. Whether you're looking to improve your fitness, find a new hobby, or simply have fun, our dance classes are the perfect fit. Get ready to move to the beat and feel the energy of dance fitness!</p>
                    <br></br>
                    <p>
                        Embark on a fitness journey that's filled with fun, energy, and the infectious rhythm of dance. Let Ramp Physio and Fitness be your partner in achieving your health goals through the power of movement. Book your dance fitness class today and transform your workout routine into an exhilarating dance adventure!
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default IncorporatingDanceFitnessForFunWorkouts;