import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const ManagingFibromyalgiaSymptomsWithPhysiotherapy = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Alleviating Fibromyalgia Pain Through Physiotherapy</h1>
                    <h2>Explore the benefits of physiotherapy in managing fibromyalgia symptoms and take control of your well-being with the expert care at Ramp Physio and Fitness.</h2>
                    <p>Fibromyalgia is a chronic condition characterized by widespread pain, fatigue, and a range of other symptoms. It can be a challenging condition to manage, but physiotherapy has been shown to be an effective way to alleviate symptoms and improve quality of life. At Ramp Physio and Fitness, our tailored physiotherapy programs are designed to address the unique needs of individuals with fibromyalgia. This article will explore how physiotherapy can help you manage fibromyalgia symptoms and lead a more comfortable life.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Fibromyalgia and Physiotherapy</h1>
                        <p>Fibromyalgia affects millions worldwide, with symptoms that can severely impact daily activities. Physiotherapy offers a non-pharmacological approach that can reduce pain, enhance mobility, and improve overall physical function. Through various techniques such as exercise therapy, manual therapy, and education, physiotherapists at Ramp Physio and Fitness empower clients to manage their fibromyalgia symptoms effectively.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Exercise Therapy for Fibromyalgia</h1>
                    <p>Structured exercise is a cornerstone of fibromyalgia management. At Ramp Physio and Fitness, we design personalized exercise programs that focus on aerobic conditioning, strength training, and flexibility exercises. These programs are tailored to each individual's capabilities and are gradually progressed to prevent exacerbation of symptoms while promoting physical resilience.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Manual Therapy and Pain Management</h1>
                    <p>Manual therapy techniques, such as massage and myofascial release, can provide relief from the muscle stiffness and tender points commonly associated with fibromyalgia. These methods help to decrease pain, improve range of motion, and facilitate better sleep patterns, which are crucial for managing fibromyalgia symptoms.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Education and Self-Management</h1>
                        <p>Understanding fibromyalgia is a critical aspect of managing the condition. Our physiotherapists provide education on pain management strategies, stress reduction techniques, and lifestyle modifications to help clients gain control over their symptoms and improve their ability to perform daily activities.</p>
                    </div>
                </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default ManagingFibromyalgiaSymptomsWithPhysiotherapy;