import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';
const NDIS = () => {
    return (
        <div>
{/* While we test GTM for now, we have removed the Google Analytics code from here */}

            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Finding Relief: Physiotherapy for Sciatica</h1>
                    <h2>Uncover effective strategies for managing sciatica pain through physiotherapy. This comprehensive guide provides insights into personalized treatment plans and exercises for lasting relief.</h2>
                    <p>If you're grappling with the sharp, sometimes debilitating pain of sciatica, you're not alone. At Ramp Physio and Fitness, we understand that each step and movement can be a challenge when dealing with this condition. Our dedicated team is here to guide you through a journey of recovery, harnessing the power of physiotherapy to alleviate your discomfort and improve your quality of life. This article explores the multifaceted approach of physiotherapy for sciatica, offering hope and solutions for those seeking respite from their symptoms.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Sciatica and Its Impact</h1>
                        <p>Sciatica is more than just a simple backache; it's a symptom of an underlying condition that affects the sciatic nerve, the longest and widest nerve in the human body. When this nerve is irritated or compressed, it can cause pain that radiates from your lower back down to your legs, often leading to discomfort that ranges from a mild ache to an excruciating sensation that can hinder everyday activities. The causes of sciatica are varied, including herniated discs, spinal stenosis, or piriformis syndrome, and addressing the root cause is essential for effective treatment.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Tailored Physiotherapy for Sciatic Relief</h1>
                    <p>At Ramp Physio and Fitness, we don't believe in a one-size-fits-all solution. Our physiotherapists are experts in crafting personalized treatment plans that cater to the individual needs of our clients. Through a detailed assessment, we identify the specific factors contributing to your sciatica and develop a program that may include manual therapy, targeted exercises, and education on posture and ergonomics. Our goal is not only to alleviate your current pain but also to equip you with the tools and knowledge to prevent future episodes.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Exercises and Movement Strategies</h1>
                    <p>Exercise is a cornerstone of sciatica management, and physiotherapy provides a structured approach to ensure movements are safe and effective. By focusing on exercises that strengthen the core and stretch the muscles affecting the sciatic nerve, our clients can experience significant improvements in their symptoms. Our physiotherapists guide you through a series of movements tailored to reduce nerve irritation and promote a healthier spine, all while considering your comfort and pain levels.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>The Supportive Role of NDIS and Group Training</h1>
                    <p>At Ramp Physio and Fitness, we recognize the importance of ongoing support in managing chronic conditions like sciatica. For eligible individuals, our services extend to NDIS support, ensuring that everyone has access to the care they need. Additionally, our group training classes offer a community-based environment where clients can work together towards their recovery goals, finding motivation and camaraderie among peers who understand their journey.</p>
                    <br></br>
                    <p>
                        In conclusion, physiotherapy for sciatica at Ramp Physio and Fitness is a dynamic and patient-centered approach to managing pain and improving function. By understanding the intricacies of sciatica, tailoring treatment plans to individual needs, and providing supportive services like NDIS support and group training, we empower our clients to take control of their health and embark on a path toward lasting relief.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NDIS;
