import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const SciaticaTreatment = () => {
    return (
        <div>
{/* While we test GTM for now, we have removed the Google Analytics code from here */}

            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br /><br />
                    <h1>Physiotherapy for Sciatica</h1>
                    <h2>Effective Sciatica Treatment for Lasting Relief</h2>
                    <p>
                        At Ramp Physio and Fitness, we specialize in providing effective physiotherapy treatments for sciatica to help you alleviate pain and regain mobility. Sciatica is characterized by pain radiating along the sciatic nerve, which runs from your lower back down through your hips and legs. Our experienced physiotherapists are dedicated to identifying the root cause of your sciatica and developing a personalized treatment plan to promote healing and prevent future episodes.
                    </p>
                    <p>
                        Experiencing sciatica pain? Don't let it disrupt your life any longer. <a href="/contact">Contact us</a> today to schedule an appointment and take the first step toward a pain-free life.
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Sciatica and Its Causes</h1>
                        <p>
                            Sciatica occurs when the sciatic nerve is compressed or irritated, leading to pain, numbness, or tingling that can extend from the lower back down to the feet. Common causes include herniated discs, spinal stenosis, piriformis syndrome, and degenerative disc disease. Understanding the underlying cause is essential for effective treatment and long-term relief.
                        </p>
                        <p>
                            Not sure what's causing your sciatica? Our experts can provide a thorough assessment to identify the source of your pain. <a href="/appointments">Schedule an appointment</a> today to begin your journey to recovery.
                        </p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Our Comprehensive Approach to Sciatica Treatment</h1>
                    <p>
                        At Ramp Physio and Fitness, we adopt a holistic approach to treating sciatica. Our physiotherapists create individualized treatment plans that may include manual therapy, targeted exercises, posture correction, and education on body mechanics. This comprehensive strategy aims to reduce pain, improve mobility, and strengthen supporting muscles to prevent future occurrences.
                    </p>
                    <p>
                        Ready to embark on a personalized treatment journey? <a href="/contact">Contact us</a> to develop a comprehensive plan tailored to your needs.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                 <div className="service-intro-content4">
                    <h1>Success Stories from Our Clients</h1>
                    <p>
                        Many of our clients have experienced significant relief from sciatica through our specialized physiotherapy programs. From regaining mobility to returning to daily activities without pain, we are proud to have made a positive impact on their lives. Read their testimonials to learn how Ramp Physio and Fitness has helped them overcome sciatica.
                    </p>
                    <br />
                    <h1>Who Can Benefit from Our Sciatica Treatment?</h1>
                    <p>
                        Our sciatica treatment is suitable for individuals experiencing pain, numbness, or tingling along the sciatic nerve pathway. Whether your symptoms are mild or severe, our physiotherapists can provide effective interventions to alleviate discomfort and improve function. Early intervention can prevent the condition from worsening and promote faster recovery.
                    </p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                   <h1>What to Expect During Your Physiotherapy Sessions</h1>
                        <p>
                            Your comfort and recovery are our top priorities during your physiotherapy sessions. Our skilled physiotherapists will conduct a thorough assessment to understand your condition and develop a personalized treatment plan. During your sessions, you may receive manual therapy to reduce muscle tension, guided exercises to improve flexibility and strength, and advice on posture and ergonomics to support your recovery.
                        </p>
                        <p>
                            Have questions about the treatment process? Our team is here to guide you every step of the way. <a href="/contact">Contact us</a> to learn more.
                        </p>
                        <br />
                        <h1>Combining Therapies for Optimal Results</h1>
                        <p>
                            To maximize your recovery, we often combine various physiotherapy techniques tailored to your specific needs. This integrative approach addresses both immediate symptoms and underlying causes, promoting long-term relief and preventing future flare-ups.
                        </p>
                        <p>
                            Ready to enhance your recovery journey? <a href="/contact">Contact us</a> to develop a comprehensive treatment plan designed just for you.
                        </p>
                    </div>
                </div>
            </SkewedContainer>
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default SciaticaTreatment;
