import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './Contact.css';
import GoogleMapComponent from './GoogleMapComponent';
import mapImage from '../assets/map.png';
import { useNavigate } from 'react-router-dom';

const Contact = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: '',
        updates: false,
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const templateParams = {
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            phone: formData.phone,
            message: formData.message,
            updates: formData.updates,
        };

        emailjs.send('service_gvt5vwp', 'template_nwga3kh', templateParams, 'OpJMLUp7AgSF4zW4L')
            .then((response) => {
                alert('Form submitted successfully!');
                setFormData({
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '',
                    message: '',
                    updates: false,
                });
                navigate('/thankyou');
            }, (error) => {
                alert('There was an error submitting the form.');
                console.error('Failed to send email:', error);
            });
    };

    return (
        <div className="contact-page">
            <div className="contact-header-details-container">
                <div className="contact-header">
                    <h1>CONTACT US</h1>
                </div>
                <div className="contact-details">
                    <div className="contact-info">
                        <p>e: info@rampphysioandfitness.com.au</p>
                        <p>PH: (02) 8188 1618</p>
                    </div>
                    <div className="contact-address">
                        <p>208 Parramatta Road, Homebush,</p>
                        <p>NSW, AUSTRALIA 2140</p>
                    </div>
                </div>
            </div>
            <div className="contact-section">
                <div className="contact-form">
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="first-name">Name <span>(required)</span></label>
                            <div className="form-group-inline">
                                <input
                                    type="text"
                                    id="first-name"
                                    name="firstName"
                                    placeholder="First Name"
                                    value={formData.firstName}
                                    onChange={handleChange}
                                    required
                                />
                                <input
                                    type="text"
                                    id="last-name"
                                    name="lastName"
                                    placeholder="Last Name"
                                    value={formData.lastName}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Email <span>(required)</span></label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                placeholder="Email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="phone">Phone <span>(required)</span></label>
                            <input
                                type="tel"
                                id="phone"
                                name="phone"
                                placeholder="Phone Number"
                                value={formData.phone}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group-checkbox">
                            <input
                                type="checkbox"
                                id="updates"
                                name="updates"
                                checked={formData.updates}
                                onChange={handleChange}
                            />
                            <label htmlFor="updates">
                                <span className="checkbox-custom"></span>
                                Sign up for news and updates
                            </label>
                        </div>
                        <div className="form-group">
                            <label htmlFor="message">Message <span>(required)</span></label>
                            <textarea
                                id="message"
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                required
                            ></textarea>
                        </div>
                        <div className="button-container">
                            <button type="submit">Submit</button>
                        </div>
                    </form>
                </div>
                <div className="map-section">
                    <img src={mapImage} alt="Map" />
                </div>
                {/* Google Map is not working, so I've commented it out for now */}
                {/* <div className="map-section">
                    <GoogleMapComponent />
                </div> */}
            </div>
        </div>
    );
};

export default Contact;
