import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const EffectiveWeightLossWorkoutsAtTheGym = () => {
    return (
        <div>
            <Helmet>
                <title>Effective Weight Loss Workouts at the Gym | Ramp Physio and Fitness</title>
                <meta name="description" content="Discover the most effective gym workouts for weight loss with Ramp Physio and Fitness. Achieve your fitness goals with our expert guidance." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Maximize Fat Burn at the Gym</h1>
                    <h2>Unlock the secrets to effective weight loss workouts at Ramp Physio and Fitness, where our expert trainers tailor your gym experience to ensure maximum calorie burn and sustainable fitness results.</h2>
                    <p>Embarking on a weight loss journey can be challenging, but with the right workouts, you can achieve remarkable results. At Ramp Physio and Fitness, our goal is to guide you through effective gym workouts that not only help you shed pounds but also improve your overall health and fitness. This article will explore the top exercises and strategies for weight loss, combining expert insights with proven methods to help you succeed.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Strategies for Weight Loss</h1>
                        <p>Effective weight loss is not just about sweating it out at the gym; it's about adopting a strategic approach that combines cardiovascular exercises, strength training, and high-intensity interval training (HIIT). These three pillars form the foundation of a successful weight loss program, and when executed correctly, they can transform your body and health. Below, we'll dive into the specifics of each strategy and how they contribute to burning calories and building lean muscle mass.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Cardiovascular Endurance</h1>
                    <p>Cardio exercises are crucial for burning a significant number of calories. At Ramp Physio and Fitness, we recommend a mix of steady-state cardio and interval training to maximize fat loss. Treadmills, ellipticals, and stationary bikes are excellent tools for this purpose. Engaging in activities like running, cycling, or rowing for sustained periods can help elevate your heart rate and maintain it within the fat-burning zone.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Strength Training Impact</h1>
                    <p>Strength training is a powerful component of weight loss, as it builds muscle, which in turn increases your resting metabolic rate. Incorporating free weights, resistance bands, and bodyweight exercises can help you develop muscle strength and endurance. Our trainers at Ramp Physio and Fitness focus on compound movements like squats, deadlifts, and bench presses that target multiple muscle groups for a more efficient workout.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>The Power of HIIT</h1>
                    <p>High-Intensity Interval Training (HIIT) is a game-changer for weight loss. These short bursts of intense exercise followed by rest periods have been shown to burn more calories in less time compared to traditional cardio. HIIT also promotes the afterburn effect, where your body continues to burn calories long after your workout is over. At Ramp Physio and Fitness, we incorporate HIIT into our programs to help you achieve significant fat loss and cardiovascular benefits.</p>
                    <br></br>
                    <p>
                        In conclusion, effective weight loss workouts at the gym involve a combination of cardiovascular exercise, strength training, and HIIT. By focusing on these key strategies, you can maximize fat burn, build lean muscle, and achieve sustainable weight loss. Join us at Ramp Physio and Fitness to begin your transformation with the support of our expert team. Together, we'll work towards reaching your health and fitness goals.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default EffectiveWeightLossWorkoutsAtTheGym;