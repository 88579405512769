import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const NutritionTipsForVegetariansAndVegansInFitness = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Nutrition Mastery for Plant-Based Athletes</h1>
                    <h2>Unlock the power of plant-based nutrition with Ramp Physio and Fitness. Our comprehensive guide ensures vegetarians and vegans meet their fitness goals with tailored nutritional strategies.</h2>
                    <p>Adopting a vegetarian or vegan lifestyle can be incredibly beneficial for both your health and the environment. However, when combined with a fitness regimen, it's crucial to ensure your body gets all the nutrients it needs to perform at its best. At Ramp Physio and Fitness, we understand the unique challenges plant-based athletes face. That's why we've compiled expert nutrition tips to help vegetarians and vegans thrive in their fitness journeys.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Essential Nutrients for Peak Performance</h1>
                        <p>Plant-based diets can support high levels of physical activity, but it's important to focus on certain nutrients that might be less abundant in vegetarian and vegan diets. Here are the key nutrients you should pay attention to:</p>
                        <ul>
                            <li>Protein: Essential for muscle repair and growth. Include a variety of plant-based sources like lentils, tofu, and quinoa.</li>
                            <li>Iron: Crucial for oxygen transport. Opt for sources like spinach, legumes, and fortified cereals, and pair them with vitamin C-rich foods to enhance absorption.</li>
                            <li>Calcium: Important for bone health. Consume fortified plant milks, almonds, and leafy greens to meet your needs.</li>
                            <li>Vitamin B12: Vital for nerve function and energy production. Consider fortified foods or supplements as this vitamin is not naturally found in plant foods.</li>
                            <li>Omega-3 Fatty Acids: Beneficial for heart health and inflammation. Flaxseeds, chia seeds, and walnuts are great sources.</li>
                            <li>Zinc: Supports immune function and wound healing. Eat plenty of whole grains, nuts, and seeds.</li>
                        </ul>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Strategic Meal Planning for Training</h1>
                    <p>Meal planning is a powerful tool for ensuring you get the right balance of macronutrients and micronutrients in your diet. Here's how to create a meal plan that supports your training regimen:</p>
                    <ul>
                        <li>Pre-Workout: Opt for a carbohydrate-rich meal with moderate protein to fuel your session.</li>
                        <li>Post-Workout: Focus on protein and carbohydrates to repair muscles and replenish glycogen stores.</li>
                        <li>Hydration: Drink plenty of water throughout the day, and consider electrolyte-rich drinks after intense workouts.</li>
                        <li>Snacking: Choose nutrient-dense snacks like nuts, seeds, and fruit to keep energy levels up.</li>
                        <li>Timing: Align your meals and snacks with your training schedule to optimize performance and recovery.</li>
                        <li>Variety: Rotate your food choices to ensure a wide range of nutrients and prevent dietary boredom.</li>
                    </ul>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Supplementing Wisely for Deficiencies</h1>
                    <p>While a well-planned vegetarian or vegan diet can provide most of the nutrients you need, there may be times when supplements are necessary. Here are some considerations for supplementation:</p>
                    <ul>
                        <li>Vitamin B12: Since it's not available in plant foods, a B12 supplement is often recommended.</li>
                        <li>Iron: If your levels are low, an iron supplement might be necessary. Always consult with a healthcare provider before starting any supplement.</li>
                        <li>Vitamin D: Particularly important during the winter months or if you have limited sun exposure.</li>
                        <li>Algae-based Omega-3s: Consider these if you don't consume enough omega-3s through your diet.</li>
                        <li>Protein Powders: Can be a convenient way to ensure you're getting enough protein, especially for bodybuilders or those with high requirements.</li>
                    </ul>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Training Adaptations for Plant-Based Diets</h1>
                    <p>Adjusting your training to fit a vegetarian or vegan diet can enhance your performance and recovery. Here are some tips to adapt your training:</p>
                    <ul>
                        <li>Listen to Your Body: Pay attention to energy levels and recovery times, adjusting intensity as needed.</li>
                        <li>Progressive Overload: Gradually increase your training demands to continue making gains without overwhelming your body.</li>
                        <li>Rest and Recovery: Prioritize rest days and active recovery to allow your body to heal and adapt.</li>
                        <li>Cross-Training: Incorporate different types of workouts to improve overall fitness and prevent overuse injuries.</li>
                        <li>Mindful Eating: Be aware of your hunger cues and fuel up appropriately to support your training demands.</li>
                    </ul>
                    <br></br>
                    <p>
                        In conclusion, with the right approach to nutrition and training, vegetarians and vegans can achieve excellent fitness results. At Ramp Physio and Fitness, we're committed to providing you with the knowledge and support you need to succeed on a plant-based diet. Whether you're a competitive athlete or just looking to improve your health and fitness, we can help you optimize your nutrition for peak performance. Join us and experience the benefits of a well-fueled plant-based lifestyle.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NutritionTipsForVegetariansAndVegansInFitness;