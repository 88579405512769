import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const TrainingForEnduranceSportsAtTheGym = () => {
    return (
        <div>
            <Helmet>
                <title>Training for Endurance Sports at the Gym | Ramp Physio and Fitness</title>
                <meta name="description" content="Maximize your endurance sports performance with specialized gym training at Ramp Physio and Fitness. Tailored strength and conditioning programs for all levels." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Maximize Endurance with Gym Training</h1>
                    <h2>Unlock your full potential in endurance sports by integrating gym training into your routine. Discover the benefits at Ramp Physio and Fitness.</h2>
                    <p>Endurance athletes are often focused on the miles they run, the laps they swim, or the time they spend on the bike. However, incorporating gym training into their regimen is crucial for strength, stability, and overall performance. At Ramp Physio and Fitness, we understand the importance of a well-rounded approach to endurance sports training. Our expert team is here to guide you through strength and conditioning exercises that will enhance your endurance and prevent injuries. This article explores the key components of gym training for endurance athletes.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Strength Training for Endurance</h1>
                        <p>Integrating strength training into your endurance program can lead to significant improvements in performance. By focusing on compound movements and functional exercises, you can develop the power and stability needed for your sport. Here are 10 examples of exercises that are beneficial for endurance athletes:</p>
                        <ul>
                            <li>Deadlifts for posterior chain strength</li>
                            <li>Squats for leg power and core stability</li>
                            <li>Bench presses for upper body strength</li>
                            <li>Rows for back strength and posture</li>
                            <li>Planks for core endurance</li>
                            <li>Lunges for leg strength and balance</li>
                            <li>Step-ups for unilateral training</li>
                            <li>Push-ups for upper body and core integration</li>
                            <li>Pull-ups for grip and shoulder stability</li>
                            <li>Core rotations for functional strength</li>
                        </ul>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Periodization for Peak Performance</h1>
                    <p>Periodization is a systematic approach to training that involves varying your workout intensity and volume over specific periods. This method helps endurance athletes avoid plateaus, manage fatigue, and peak at the right time. At Ramp Physio and Fitness, we create personalized periodization plans to ensure you're progressing safely and effectively towards your goals.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Recovery and Injury Prevention</h1>
                    <p>Recovery is as crucial as the training itself. Incorporating rest days, proper nutrition, and recovery techniques like foam rolling and stretching can dramatically reduce the risk of injury. We educate our endurance athletes on the best practices for recovery to ensure they can train consistently and perform at their best.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Balance and Stability Workouts</h1>
                    <p>Balance and stability are fundamental for endurance athletes to maintain form and efficiency during long events. Our gym sessions include exercises that challenge your proprioception and coordination, leading to better movement control and reduced risk of falls or missteps during your endurance activities.</p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default TrainingForEnduranceSportsAtTheGym;