import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import physioLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const PhysiotherapyTreatmentAndExercisesForSoftTissueAndMuscleInjuries = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Physiotherapy for Soft Tissue and Muscle Injuries</h1>
                    <h2>Expert Strategies for Recovery and Rehabilitation at Ramp Physio and Fitness</h2>
                    <p>Soft tissue and muscle injuries are common issues that can severely impact your daily life and fitness routines. At Ramp Physio and Fitness in Homebush, NSW, our team of physiotherapists specializes in delivering personalized treatment plans and exercises designed to aid in the recovery and rehabilitation of these injuries. This comprehensive guide will delve into effective strategies to manage soft tissue and muscle injuries, helping you return to your peak performance.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Soft Tissue Injuries</h1>
                        <p>Soft tissue injuries encompass damage to muscles, ligaments, and tendons. These injuries can occur due to various reasons, including sports accidents, overuse, or sudden movements. The severity of these injuries can range from minor strains to complete tears, requiring different levels of intervention.</p>

                        <p>Our physiotherapists at Ramp Physio and Fitness begin with a thorough assessment to understand the extent of your injury. This initial evaluation is crucial in creating a targeted treatment plan that addresses your specific needs, promoting efficient recovery.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/dumbbells.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Effective Treatment Modalities</h1>
                    <p>Our approach to treating soft tissue and muscle injuries involves a combination of hands-on techniques and therapeutic exercises. Treatments may include manual therapy to reduce pain and inflammation, improve circulation, and facilitate movement.</p>

                    <p>Therapeutic exercises are then introduced to strengthen the affected area, prevent muscle atrophy, and restore function. These exercises are carefully selected and tailored to your recovery stage, ensuring that each movement contributes positively to your healing journey.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={physioLogo} alt="Physio Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>

            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Progressive Exercise Programs</h1>
                    <p>As you advance through your recovery, our physiotherapists will progressively increase the intensity and complexity of your exercises. This gradual progression helps build resilience in the soft tissues, enhancing your strength and flexibility without overloading the healing structures.</p>

                    <p>Key components of our exercise programs include range of motion exercises, strength training, and functional movements that simulate your daily activities or sports-specific tasks, ensuring a well-rounded and effective rehabilitation process.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Pinloaded.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Rehabilitation and Beyond</h1>
                        <p>Our commitment to your recovery extends beyond the walls of our clinic. We empower you with education on injury prevention and self-management techniques to maintain and improve your physical well-being in the long term.</p>

                        <p>Our team is dedicated to ensuring that you not only return to your pre-injury state but also gain the knowledge and strength to reduce the risk of future injuries. At Ramp Physio and Fitness, we are your partners in health, supporting you every step of the way.</p>

                        <br></br>
                        <p>
                            In summary, our expert physiotherapy treatment and exercise programs for soft tissue and muscle injuries are designed to provide you with the highest standard of care. With a focus on personalized treatment, evidence-based practices, and a holistic approach to rehabilitation, we at Ramp Physio and Fitness are committed to helping you achieve optimal recovery and performance.
                        </p>
                    </div>
                </div>
            </SkewedContainer>
            <SubpagesLink />

            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default PhysiotherapyTreatmentAndExercisesForSoftTissueAndMuscleInjuries;