import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const PhysiotherapyForHeelSpurs = () => {
    return (
        <div>
            <Helmet>
                <title>Effective Physiotherapy for Heel Spurs | Ramp Physio and Fitness</title>
                <meta name="description" content="Alleviate pain from heel spurs with targeted physiotherapy treatments at Ramp Physio and Fitness. Discover exercises and personalized care plans to improve your condition." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Alleviate Heel Spur Discomfort</h1>
                    <h2>Explore personalized physiotherapy treatments for heel spurs at Ramp Physio and Fitness, tailored to reduce pain and enhance mobility for a better quality of life.</h2>
                    <p>Heel spurs can be a source of persistent pain and discomfort, affecting your daily activities and overall quality of life. At Ramp Physio and Fitness, we specialize in offering effective physiotherapy treatments designed to target the root cause of heel spurs, providing relief and preventing recurrence. In this comprehensive guide, we'll explore the benefits of physiotherapy for heel spurs, including exercises and strategies to manage and mitigate the pain associated with this condition.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Heel Spurs</h1>
                        <p>Heel spurs are bony growths that develop on the underside of the heel bone, often associated with plantar fasciitis. These growths can cause sharp, intense pain, especially during the first steps in the morning or after periods of rest. Understanding the condition is the first step towards effective treatment, and our physiotherapists are adept at diagnosing and creating tailored management plans that address both symptoms and underlying causes.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Personalized Care Plans</h1>
                    <p>Your journey to recovery from heel spurs is unique, and so should be your treatment plan. At Ramp Physio and Fitness, we develop personalized care plans that include a combination of manual therapy, exercises, and education to help you understand your condition and how to manage it effectively. Our goal is to not only alleviate pain but also to empower you with the knowledge and tools needed for long-term relief.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Targeted Exercise Regimens</h1>
                    <p>Targeted exercises are integral to the treatment of heel spurs. We guide you through specific stretches and strengthening exercises aimed at reducing tension in the plantar fascia and surrounding muscles. These exercises not only help in providing symptomatic relief but also address the biomechanical factors contributing to heel spur formation, thereby preventing future occurrences.</p>
                    <ul>
                        <li>Calf stretches against the wall</li>
                        <li>Seated foot flexes with resistance band</li>
                        <li>Towel curls to strengthen foot muscles</li>
                        <li>Rolling a ball under the foot for massage</li>
                        <li>Stair heel raises and drops</li>
                        <li>Isometric toe presses</li>
                        <li>Standing plantar fascia stretch</li>
                        <li>Cross-leg ankle stretch</li>
                        <li>Toe extension and splay</li>
                        <li>Marble pickups with toes for dexterity</li>
                    </ul>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Advanced Treatment Options</h1>
                    <p>At Ramp Physio and Fitness, we stay abreast of the latest advancements in physiotherapy to offer you the most effective treatment options. Shockwave therapy, ultrasound, and taping techniques are some of the advanced modalities we may employ to facilitate healing and provide relief from heel spur pain. Our physiotherapists will discuss the most appropriate options for your specific condition during your consultation.</p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default PhysiotherapyForHeelSpurs;