import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const NDIS = () => {
    return (
        <div>
{/* While we test GTM for now, we have removed the Google Analytics code from here */}

            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Navigating Your Rights Under Australian Consumer Law at Ramp Physio and Fitness</h1>
                    <h2>This article provides an insightful guide for clients of Ramp Physio and Fitness, outlining their protections and entitlements under Australian Consumer Law when engaging with healthcare and fitness services.</h2>
                    <p>Welcome to Ramp Physio and Fitness, where we pride ourselves on delivering exceptional sports injury management, physiotherapy, and personalized training programs. As healthcare professionals and service providers, we recognize the importance of not only offering quality care but also ensuring that our practices align with the Australian Consumer Law (ACL). Understanding your rights as a consumer can empower you to make informed decisions and recognize when you are entitled to certain protections. This article will delve into the key aspects of the ACL relevant to our services, providing you with the knowledge to navigate your healthcare journey with confidence.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Your Consumer Rights</h1>
                        <p>The ACL provides a national, statutory framework to protect consumers and ensure fair trading across Australia. As a client at Ramp Physio and Fitness, you are entitled to certain guarantees when purchasing goods and services. These include the right to receive services that are:</p>
                        <p>- Provided with due care and skill</p>
                        <p>- Fit for any specified purpose</p>
                        <p>- Delivered within a reasonable time frame</p>
                        <p>It is our commitment to uphold these standards, ensuring that you receive the high-quality care and fitness guidance that you deserve.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Guarantees and Remedies in Healthcare</h1>
                    <p>When engaging with healthcare services like physiotherapy, it's crucial to understand the guarantees that are in place. If a service fails to meet the expectations set by the ACL, you may be entitled to a remedy. This could range from a repeat service to correct the issue or a partial or full refund, depending on the nature of the problem. At Ramp Physio and Fitness, we strive to provide effective solutions and are dedicated to resolving any concerns swiftly and fairly.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>

            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Consumer Protection and Fitness Services</h1>
                    <p>Fitness services, including personalized training and group classes, are also covered under the ACL. As a consumer, you have the right to expect that our fitness programs are safe, accurately described, and delivered by qualified professionals. In the event that a service is not provided with reasonable care or doesn't meet the descriptions advertised, we are here to work with you to find an appropriate solution.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Chronic Pain Management and ACL</h1>
                        <p>Managing chronic pain requires a nuanced approach, and at Ramp Physio and Fitness, we tailor our services to meet your individual needs. Under the ACL, you can expect that any therapeutic goods or services you receive for chronic pain management are of acceptable quality and match their description. We are committed to transparency and honesty in our communications, providing you with the assurance that your care plan is in line with consumer guarantees.</p>
                        <br></br>
                        <p>Ramp Physio and Fitness is not only dedicated to providing top-tier healthcare and fitness services but also to ensuring that our practices comply with the Australian Consumer Law. By understanding your rights and our obligations, you can engage with our services confidently, knowing that your well-being is protected by law. Should you ever have any concerns or need further clarification, our team is always here to support and guide you. Remember, your health and satisfaction are at the heart of what we do, and we are committed to upholding the standards that empower you as a consumer.</p>
                    </div>
                </div>
            </SkewedContainer>
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NDIS;
