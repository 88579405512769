import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';
const NDIS = () => {
    return (
        <div>
{/* While we test GTM for now, we have removed the Google Analytics code from here */}
            <div className="service-detail-content">
                <div className="service-detail-text">
                <br></br><br></br>
                    <h1>Remedial Massage</h1>
                    <h2>Experience Relief and Relaxation with Remedial Massage Therapy</h2>
                    <p>At Ramp Physio and Fitness, we provide expert remedial massage therapy to help you relieve pain, reduce stress, and enhance your overall well-being. Our skilled therapists use targeted techniques to treat musculoskeletal issues, alleviate tension, and promote relaxation. Whether you’re dealing with chronic pain, recovering from an injury, or simply need to unwind, remedial massage therapy offers a therapeutic solution that’s tailored to your specific needs.</p>
                    <p>Ready to experience the benefits of remedial massage therapy? Contact us today to book your appointment and start your journey to relief and relaxation.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Discover the Therapeutic Benefits of Remedial Massage</h1>
                        <p>Remedial massage therapy offers a wide range of benefits, including pain relief, improved circulation, and enhanced flexibility. It’s particularly effective for treating conditions such as muscle tension, sports injuries, and postural imbalances. At Ramp Physio and Fitness, we apply remedial massage techniques to target problem areas, relieve discomfort, and support your body’s natural healing processes. Whether you’re looking to recover from an injury or manage chronic pain, remedial massage can provide significant relief.</p>
                        <p>Suffering from pain or tension? Contact us to learn how remedial massage therapy can help you recover and feel your best.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Personalized Remedial Massage Treatments for Optimal Results</h1>
                    <p>At Ramp Physio and Fitness, we believe in a personalized approach to remedial massage therapy. Our therapists take the time to assess your individual needs and create a tailored treatment plan that addresses your specific concerns. Whether you need deep tissue work to relieve muscle knots or a gentler approach to reduce stress, we ensure that your massage therapy sessions are customized for maximum effectiveness and comfort.</p>
                    <p>Looking for a massage theapy session that's customized to your needs? Contact us to book your personalized remedial massage and strat feeling better today.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Success Stories: Healing and Relaxation with Remedial Massage</h1>
                    <p>Our clients have experienced remarkable improvements in pain relief, relaxation, and overall well-being through our remedial massage therapy. From easing chronic back pain to recovering from sports injuries, our clients’ success stories highlight the transformative impact of our therapeutic approach. Read their testimonials to see how Ramp Physio and Fitness has helped others achieve lasting relief and relaxation with remedial massage therapy.</p>
                    <p>Inspired by our client's experiences? Start your own journey to healing and relaxation with our expert remedial massage therapy. Contact us today to book your appointment and feel the difference.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="white" noMargin>
            <HealthSection />
            </SkewedContainer>
            <TestimonialSection />
        </div>
    );
};

export default NDIS;
