import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const PhysiotherapyForOsteoporosis = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Empowering Bone Health</h1>
                    <h2>Enhance your bone strength with our specialized Physiotherapy for Osteoporosis at Ramp Physio and Fitness, and take control of your skeletal health today.</h2>
                    <p>Osteoporosis is a condition that weakens bones, making them fragile and more likely to break. At Ramp Physio and Fitness, we understand the importance of bone health and offer comprehensive physiotherapy services to manage and prevent osteoporosis. Our expert physiotherapists are dedicated to helping you strengthen your bones, improve balance, and reduce the risk of fractures. In this article, we'll explore the transformative power of physiotherapy in managing osteoporosis and how our tailored approach can make a significant difference in your life.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Osteoporosis</h1>
                        <p>Osteoporosis is often called the 'silent disease' because it can progress without symptoms until a fracture occurs. Understanding the impact of this condition on your body is the first step in taking proactive measures. Our physiotherapists at Ramp Physio and Fitness are equipped with the knowledge to educate you on bone density, risk factors, and the importance of early intervention. We believe that informed clients are empowered to make better health decisions, and we're here to guide you every step of the way.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Customized Treatment Plans</h1>
                    <p>Every individual is unique, and so is their response to osteoporosis. At Ramp Physio and Fitness, we design customized treatment plans that address your specific needs. Our physiotherapy programs for osteoporosis focus on exercises that promote bone strength, improve posture, and enhance balance. We combine evidence-based techniques with the latest research to offer you a regimen that not only targets osteoporosis symptoms but also fits into your lifestyle.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Exercise: A Pillar of Prevention</h1>
                    <p>Exercise is a cornerstone of osteoporosis management. The right type of activity can help build and maintain bone density, reduce the likelihood of falls, and prevent fractures. Our physiotherapists at Ramp Physio and Fitness are adept at creating exercise programs that include weight-bearing, resistance training, and balance activities, all essential for bone health. We ensure these exercises are safe, effective, and enjoyable, empowering you to incorporate them into your daily routine for long-term benefits.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Embracing a Bone-Healthy Lifestyle</h1>
                        <p>Physiotherapy for osteoporosis extends beyond exercise. At Ramp Physio and Fitness, we advocate for a holistic approach that includes nutrition advice, lifestyle modifications, and strategies to protect your bones during daily activities. We work with you to embrace a bone-healthy lifestyle, providing support and guidance to make sustainable changes that contribute to your overall well-being and bone strength.</p>
                        <br></br>
                        <p>
                            In conclusion, physiotherapy for osteoporosis is a powerful tool in managing and preventing this condition. Our team at Ramp Physio and Fitness is committed to delivering personalized care that empowers you to take charge of your bone health. With our expert guidance and your dedication, you can strengthen your bones, prevent fractures, and lead a fulfilling, active life. Reach out to us to start your journey towards a stronger, healthier you.
                        </p>
                    </div>
                </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default PhysiotherapyForOsteoporosis;