import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const ManagingMultipleSclerosisSymptomsWithPhysiotherapy = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Empower Your Wellness Journey</h1>
                    <h2>Explore the transformative impact of physiotherapy on managing Multiple Sclerosis symptoms at Ramp Physio and Fitness, where our tailored approaches pave the way for resilience and improved quality of life.</h2>
                    <p>Multiple Sclerosis (MS) presents a unique set of challenges, but with the right physiotherapy interventions, individuals can enhance their physical capabilities and manage symptoms effectively. At Ramp Physio and Fitness, we are committed to empowering our clients by providing expert physiotherapy services designed to address the specific needs of those living with MS. This article will guide you through the benefits of physiotherapy for MS and how our dedicated team can support your journey towards better health and wellness.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Customized MS Physiotherapy</h1>
                        <p>Our physiotherapists at Ramp Physio and Fitness understand that each case of MS is unique. We create customized physiotherapy programs that focus on improving mobility, balance, and strength, which are often affected by MS. Our evidence-based practices ensure that you receive the most effective treatment to manage your symptoms and maintain an active lifestyle.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Enhancing Mobility and Strength</h1>
                    <p>MS can lead to decreased mobility and muscle weakness, but with targeted physiotherapy, you can combat these issues. Our programs include strength training, stretching exercises, and aerobic conditioning tailored to your abilities and goals, all aimed at enhancing your overall mobility and muscle strength.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Understanding MS and Physiotherapy</h1>
                    <p>Learning about Multiple Sclerosis and the role of physiotherapy is crucial for effective management. Physiotherapy can help alleviate symptoms such as fatigue, spasticity, and coordination issues, providing you with strategies to manage daily activities with greater ease and confidence.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Balancing Act: Coordination and Stability</h1>
                    <p>Balance and coordination are often compromised in individuals with MS. Our physiotherapy sessions include balance training and proprioceptive exercises, which help improve stability and reduce the risk of falls, thereby enhancing your independence and safety.</p>
                </div>
            </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="service-intro-content2">
                    <h1>Strategies for Fatigue Management</h1>
                    <ul>
                        <li>Energy conservation techniques</li>
                        <li>Restorative strecthing and mindfulness</li>
                        <li>Adaptive equipment education</li>
                        <li>Guided aerobic exercise</li>
                        <li>Customized activity pacing</li>
                        <li>Workplace ergonomic assessments</li>
                        <li>Heat management strategies</li>
                        <li>Nutritional advice for energy levels</li>
                        <li>Pain management approaches</li>
                    </ul>
                    <p>Fatigue is a common symptom in MS and can be debilitating. Our physiotherapists offer strategies to manage fatigue, allowing you to participate in daily activities with improved energy levels.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <h1>Comprehensive MS Care</h1>
                    <p>At Ramp Physio and Fitness, we believe in a multidisciplinary approach to MS care. We work closely with neurologists, occupational therapists, and other healthcare professionals to provide comprehensive care that addresses all aspects of living with MS.</p>
                </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default ManagingMultipleSclerosisSymptomsWithPhysiotherapy;