import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const PhysiotherapyForPelvicPain = () => {
    return (
        <div>
            <Helmet>
                <title>Physiotherapy for Pelvic Pain | Ramp Physio and Fitness</title>
                <meta name="description" content="Expert physiotherapy for pelvic pain relief at Ramp Physio and Fitness. Tailored treatments to alleviate pain and improve your quality of life." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Comprehensive Care for Pelvic Pain</h1>
                    <h2>Explore the transformative power of physiotherapy for pelvic pain at Ramp Physio and Fitness, where personalized treatment meets exceptional care.</h2>
                    <p>Pelvic pain can be a debilitating condition affecting every aspect of life, but with the right approach, it can be managed effectively. Ramp Physio and Fitness is dedicated to providing specialized physiotherapy for pelvic pain, empowering you to regain control and improve your wellbeing. Our expert team utilizes evidence-based techniques to address the root causes of pelvic discomfort and foster lasting relief.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Pelvic Pain</h1>
                        <p>Pelvic pain can arise from various conditions such as endometriosis, pelvic inflammatory disease, interstitial cystitis, or musculoskeletal issues. It may manifest as a sharp, stabbing sensation or a dull, constant ache. Physiotherapy offers a non-invasive path to alleviating this pain by addressing the musculoskeletal components and improving pelvic floor function.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Personalized Treatment Plans</h1>
                    <p>Each individual’s experience with pelvic pain is unique, which is why our physiotherapists create personalized treatment plans. These plans may include manual therapy, pelvic floor exercises, education on pain management, and lifestyle modifications to support recovery and prevent recurrence.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Techniques for Pain Relief</h1>
                    <ul>
                        <li>Manual therapy to reduce muscle tension</li>
                        <li>Trigger point release for tight muscles</li>
                        <li>Pelvic floor relaxation and strengthening</li>
                        <li>Myofascial release for tissue mobility</li>
                        <li>Biofeedback for muscle control</li>
                        <li>Pain education to understand and manage symptoms</li>
                        <li>Stress management and relaxation techniques</li>
                        <li>Exercise therapy for core stability</li>
                        <li>Postural advice and ergonomic assessments</li>
                        <li>Bladder and bowel habit training</li>
                    </ul>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Enhancing Quality of Life</h1>
                    <p>Our goal at Ramp Physio and Fitness is not just to relieve your pelvic pain, but to enhance your overall quality of life. Through comprehensive physiotherapy, we aim to restore your physical function, reduce pain, and give you the tools to maintain your health and prevent future issues.</p>
                    <br></br>
                    <p>
                        In conclusion, physiotherapy for pelvic pain is an effective and empowering approach to managing and overcoming this challenging condition. At Ramp Physio and Fitness, we are committed to guiding you on your journey to recovery with our expert care and personalized treatment strategies. Regain your freedom from pelvic pain and embrace a healthier, more comfortable life with our support.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default PhysiotherapyForPelvicPain;