import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const NdisPhysiotherapyForSchizophrenia = () => {
    return (
        <div>
            <Helmet>
                <title>NDIS Physiotherapy for Schizophrenia | Ramp Physio and Fitness</title>
                <meta name="description" content="Discover the benefits of NDIS physiotherapy for individuals with schizophrenia at Ramp Physio and Fitness. Tailored support for improved mental and physical health." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Empowering Schizophrenia Care through NDIS Physiotherapy</h1>
                    <h2>At Ramp Physio and Fitness, we provide specialized NDIS physiotherapy services to support individuals with schizophrenia in achieving their health and mobility goals.</h2>
                    <p>Schizophrenia can present unique challenges in terms of physical health and well-being. Ramp Physio and Fitness is committed to supporting individuals with schizophrenia through expert NDIS physiotherapy services. Our program is designed to enhance mobility, strength, and overall fitness, while also contributing to better mental health outcomes. In this article, we will delve into the advantages of physiotherapy for schizophrenia and how our tailored approach can make a significant difference in the lives of those we serve.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Schizophrenia and Movement</h1>
                        <p>Schizophrenia is a complex condition that can affect movement and coordination. Physical health issues, such as decreased mobility and sedentary lifestyles, are common among those with schizophrenia. NDIS physiotherapy aims to address these challenges by providing individualized exercise programs and movement strategies to improve quality of life.</p>

                        <p>Our physiotherapists at Ramp Physio and Fitness begin with an assessment to understand the specific needs of each person. We focus on developing a plan that not only targets physical health but also considers the psychosocial aspects of schizophrenia, ensuring a holistic treatment approach.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/dumbbells.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Personalized NDIS Physiotherapy Plans</h1>
                    <p>Our NDIS physiotherapy for schizophrenia is not a one-size-fits-all solution. We craft personalized plans that consider the individual's symptoms, medication side effects, and personal goals. Activities may include strength training, aerobic exercises, and balance work, all aimed at enhancing daily functioning and independence.</p>

                    <p>The benefits of these programs extend beyond physical health, potentially reducing symptoms of schizophrenia and improving mental well-being. Our physiotherapists work closely with other healthcare providers to ensure a coordinated and comprehensive approach to care.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>

            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Enhancing Mobility and Reducing Falls</h1>
                    <p>One of the primary goals of NDIS physiotherapy for schizophrenia is to enhance mobility and reduce the risk of falls. Our targeted interventions focus on improving balance, coordination, and strength, which are critical for daily activities and preventing injuries.</p>

                    <p>With consistent physiotherapy sessions, individuals can experience an increase in confidence in their movement, leading to a more active and engaged lifestyle. We aim to empower our clients by teaching them how to safely navigate their environments, both at home and in the community.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Pinloaded.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Strengthening Mental-Physical Connections</h1>
                    <p>At Ramp Physio and Fitness, we recognize the strong connection between mental and physical health. Our NDIS physiotherapy programs are designed to not only improve physical capabilities but also to help manage stress, anxiety, and other symptoms associated with schizophrenia.</p>
                    <p>By engaging in regular physical activity, individuals may experience improvements in mood, sleep, and overall mental health. Our physiotherapists are trained to provide support and motivation, helping clients to integrate exercise into their daily routines as a tool for managing their condition.</p>

                    <br></br>
                    <p>
                        In conclusion, NDIS physiotherapy for schizophrenia is a powerful approach to support individuals in overcoming the physical challenges associated with the condition. Ramp Physio and Fitness is dedicated to providing compassionate, evidence-based care, empowering our clients to achieve their fullest potential. If you or someone you know could benefit from our services, we encourage you to reach out and book a session with our team.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            <SubpagesLink />
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NdisPhysiotherapyForSchizophrenia;