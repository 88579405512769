import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const TheBenefitsOfResistanceBandTraining = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Maximize Your Fitness</h1>
                    <h2>Unlock the full potential of resistance band training at Ramp Physio and Fitness, where we integrate versatile workout tools to enhance your strength, flexibility, and overall health.</h2>
                    <p>Resistance band training is an adaptable and effective way to boost your fitness routine. Ramp Physio and Fitness in Homebush, NSW, is dedicated to incorporating this simple yet powerful tool into your workouts. Whether you're rehabilitating from an injury, looking to increase muscular strength, or wanting to improve your athletic performance, resistance bands can provide a multitude of benefits. In this comprehensive guide, we'll explore the advantages of resistance band training and how it can be seamlessly integrated into your fitness regimen.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Strength Without Limits</h1>
                        <p>Resistance bands provide a unique challenge to your muscles, promoting strength gains without the need for heavy weights. They're perfect for all fitness levels and can be used for a wide range of exercises. Here are the top benefits of resistance band training:</p>
                        <ul>
                            <li>Adaptable for multiple fitness levels</li>
                            <li>Can be used for a variety of exercises</li>
                            <li>Lightweight and portable</li>
                            <li>Cost-effective workout tool</li>
                            <li>Reduces the risk of injury</li>
                            <li>Improves coordination and balance</li>
                            <li>Enhances strength and flexibility</li>
                            <li>Can be combined with other equipment</li>
                            <li>Offers variable resistance</li>
                            <li>Supports full-body workouts</li>
                        </ul>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Flexibility and Recovery</h1>
                    <br></br>
                    <p>Resistance bands are not just for building muscle; they're also an excellent tool for stretching and rehabilitation. The bands allow for controlled and gentle movements that can improve your range of motion and accelerate the recovery process. Here's how resistance bands can aid in flexibility and recovery:</p>
                    <ul>
                        <li>Assists with dynamic stretching</li>
                        <li>Supports muscle recovery post-injury</li>
                        <li>Facilitates controlled strength training</li>
                        <li>Helps maintain muscle length and health</li>
                        <li>Can be used for physical therapy exercises</li>
                    </ul>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Convenience and Portability</h1>
                    <p>One of the greatest advantages of resistance bands is their convenience. They are lightweight, easy to store, and perfect for those who travel or have limited space at home. Here's why resistance bands are the ultimate convenient workout tool:</p>
                    <ul>
                        <li>Easy to pack and take anywhere</li>
                        <li>Ideal for home workouts</li>
                        <li>Requires minimal space to use</li>
                        <li>Quick to set up for a workout</li>
                        <li>Suitable for outdoor workouts</li>
                    </ul>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Training Versatility</h1>
                    <p>Resistance bands are incredibly versatile, allowing you to perform exercises for every major muscle group. From upper body to lower body, core workouts to full-body circuits, resistance bands cater to all. Here's how you can use resistance bands to target different areas:</p>
                    <ul>
                        <li>Upper body exercises like bicep curls and shoulder presses</li>
                        <li>Lower body workouts such as squats and lunges</li>
                        <li>Core strengthening moves like planks and rotations</li>
                        <li>Full-body circuits for a comprehensive workout</li>
                        <li>Functional training to improve daily activities</li>
                    </ul>
                    <br></br>
                    <p>
                        In conclusion, resistance band training is a versatile, effective, and accessible way to enhance your fitness. At Ramp Physio and Fitness, we're committed to integrating these powerful tools into your personalized fitness plan. Whether you're a gym enthusiast or someone who prefers to exercise at home, resistance bands can help you achieve your health and fitness goals. Join us and discover the transformative power of resistance band training in your workout routine.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default TheBenefitsOfResistanceBandTraining;