import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const NdisPhysiotherapyTrainingCapabilityInHandlingEverydayActionsAndTasks = () => {
    return (
        <div>
            <Helmet>
                <title>NDIS Physiotherapy Training | Ramp Physio and Fitness</title>
                <meta name="description" content="Unlock your potential with NDIS Physiotherapy Training at Ramp Physio and Fitness. Enhance your daily living capabilities with our expert team." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Enhancing Daily Life with NDIS Physiotherapy Training</h1>
                    <h2>Discover how NDIS Physiotherapy Training at Ramp Physio and Fitness can transform your capacity for everyday activities and tasks.</h2>
                    <p>At Ramp Physio and Fitness, we specialize in NDIS Physiotherapy Training, focusing on empowering our clients to gain greater control over their daily lives. Our dedicated team of professionals is committed to delivering personalized programs that enhance the functional capacity of individuals with various needs. Through a combination of expert physiotherapy and training techniques, we help our clients achieve independence and improve their quality of life.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding NDIS and Physiotherapy</h1>
                        <p>The National Disability Insurance Scheme (NDIS) provides support for Australians with a disability, ensuring they have the necessary resources to live an enriched life. Physiotherapy plays a crucial role in the NDIS, offering therapeutic interventions that assist in managing disabilities and improving functional capabilities.</p>
                        <p>At Ramp Physio and Fitness, our NDIS Physiotherapy Training is designed to align with the goals outlined in your NDIS plan. We work closely with you to create a customized training regimen that addresses your specific needs, fostering autonomy in daily tasks and activities.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/dumbbells.webp" alt="Exercise" />
                    </div>
                </div>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/massage.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Personalized Training for Everyday Actions</h1>
                        <p>Our approach to NDIS Physiotherapy Training is holistic and person-centered. We recognize that every individual has unique challenges and goals. Our programs are tailored to help you master the everyday actions that matter most to you, whether it's mobility, self-care, or community participation.</p>
                        <p>By focusing on your capacity and building upon it, we aim to enhance your independence. This may involve manual handling techniques to improve movement and reduce the risk of injury, as well as targeted exercises that promote strength, balance, and coordination.</p>
                    </div>
                </div>
            </SkewedContainer>

            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>

            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Maximizing Potential with Specialized Programs</h1>
                    <p>Our specialized NDIS Physiotherapy Training programs are designed to maximize your physical potential. We incorporate evidence-based practices and the latest techniques to ensure you are receiving the highest quality care.</p>
                    <p>From mobility aids to therapeutic exercises, our physiotherapists are equipped with the knowledge and tools to help you navigate through your daily life with confidence and ease.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">  
                <div className="intro-image">
                    <img src="../assets/Pinloaded.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Manual Handling Outcomes and Training</h1>
                    <p>Effective manual handling is essential for safety and independence. Our training programs focus on techniques that enhance your ability to perform tasks safely, whether it's transferring from a wheelchair or lifting objects correctly.</p>
                    <p>These outcomes are not only crucial for your well-being but also for the caregivers and support workers who assist you. By learning and practicing proper manual handling, the risk of injury is significantly reduced for everyone involved.</p>
                </div>
            </div>
            </SkewedContainer>
            <SubpagesLink />

            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NdisPhysiotherapyTrainingCapabilityInHandlingEverydayActionsAndTasks;