import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const RehabilitationStrategiesForSpinalStenosis = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Mastering Spinal Stenosis Rehabilitation</h1>
                    <h2>Unlock your pathway to improved mobility and reduced pain with our evidence-based rehabilitation strategies for spinal stenosis at Ramp Physio and Fitness.</h2>
                    <p>Spinal stenosis can be a challenging condition, causing pain and limiting mobility. At Ramp Physio and Fitness, we understand the complexities of spinal stenosis and are dedicated to providing you with the latest and most effective rehabilitation strategies. Our expert team offers personalized care tailored to your unique needs, helping you regain function and enhance your quality of life. This article will explore the various rehabilitation techniques and exercises designed to alleviate the symptoms of spinal stenosis.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Spinal Stenosis</h1>
                        <p>Spinal stenosis occurs when the spaces within your spine narrow, putting pressure on the nerves. This can lead to symptoms such as pain, numbness, and muscle weakness. While it's most commonly seen in the lower back (lumbar spinal stenosis), it can also occur in the neck (cervical spinal stenosis). Our rehabilitation program is designed to address both types and provide you with relief and recovery.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Personalized Treatment Plans</h1>
                    <p>Every individual's experience with spinal stenosis is unique, which is why we craft personalized treatment plans. Our physiotherapists conduct thorough assessments to determine the best course of action for your specific condition, incorporating various techniques such as manual therapy, targeted exercises, and education on pain management.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Effective Exercise Strategies</h1>
                    <p>Exercise is a cornerstone of spinal stenosis rehabilitation. Our physiotherapists guide you through specific movements designed to increase flexibility, improve strength, and enhance endurance. These exercises are vital for maintaining spinal health and preventing further narrowing of the spinal spaces.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Integrating Holistic Approaches</h1>
                    <p>Our approach to spinal stenosis rehabilitation also includes holistic strategies such as lifestyle modifications, ergonomic advice, and stress reduction techniques. These elements are essential for managing symptoms and improving overall well-being.</p>
                    <br></br>
                    <p>
                        Spinal stenosis doesn't have to define your life. With the right rehabilitation strategies, you can take control of your symptoms and enjoy a more active, pain-free lifestyle. Our team at Ramp Physio and Fitness is here to support you every step of the way. Book your appointment today and embark on your journey to recovery.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default RehabilitationStrategiesForSpinalStenosis;