import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const UnderstandingAndTreatingPlantarFasciitis = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Understanding and Treating Plantar Fasciitis</h1>
                    <h2>Embark on a journey to relief and recovery from plantar fasciitis with Ramp Physio and Fitness, your trusted partner in managing foot pain and enhancing mobility.</h2>
                    <p>Plantar fasciitis is a common condition that causes heel pain, often characterized by a stabbing sensation that typically occurs with your first steps in the morning. At Ramp Physio and Fitness, we understand the impact this condition can have on your daily life and are dedicated to providing effective treatment options tailored to your individual needs. This comprehensive guide will explore the causes, symptoms, and treatments for plantar fasciitis, empowering you to take the first step towards a pain-free lifestyle.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Plantar Fasciitis Explained</h1>
                        <p>Plantar fasciitis is an inflammation of the plantar fascia, a thick band of tissue that runs across the bottom of your foot and connects your heel bone to your toes. It's often caused by repetitive strain injury to the ligament of the sole of the foot. Such strain injury can be from excessive running or walking, inadequate foot gear, or a heavy load on the foot. Here are ten key points to remember about plantar fasciitis:</p>
                        <ul>
                            <li>Most common cause of heel pain</li>
                            <li>Affects the plantar fascia ligament</li>
                            <li>Can be caused by overuse or foot structure issues</li>
                            <li>Typically felt during the first steps after waking up</li>
                            <li>Pain can also trigger after long periods of standing</li>
                            <li>More common in runners and overweight individuals</li>
                            <li>Can lead to chronic heel pain if left untreated</li>
                            <li>Stretching and strengthening exercises can provide relief</li>
                            <li>Proper footwear is essential for prevention and treatment</li>
                            <li>Physiotherapy is a proven approach to manage and treat plantar fasciitis</li>
                        </ul>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Effective Treatment Strategies</h1>
                    <p>Managing plantar fasciitis involves a combination of professional healthcare guidance and self-care strategies. Our team at Ramp Physio and Fitness offers personalized treatment plans that may include physiotherapy, exercises, and advice on proper footwear. Here are some effective treatment strategies:</p>
                    <ul>
                        <li>Rest and ice to reduce inflammation</li>
                        <li>Stretching exercises for the Achilles tendon and plantar fascia</li>
                        <li>Strengthening exercises to improve foot muscle balance</li>
                        <li>Orthotics or shoe inserts to support the arch of the foot</li>
                        <li>Night splints to stretch the plantar fascia overnight</li>
                        <li>Physical therapy to provide targeted treatments and exercises</li>
                        <li>Anti-inflammatory medications for pain relief</li>
                        <li>Cortisone injections for severe cases</li>
                        <li>Shock wave therapy or surgery as last resort options</li>
                        <li>Proper footwear with adequate support and cushioning</li>
                    </ul>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Prevention is Key</h1>
                    <p>Preventing plantar fasciitis is crucial, especially for individuals who are at higher risk. Here are some tips to prevent plantar fasciitis:</p>
                    <ul>
                        <li>Maintain a healthy weight to reduce stress on your plantar fascia</li>
                        <li>Choose supportive shoes and avoid high heels</li>
                        <li>Use custom orthotics if recommended by a healthcare professional</li>
                        <li>Don't wear worn-out athletic shoes</li>
                        <li>Change your sport to one that puts less stress on the plantar fascia</li>
                        <li>Apply ice after exercise if you're prone to plantar fasciitis</li>
                        <li>Gradually increase the intensity of any new sports activity</li>
                        <li>Stretch your arches, Achilles tendons, and calf muscles regularly</li>
                        <li>Strengthen your foot muscles with exercises</li>
                        <li>Ensure proper technique and posture when exercising</li>
                    </ul>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Join Us for Holistic Care</h1>
                    <p>At Ramp Physio and Fitness, we believe in taking a holistic approach to treating plantar fasciitis. We offer a range of services that complement each other to provide you with the best possible care. From personalized physiotherapy sessions to guided exercises and advice on lifestyle changes, we are dedicated to helping you overcome plantar fasciitis and improve your overall health and well-being. Book an appointment with us today and take the first step towards a pain-free life.</p>
                    <br></br>
                    <p>
                        In conclusion, with the right combination of treatment, prevention, and lifestyle adjustments, it is possible to manage and overcome plantar fasciitis. At Ramp Physio and Fitness, we are committed to providing you with the highest quality care and support throughout your recovery journey. Let us help you get back on your feet and enjoy the activities you love without pain.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default UnderstandingAndTreatingPlantarFasciitis;