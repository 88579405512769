import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const TheRoleOfFunctionalMovementScreening = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Mastering Movement with FMS</h1>
                    <h2>Uncover the significance of Functional Movement Screening at Ramp Physio and Fitness to enhance your physical performance and prevent injuries.</h2>
                    <p>Functional Movement Screening (FMS) is a game-changing tool in the world of physiotherapy and fitness. At Ramp Physio and Fitness, we utilize FMS to identify asymmetries and limitations in your movement patterns that could potentially lead to injuries. By addressing these issues, we can tailor a training program that not only improves your physical performance but also reduces the risk of future injuries. In this comprehensive guide, we will explore the role of FMS in achieving optimal movement health and how it can benefit your overall fitness journey.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Functional Movement</h1>
                        <p>Functional Movement Screening is a systematic approach to evaluating basic movement patterns before they become painful or problematic. The process involves a series of seven specific tests that assess strength, balance, flexibility, and coordination. The FMS score, which ranges from 0 to 21, helps us pinpoint areas of concern and develop corrective strategies. This proactive approach is crucial for anyone looking to enhance their physical capabilities, from professional athletes to individuals embarking on a new fitness regime.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Benefits of Movement Screening</h1>
                    <p>Implementing FMS into your fitness assessment offers a multitude of benefits. Here are ten key advantages:</p>
                    <ul>
                        <li>Improves overall functional fitness</li>
                        <li>Reduces the likelihood of injury</li>
                        <li>Identifies physical imbalances and weaknesses</li>
                        <li>Enhances athletic performance</li>
                        <li>Guides personalized workout plans</li>
                        <li>Prevents muscle and joint pain</li>
                        <li>Serves as a benchmark for progress</li>
                        <li>Facilitates better movement patterns</li>
                        <li>Assists in recovery from injury</li>
                        <li>Optimizes training efficiency</li>
                    </ul>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Screening Process and Protocols</h1>
                    <p>The FMS protocol involves seven fundamental movement patterns that require a balance of mobility and stability. These include the deep squat, hurdle step, in-line lunge, shoulder mobility, active straight-leg raise, trunk stability push-up, and rotary stability. Each movement is scored and the collective data provides a comprehensive overview of your movement proficiency. Understanding the intricacies of each test allows our physiotherapists to detect subtle dysfunctions that could lead to injury if left unaddressed.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Personalized Training Interventions</h1>
                    <p>After the FMS assessment, our team at Ramp Physio and Fitness designs individualized training interventions based on your results. By focusing on your specific needs, we can create a training program that addresses your weaknesses and builds upon your strengths. This personalized approach ensures that you are working towards your fitness goals efficiently and effectively, without the risk of injury that comes from a one-size-fits-all workout plan.</p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default TheRoleOfFunctionalMovementScreening;