import React from 'react';
import { useNavigate } from 'react-router-dom';
import './postsubmit_thankyou.css';

const ThankYou = () => {
    const navigate = useNavigate();

    return (
        <div className="thank-you-page">
            <div className="thank-you-content">
                <br />
                <br />
                <br />
                <br />
                <div className="success-icon">
                    <svg viewBox="0 0 50 50" width="70" height="70">
                        <circle cx="25" cy="25" r="23" fill="#4CAF50"/>
                        <path
                            d="M15 25 L23 33 L35 20"
                            stroke="white"
                            strokeWidth="3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            fill="none"
                        />
                    </svg>
                </div>
                <h1>Thank you for your message.</h1>

                <div className="contact-info">
                    <p>Our team will be in touch shortly. For further enquiries, please use the details below or the chat button on this page, thank you.</p>
                    <p>
                        <a href="tel:+61281881618" className="phone-link">
                            ☎ (02) 8188-1618
                        </a>
                    </p>
                    <p>
                        <a href="mailto:info@rampphysioandfitness.com.au" className="email-link">
                            ✉ info@rampphysioandfitness.com.au
                        </a>
                    </p>
                </div>

                <div className="button-group">
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>

                {/* Extra line breaks below the button */}
                <br />
                <br />

                {/* Centered heading and justified text */}
                <h2 style={{ textAlign: 'center' }}>About RAMP Physio & Fitness</h2>
                <br />
                <br />
                <div style={{ textAlign: 'justify' }}>
                    <p>
                        RAMP Physio & Fitness is dedicated to providing top-quality physiotherapy and fitness
                        services to help individuals recover from injuries, enhance sports performance, and
                        maintain an active lifestyle. Our team of experienced physiotherapists and fitness
                        professionals is committed to personalized care for clients of all ages and fitness
                        levels. Whether you need rehabilitation programs, one-on-one training sessions, or
                        ongoing support to get pain-free and stay healthy and active, we have you covered.
                    </p>
                    <p>
                        By combining evidence-based practices with modern workout techniques, we aim to address
                        your specific needs and help you reach your wellness goals efficiently. We believe in
                        educating our clients, empowering you with knowledge to prevent future injuries, and
                        continuing to improve your fitness level. Our state-of-the-art facilities ensure that
                        every client has access to the latest equipment and a comfortable environment for both
                        therapy and training.
                    </p>
                <br />
                <br />
                </div>
                <div className="button-group">
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </div>
        </div>
    );
};

export default ThankYou;
