import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const HowToTrackAndMeasureFitnessProgress = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Master Your Fitness Journey</h1>
                    <h2>Unlock your potential with effective tracking and measurement strategies at Ramp Physio and Fitness, your partner in achieving health and fitness excellence.</h2>
                    <p>Understanding and monitoring your fitness progress is key to achieving your health goals. At Ramp Physio and Fitness, we prioritize providing you with the knowledge and tools to track your journey effectively. In this comprehensive guide, we'll explore various methods to measure your fitness progress beyond the scale, ensuring you stay motivated and on track to reach your personal best.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Learn More
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Setting SMART Fitness Goals</h1>
                        <p>Beginning your fitness journey starts with setting Specific, Measurable, Achievable, Relevant, and Time-bound (SMART) goals. These objectives provide a clear roadmap to success and make tracking progress straightforward. We'll help you establish SMART goals that align with your lifestyle and aspirations, ensuring you have a targeted approach to your fitness regimen.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Performance Metrics That Matter</h1>
                    <p>Tracking your performance involves more than just noting your workouts. It's about understanding the metrics that truly reflect your progress. We'll guide you through tracking your strength gains, endurance levels, agility, and even recovery times, providing a comprehensive view of your fitness journey.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                        onClick={() => {
                          window.gtag_report_conversion('https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service');
                        }}>
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Embrace Technology in Fitness</h1>
                    <p>Leveraging the latest technology can enhance your ability to track fitness progress. From wearable devices to mobile apps and even advanced gym equipment, we'll introduce you to the best tech to monitor your activity levels, nutrition, and overall health.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Body Measurements for Progress</h1>
                        <p>While the scale can tell you how much you weigh, it doesn't give you the full picture. We'll show you how to take body measurements accurately, allowing you to see changes in your body composition and celebrate improvements that aren't reflected by weight alone.</p>
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Tracking Nutrition and Hydration</h1>
                    <p>Nutrition and hydration play a critical role in your fitness progress. We'll assist you in understanding how to track your dietary intake and fluid consumption to ensure you're fueling your body correctly for both performance and recovery.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Community Support and Accountability</h1>
                        <p>Being part of a supportive community can significantly impact your motivation and consistency. At Ramp Physio and Fitness, we foster a culture of encouragement and accountability, helping you stay committed to your fitness goals.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/massage.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Reflecting on Your Fitness Journey</h1>
                    <p>Reflection is a powerful tool for growth. We encourage you to regularly reflect on your fitness journey, celebrate your victories, learn from challenges, and adjust your goals as needed to keep moving forward.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="service-intro-content2">
                        <h1>Tailored Fitness Plans</h1>
                        <p>Everyone's fitness journey is unique. At Ramp Physio and Fitness, we tailor fitness plans to meet your individual needs, ensuring that you have a personalized approach to tracking and measuring your progress.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default HowToTrackAndMeasureFitnessProgress;